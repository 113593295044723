import * as React from 'react';
import { 
  Alert, 
  // Avatar, 
  CircularProgress, 
  Snackbar 
} from '@mui/material';
// import useOnline from '@haydenbleasel/use-online';
// import WifiOffIcon from '@mui/icons-material/WifiOff';

export default function KomunikatyLadowanie(props) {

  const [stan, setStan] = React.useState(null);
  // const isOnline = useOnline();
  // const [openOffline, setOpenOffline] = React.useState(false);
  const konfig = {
    autoHide: 500,
    pozycja: {
      vertical: 'top',
      horizontal: 'center',
    },
    // sx: { top: 60 },
  };
  // console.log(props.stany);


  React.useEffect(() => {
        setStan(props.stany);
        if (props.stany.isSuccess) { 
                setTimeout(function () {
                setStan(null);
        }, 10)}
  }, [props.stany]);

  // React.useEffect(() => {
  //   if (!isOnline) {
  //     setOpenOffline(true);
  //     setTimeout(function () {
  //       setOpenOffline(false);
  //     }, 3000);
  //   }
  // }, [isOnline]);

  // console.log(stan);

  return (<>
    { stan && stan.isLoading && ( // loading && !porazka && !sukces 
      (<Snackbar
        open={stan.isLoading}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
        // sx={konfig.sx}
      >
        <Alert severity="warning" icon={<CircularProgress />}>
          czekaj... ładuję dane...
          {/* {siec} */}
        </Alert>
      </Snackbar>)
    )}
    { stan && stan.isError && ( // loading && porazka
      (<Snackbar
        open={stan.isError}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
        // sx={konfig.sx}
      >
        <Alert severity="error">
          Coś poszło nie tak... ({stan.error}) - spróbuj odświeżyć lub ponownie załadować...
        </Alert>
      </Snackbar>)
    )}
    {/* { stan && stan.isSuccess && ( // loading && sukces
      <>
      <Snackbar
        open={stan.isSuccess}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
      >
        <Alert severity="success">Gotowe! {JSON.stringify(Object.keys(stan.data))}</Alert>
      </Snackbar>
      </>
    )} */}
    {/* { !isOnline && <Snackbar
        open={openOffline}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
      >
          <Alert severity="error">
          <Avatar sx={{ bgcolor: 'white', width: 28, height: 28, mr: 1, border: "2px solid red" }}>
                  <WifiOffIcon color='error' />
                </Avatar>
          Uwaga - nie masz połączenia z Internetem - zapisanie informacji nie będzie możliwe...
          </Alert>
    </Snackbar> } */}

  </>);
}

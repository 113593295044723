import { serialize } from "object-to-formdata";
import imageCompression from 'browser-image-compression';
import { token } from "./index"

// dodanie (zapisanie) Pliku w bazie i na dysku:
export const postPlik = async (key, form) => {

  // console.log(plik);
  let file = form.pliki[0];
  var formData = serialize(
    { plik: { formularz: form.formularz }, [token]: 1 }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { 
      booleansAsIntegers: true, 
      indices: true,
      // noFilesWithArrayNotation: true,
    }
  );

  // sprawdzam mime type wgrywanego pliku:
  // jeśli image/jpeg to:
  if (file.type==="image/jpeg") {

    // przeskalowanie załączonych plików:
    const imageFile = file;
    // imageCompression.getExifOrientation(imageFile).then(e=>console.log(e));
  
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      fileType: 'image/jpeg',
      // exifOrientation: -1,
      // useWebWorker: true,
      preserveExif: false,
    }
    const obrazek = await imageCompression(imageFile, options)
      .then(function (compressedFile) {
        return compressedFile;
      }).then( async function (data) {
        formData.append('zalaczniki[0]', data, file.name);
        const response = await fetch(
          process.env.REACT_APP_API_DOMAIN +
            "/index.php?option=com_content&task=api.savePlik&format=json",
          {
            method: "POST",
            body: formData,
          }
        );
        return response.json();
      })
      .catch(function (error) {
        console.log(error);
      });
      return obrazek;
  }

  // jeśli application/pdf to:
  else if (file.type==="application/pdf") {
    formData.append('zalaczniki[0]', file);
    const response = await fetch(
      process.env.REACT_APP_API_DOMAIN +
        "/index.php?option=com_content&task=api.savePlik&format=json",
      {
        method: "POST",
        body: formData,
        // mode: 'no-cors',
      }
    );
    // console.log(response);
    return response.json();
  }

  // a jeśli inny to wywal komunikat, że nie wolno.
  else {
    console.log('nic nie zostało zrobione');
  }

};  


import * as React from 'react';
import { Badge, Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { deleteObiekt } from '../api';
import { transNocleg, transOsoba } from '../komponenty/labelki';
import OsobaDuplikaty from '../odwiedziny/OsobaDuplikaty';
import HistoriaZmian from '../komponenty/HistoriaZmian';
import ButtonNoclegiGrupa from './ButtonNoclegiGrupa';
import ButtonNoclegDodaj from './ButtonNoclegDodaj';




///// pojedynczy wiersz listy ////////////////////////////////// 
export default function NoclegiWiersz(props) {

  // console.log(props);
  const [open, setOpen] = React.useState(false);
  const [openDial, setOpenDial] = React.useState(false);
  const queryClient = useQueryClient();
  let cred = queryClient.getQueryData(['dane','cred']);
  let nocleg = queryClient.getQueryData(['dane','noclegi',{id: props.nid}]).noclegi[0];
  let osoba = queryClient.getQueryData(['dane','osoby',{id: nocleg.idosoby}]).osoby[0];
  let grupa = queryClient.getQueryData(['dane','odwiedziny',{id: nocleg.gid}]);
  grupa = grupa && grupa.odwiedziny[0];
  let row = transNocleg({...transOsoba(osoba), ...nocleg});
  // console.log(row);

  const delNocleg = useMutation(nocleg => deleteObiekt('x',nocleg), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      // let nocki = noclegi.data.data.filter((nocka) => Number(nocka.nid) !== Number(data.data));
      let oldLista = queryClient.getQueryData(['dane','noclegi']);
      let newLista = oldLista.noclegi.filter((o) => o.id !== Number(data.data));
      // let newOper = { 
      //   ...operacje.data, 
      //   noclegi: newLista,
      // };
      // queryClient.setQueryData(['dane'], newOper);
      // noclegi = [...noclegi, data.data];
      queryClient.setQueryData(['dane','noclegi'], {noclegi: newLista});
      setOpenDial(false);
    },
  });

  const handleConfirm = () => {
    delNocleg.mutate({tabela: "Noclegi",wiersz: row.id});
  }

  // console.log(row);
  let daty = row.od + " - " + row.do
  let arrow = open ? <KeyboardArrowUpIcon sx={{color:"green",fontSize:36,m:"-12px 6px"}} /> : <KeyboardArrowDownIcon  sx={{color:"green",fontSize:36,m:"-12px 6px"}} />

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer", backgroundColor: open&&"#ffd" }}>
      <TableCell colSpan={7} sx={{ p:"1em" }}>

                { props.noclegi && daty }
                { props.noclegi && arrow }
                { props.noclegi && row.gid>0 && <Typography variant="caption" noWrap sx={{position:"absolute",right:10}}>Grupa: {row.gid}</Typography>}
                { props.noclegi && <br/> }

          <b>{row.osoba.imie} {row.osoba.nazwisko}</b>, PESEL: {row.PESEL}<br />
          <small>kat.: <b>{row.kategoria_label}</b> - noclegów: <b>{row.nocy}</b> - opłata: <b>{row.pobrano} zł</b></small>


          

          { row.osoba.duplikaty && row.osoba.duplikaty.length>0 && cred.isSuccess && ( cred.data.grupa==="GOSP" || cred.data.grupa==="RADA" ) &&
            <Badge 
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }} 
              badgeContent={row.osoba.duplikaty.length} 
              color="warning"
              sx={{ marginTop: 2 }}
            >
              <TheaterComedyIcon color="warning" />
            </Badge>
          }



      </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>

          <Box sx={{ p:1 }}>
                { props.odwiedziny && daty }
                { props.odwiedziny && <br /> }
                
                { row.rodzaj>0 && <>
                        opłata niestandardowa, bo: <b>{row.rodzaj_label}</b><br/>
                        { row.nuwagi && <>Uwagi: {row.nuwagi}<hr/></> }
                        opłata wyliczona: <b>{row.wyliczona} zł</b>, pobrana: <b>{row.karta ? row.karta : row.pobrano} zł</b>
                        { row.karta && <><br/>w tym gotówka: <b>{row.oplata} zł</b></> }
                        <br />
                </> }

                {(cred.grupa!=="REGI" || !row.klimat_zw ) && <>
                        opłata klimatyczna:{" "}
                        {row.klimat_zw ? <>nie pobrano</> : <>{row.oklimat} zł</>}
                        <br />
                </>}

                { row.dataur && <>data ur.: {row.dataur}, </> }
                <br />wiek podczas noclegu: <b>{row.wieknoc}</b>
                                
                { ( cred.grupa==="GOSP" || cred.grupa==="RADA" ) && <> - wiek dziś: <b>{row.wiekdzis}</b></> }
                
                { (cred.grupa==="GOSP" || cred.grupa==="RADA") && <>
                        <Typography sx={{textAlign:"right",fontSize:".8em",fontStyle:"italic",mt:2}} >osobaID: {row.idosoby}, noclegID: {row.id}</Typography>
                        { row.osoba.funkcje.length>0 && <u><hr />historia związków z Klubem Otryckim:<br/></u>}
                        {/* { row.osoba.funkcje.map( f => <Box fontSize={"small"} key={"funkcje-"+f.id}>{desk ? f.funkcja_label : f.funkcja_label_short } od {f.datastart ? f.datastart : "..."} do {f.datastop ? (f.datastop==="2100-01-01" ? "dzisiaj" : f.datastop) : "..."}</Box> ) } */}
                </> }
                { (cred.grupa!=="GOSP" && cred.grupa!=="RADA") && 
                        <Typography sx={{textAlign:"right",fontSize:".8em",fontStyle:"italic",mt:2}} >nID: {row.id}</Typography>
                }

            </Box>




            {/* #### poniżej listwa ikon nawigacyjnych: ######################## */}
            <hr />
            {/* { ( !grupa || (grupa && Number(grupa.blokada)===0)) &&  */}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                 <Fab 
                   color="error" 
                   aria-label="usun" 
                   onClick={()=>setOpenDial(true)} 
                   size="small"
                   disabled={grupa && Number(grupa.blokada)===1}
                   sx={{ mb: "2em" }}
                 >
                   <DeleteForeverIcon />
                 </Fab>

                 { row.osoba.duplikaty && row.osoba.duplikaty.length>0 && ( cred.grupa==="GOSP" || cred.grupa==="RADA" ) 
                   && <OsobaDuplikaty osoba={row.osoba} /> }

                 { cred.grupa==="RADA"
                   && <HistoriaZmian tabela="Noclegi" id={row.id} /> }
                 { cred.grupa==="RADA"
                   && <HistoriaZmian tabela="Osoby" id={row.osoba.id} /> }

                 { !props.odwiedziny && grupa && row.gid && <ButtonNoclegiGrupa 
                      id={grupa.id} 
                      button 
                      pozycja={{ mb: "2em"}} 
                      size="small" 
                   /> }

                 <ButtonNoclegDodaj 
                    key={"nocEd-"+row.id} 
                    button 
                    nocleg={row} 
                    disabled={grupa && Number(grupa.blokada)===1}
                    pozycja={{ mb: "2em" }} 
                 />
                 
            </Box> 
            {/* } */}
            <hr style={{ height: "10px", marginTop: "-10px", border: 0, backgroundColor: "lightgrey" }} />
                 
                 
            <Dialog
              open={openDial}
              onClose={()=>setOpenDial(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">Czy aby na pewno???</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Potwierdź... ;-)
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleConfirm}
                  sx={{ position: "absolute", left: "1em" }}
                >
                  Tak!!!
                </Button>
                <Button onClick={()=>setOpenDial(false)} autoFocus>
                  Przemyślę...
                </Button>
              </DialogActions>
            </Dialog>

          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


import * as React from 'react';
// import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FinanseRok from './FinanseRok';
// import { fetchOperacje } from '../api/Finanse';
import { 
  // useQuery, 
  useQueryClient } from '@tanstack/react-query';
import { FormControlLabel, Switch } from '@mui/material';
// import { bilanseLata } from './sumy';
import ButtonZestawienia from './ButtonZestawienia';
// import KomunikatyLadowanie from '../komponenty/KomunikatyLadowanie';
// import { fetchDane } from '../api';

function Row(props) { // wiersz podsumowania rocznego - przychody, wydatki, bilans w roku
  const queryClient = useQueryClient();
  const rok = props.dane;
  const [open, setOpen] = React.useState(false);
  const ksiegowe = queryClient.getQueryData(['ksiegowe']);
  const calosc = queryClient.getQueryData(['calosc']);
  // console.log(props.dane);

  // p = przychody, w = wydatki, b = bilans | c = całość, k = księgowe
  // let pc = props.dane['c'].przychody;
  // let wc = props.dane['c'].wydatki;
  // let bc = pc-wc;
  // let pk = props.dane['k'].przychody;
  // let wk = props.dane['k'].wydatki;
  // let bk = pk-wk;

  let pc = rok.przychody.suma+rok.bankP.suma+rok.kasaP.suma;
  let wc = rok.wydatki.suma+rok.bankW.suma+rok.kasaW.suma;
  let bc = pc-wc;
  let pk = rok.przychody.ksiegowe+rok.bankP.ksiegowe+rok.kasaP.ksiegowe;
  let wk = rok.wydatki.ksiegowe+rok.bankW.ksiegowe+rok.kasaW.ksiegowe;
  let bk = pk-wk;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }} onClick={() => setOpen(!open)} >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rok.rok}
        </TableCell>
        <TableCell align="right"> przychody:
          {calosc && <> {pc.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</>}
          {rok.rok>2021 && calosc && ksiegowe && <br/>}
          {rok.rok>2021 && ksiegowe && <i>{pk.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</i>}
        </TableCell>
        <TableCell align="right"> wydatki: 
          {calosc && <> {wc.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</>}
          {rok.rok>2021 && calosc && ksiegowe && <br/>}
          {rok.rok>2021 && ksiegowe && <i>{wk.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</i>}
        </TableCell>
        <TableCell align="right"> bilans:
          {calosc && <> {bc.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</>}
          {rok.rok>2021 && calosc && ksiegowe && <br/>}
          {rok.rok>2021 && ksiegowe && <i>{bk.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</i>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* lista miesięcy w roku, wraz z sumami w kategoriach: */}
              <FinanseRok rok={rok.rok} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}





export default function FinanseStart() {
  // const sumy = useQuery('sumy', fetchSumy);
  // const podsumowania = useQuery('podsumowania', fetchPodsumowania);

  const queryClient = useQueryClient();
  // const operacje = useQuery(['operacje'], fetchOperacje);
  // const operacje = useQuery(['dane'], fetchDane);
  const sumyRoczne = queryClient.getQueryData(['sumyRoczne']);

  // const sumy = operacje.isSuccess && bilanseLata(operacje.data); 
  // const sumy = operacje.isSuccess && podsumowania(operacje.data).lata; 
  // queryClient.setQueryData(['podsumowania'], sumy);
  // console.log(sumy)

  const [invisible, setInvisible] = React.useState(queryClient.getQueryData(['badzeSumy']));
  const [invisible2, setInvisible2] = React.useState(queryClient.getQueryData(['ksiegowe']));
  const [invisible3, setInvisible3] = React.useState(queryClient.getQueryData(['calosc']));

  const handleBadgeVisibility = () => {
    setInvisible(!invisible);
    queryClient.setQueryData(['badzeSumy'], !invisible);
  };
  const handleBadgeVisibility2 = () => {
    setInvisible2(!invisible2);
    queryClient.setQueryData(['ksiegowe'], !invisible2);
  };
  const handleBadgeVisibility3 = () => {
    setInvisible3(!invisible3);
    queryClient.setQueryData(['calosc'], !invisible3);
  };

  return (
    <div>
      {/* <KomunikatyLadowanie stany={operacje} /> */}
      <FormControlLabel
        sx={{ color: 'text.primary' }}
        control={<Switch checked={!invisible} onChange={handleBadgeVisibility} />}
        label="uzupełnienia"
        // label={invisible ? "pokaż liczby uzupełnień" : "ukryj liczby uzupełnień"}
      />

      <FormControlLabel
        sx={{ color: 'text.primary' }}
        control={<Switch checked={invisible2} onChange={handleBadgeVisibility2} />}
        label="księgowe"
        // label={invisible2 ? "pokaż operacje księgowe" : "ukryj operacje księgowe"}
      />

      <FormControlLabel
        sx={{ color: 'text.primary' }}
        control={<Switch checked={invisible3} onChange={handleBadgeVisibility3} />}
        label="całość"
        // label={invisible3 ? "pokaż całość" : "ukryj całość"}
      />


    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        {/* <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {sumyRoczne.sort((a,b)=>b.rok>a.rok?1:-1).map((k) => (
            <Row key={"rok-"+k.rok} dane={k} /> 
          ))}
          {/* {operacje.isSuccess && Object.keys(sumy).sort().reverse().map((k) => (
            <Row key={"rok-"+k} dane={sumy[k]} /> 
          ))} */}
        </TableBody>
      </Table>
    </TableContainer>

    <Box component="div" sx={{ m: 2, width: "100%", textAlign: "center" }} >
      <ButtonZestawienia />
    </Box>

    </div>
  );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, ButtonGroup, Collapse, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'

import { useQuery } from '@tanstack/react-query';
// import ButtonDelete from './ButtonDelete';
import ButtonView from './ButtonView';
// import ButtonDownload from './ButtonDownload';
import PlikFormularz from '../formularze/PlikFormularz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { fetchOperacje } from '../api';
import ButtonDeletePlik from './ButtonDeletePlik';
import { fetchDane } from '../api';

export default function FinanseTab3(props) {

  // const operacje = useQuery(['operacje'], fetchOperacje);
  const operacje = useQuery(['dane'], fetchDane);
  // const cred = useQuery(['JUser'], fetchJUser);
  const [open, setOpen] = React.useState(false);
  // const [stan, setStan] = React.useState(0); // stan dla odświeżenia listy plików...
  let rows = operacje.isSuccess && operacje.data.pliki_polaczenia
    .filter(f=>( f['tabela']===props.dane.tabela && f['wiersz']===props.dane.id ))
    .map(plik=>{
      let p = operacje.isSuccess && operacje.data.pliki.filter(file=>file.id===plik.idpliku)[0];
      p.info = typeof p.info === 'string' ? JSON.parse(p.info) : p.info;
      return p && {
        id: p.id,
        data: p.info.data, 
        plik: p.info.plik,
        nazwa: p.info.nazwa,
        wielkosc: ((p.info.wielkosc/1024).toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" kb"),
        kategoria: p.info.kategoria,
        id_polaczenia: plik.id,
      }      
    });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


  return (
        <React.Fragment>
          <Grid container spacing={1}>
          <Grid item xs={6} md={9}>
          { fullScreen ? <Typography variant="h6">Pliki...</Typography> :
            <Typography variant="h6">Pliki powiązane z operacją</Typography> }
          </Grid>
          <Grid item xs={6} md={3} sx={{ textAlign: "right" }}>
          
            <Button variant="outlined" 
              onClick={() => setOpen(!open)}
              size="small"
            >
              dodaj plik
            </Button>
            <IconButton
              aria-label="expand row"
              size="small"
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          
          </Grid>
          </Grid>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <PlikFormularz row={props.dane} setOpen={setOpen} />
            </Box>
          </Collapse>
          <hr/>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell><b>data</b></TableCell>
            <TableCell><b>nazwa</b></TableCell>
            <TableCell><b>wielkość</b></TableCell>
            <TableCell align="right"><b>akcje</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              { fullScreen ? <TableCell colSpan={3}>
                <small><i>ID: {row.id} - {row.data}</i></small><br/>
                {row.kategoria} - {row.nazwa} { row.opis && <small><i> - {row.opis}</i></small> }<br/>
                <small>{row.plik} - <i>{row.wielkosc}</i></small>
              </TableCell> : <>
              <TableCell component="th" scope="row">
                {row.data}<br/>
                <small><i>(ID: {row.id})</i></small>
              </TableCell>
              <TableCell>{row.kategoria}<br/>{row.nazwa}<br/><small><i>{row.opis}</i></small></TableCell>
              <TableCell>{row.plik}<br/>{row.wielkosc}</TableCell>
              </> }
              <TableCell align="right">
                <ButtonGroup variant="text" aria-label="text button group">
                  <Button disabled />
                  <ButtonDeletePlik obiekt={row} />
                  <ButtonView id={row.id} plik={row.plik} />
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
}

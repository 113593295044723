import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PeselDecode } from "../../komponenty/labelki";

import SelectPanstwo from "../../komponenty/SelectPanstwo";
import AutoVir from "../../komponenty/AutoVir";
import AutoVirOsoba from "../../komponenty/AutoVirOsoba";
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from "../../api";
import { TextField, Typography } from "@mui/material";


// const NoclegFormularz = React.forwardRef((props, ref) => {
const Nocleg2Osoba = (props) => {

  const cred = useQuery(['dane','cred'], fetchDane);
  const osoby = useQuery(['dane','osoby'], fetchDane);

  const {
    register,
    setValue,
    trigger,
    watch,
    resetField,
    clearErrors,
    formState: { errors },
  } = props.formularz;
  
  React.useEffect(() => {
        if (watch("idosoby")==="nowa"&&PeselDecode(watch("PESEL")).valid) {
                // helperPESEL = "PESEL OK - ur. "+watch("dataur")+", "+watch("panstwo")
                let checkPesel = osoby.isSuccess && osoby.data.osoby.filter(o=>o.PESEL===watch("PESEL"));
                if (checkPesel.length>0) {
                        // console.log(checkPesel,watch(),PeselDecode(watch("PESEL")));
                        let osoba = checkPesel[0];
                        setValue("idosoby", osoba.id);
                        setValue("imie", osoba.imie);
                        setValue("nazwisko", osoba.nazwisko);
                        setValue("dataur", PeselDecode(watch("PESEL")).date);
                        setValue("panstwo", "Polska");
                        clearErrors(); // nie wiem czemu w przypadku prawidłowego PESEL nie działa walidacja osoby???
                        // clearErrors(["imie","nazwisko","dataur","panstwo"]); 
                }
        } else if (watch("idosoby")==="nowa" && watch("PESEL")>5) {
                setValue("dataur", PeselDecode(watch("PESEL")).date);
                resetField("idosoby");
                resetField("imie");
                resetField("nazwisko");
                resetField("panstwo");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("PESEL")]);

  React.useEffect(() => {
    if (!PeselDecode(watch("PESEL")).valid) {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // nie wiem czemu w przypadku prawidłowego PESEL nie działa walidacja osoby???

  // console.log(watch(),errors);
  
  return (
    <React.Fragment>
        {/* ### edycja danych osoby ############################################################################### */}
        { (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA") && 
        <Grid xs={12} sx={{ '& .MuiFormControl-root': { m: 0 },mt:2 }}>
          <AutoVirOsoba 
              // value={watch("idosoby")}
              stan={watch()}
              onChange={(v) => {
                // console.log(v);
                // setValue("osoba", v);
                setValue("idosoby", v.id);
                setValue("imie", v.imie);
                setValue("nazwisko", v.nazwisko);
                setValue("PESEL", v.PESEL);
                setValue("dataur", v.dataur); // PeselDecode(v.PESEL).date
                setValue("panstwo", v.panstwo);
                clearErrors();
                // trigger();
                // v.funkcje && watch("osoba").funkcje.map( f => {
                //   if (Number(f.funkcja)===1 && f.datastop==="2100-01-01") { 
                //     setValue("kategoria", 5);
                //   }
                //   return true;
                // })
            }}
          />
        </Grid> }

        <Grid xs={12} sx={{ ml:2 }}>wpisany PESEL: {watch("PESEL")} - {PeselDecode(watch("PESEL")).valid?"poprawny":"niepoprawny"}</Grid>

        <Grid xs={12} md={6}>
          { (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA") ?
          <AutoVir 
            {...register("nazwisko", {
                required: 'Wpisz nazwisko',
            })}
            opcja="nazwisko" 
            value={watch("nazwisko")}
            onChange={(v) => {
              setValue("nazwisko", v);
              trigger("nazwisko");
            }}
            errors={errors}
          /> 
          :
          <TextField
            {...register("nazwisko", {
                required: 'Wpisz nazwisko',
            })}
            label="nazwisko"
            value={watch("nazwisko")}
            onChange={(event) => {
              if (event.target.value && event.target.value.length>0) { event.target.value = event.target.value[0].toUpperCase() + event.target.value.slice(1) }
              setValue("nazwisko", event.target.value);
              trigger("nazwisko");
            }}
            error={errors.nazwisko ? true : false}
            helperText={ errors.nazwisko && errors.nazwisko.message }
            variant="outlined"
            size="small"
            // margin="dense"
            sx={{ 
              '& .MuiOutlinedInput-input': { p: "8.5px" },
              '& .MuiFormControl-root': { m: 0 },
            }}
          /> }
        </Grid>
        
        <Grid xs={12} md={6}>

          {/* <input type="hidden" {...register("validPESEL")} value={watch("validPESEL")} />
          <input type="hidden" {...register("osoba")} value={watch("osoba")} />
          <input type="hidden" {...register("ninfo")} value={watch("ninfo")} />
          <input type="hidden" {...register("nid")} value={watch("nid")} /> */}
          
          { (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA") ?
          <AutoVir 
            {...register("imie", {
                required: 'Wpisz imię',
            })}
            opcja="imie" 
            value={watch("imie")}
            onChange={(v) => {
              setValue("imie", v);
              trigger("imie");
            }}
            errors={errors}
          />
          :
          <TextField
            {...register("imie", {
                required: 'Wpisz imię',
            })}
            label="imie"
            value={watch("imie")}
            onChange={(event) => {
              if (event.target.value && event.target.value.length>0) { event.target.value = event.target.value[0].toUpperCase() + event.target.value.slice(1) }
              setValue("imie", event.target.value);
              trigger("imie");
            }}
            error={errors.imie ? true : false}
            helperText={ errors.imie && errors.imie.message }
            variant="outlined"
            size="small"
            // margin="dense"
            sx={{ 
              '& .MuiOutlinedInput-input': { p: "8.5px" },
              '& .MuiFormControl-root': { m: 0 },
            }}
          /> }

        </Grid>

        { !PeselDecode(watch("PESEL")).valid && <>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                <Grid xs={6} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
                <DatePicker 
                    label="data urodzenia"
                    {...register("dataur", {
                        required: 'Wprowadź datę urodzenia',
                        validate: { 
                          przyszlosc: v => dayjs(v).isBefore(dayjs()) || 'Data nie może być z przyszłości',
                          walid: v => dayjs(v).isValid() || 'Wpisz prawidłową datę urodzenia'
                        },
                    })}
                    value={watch('dataur')&&dayjs(watch('dataur'))}
                    onChange={(event) => {
                      setValue("dataur",event.format("YYYY-MM-DD"));
                      trigger(["panstwo","dataur","PESEL"]);
                    }}
                    fullWidth
                    disableFuture
                    openTo="year"
                    views={['year', 'month', 'day']}
                    format="YYYY-MM-DD"
                    clearable
                    slotProps={{
                      textField: {
                        error: errors.dataur ? true : false,
                        helperText: errors.dataur ? errors.dataur.message : false,
                        size: 'small',
                      },
                    }}
                    localeText={{ datePickerToolbarTitle: 'Data urodzenia' }}
                    sx={{ 
                      '& .MuiOutlinedInput-input': { backgroundColor: "none",pt:1,pb:1 },
                      '& .MuiInputLabel-root': { zIndex: 0 }
                    }}
                /> 
                </Grid>
                </LocalizationProvider>
            
                <Grid xs={6} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
                  <SelectPanstwo
                    {...register("panstwo", {
                        required: 'Państwo jest wymagane przy braku PESEL',
                    })}
                    id="panstwo"
                    value={watch('panstwo')}
                    onChange={(event) => {
                      setValue("panstwo",event);
                      trigger(["panstwo","dataur","PESEL"]);
                    }}
                    errors={errors}
                    type="text"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                  />
                </Grid>
        </> }

        {/* </>}  */}

        {/* {console.timeEnd("NF2")} */}
        
        { watch("idosoby") && watch("idosoby")!=="nowa" && <Grid xs={12}>
        <Typography sx={{textAlign:"right",fontSize:".7em",fontStyle:"italic",mt:1}} >
          osobaID: {watch("idosoby")}
        </Typography>
        </Grid> }

        { (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA") && watch("idosoby")==="nowa" && <Grid xs={12}>
        <Typography sx={{fontStyle:"italic",mt:4}}>
          Jeśli tylko jest taka mozliwość - wybierz osobę z listy - widocznej po kliknięciu w zielony przycisk z ludzikiem. Wpisanie nazwiska i/lub imienia dodatkowo ograniczy listę dostępnych osób.
        </Typography>
        </Grid> }


    </React.Fragment>
  );
}


export default Nocleg2Osoba;

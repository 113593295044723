import * as React from 'react';
import { Alert, Avatar, CircularProgress, IconButton, Snackbar } from '@mui/material';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import useOnline from '@haydenbleasel/use-online';
import CloseIcon from '@mui/icons-material/Close';

export default function KomunikatyWysylka(props) {

  const [stan, setStan] = React.useState(null);
  const isOnline = useOnline();

  const konfig = {
    autoHide: 1000,
    pozycja: {
      vertical: 'top',
      horizontal: 'center',
    },
    // sx: isOnline?null:{ position: 'absolute', top: "160px" },
  };
  // console.log("props KomunikatyWysylka: ", props);


  React.useEffect(() => {
    setStan(props.stany);
  }, [props.stany]);
  
  return (<>
    { stan && stan.isLoading && ( // loading && !porazka && !sukces 
      <>
      <Snackbar
        open={stan.isLoading}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
        // sx={konfig.sx}
      >
        <Alert severity="warning" icon={<CircularProgress />}>
          
          { isOnline ? <>zapisuję dane...</> : <><Avatar sx={{ bgcolor: 'white', width: 28, height: 28, mr: 1, border: "2px solid red" }}>
                  <WifiOffIcon color='error' />
                </Avatar>
          Uwaga - nie masz połączenia z Internetem - ta operacja powinna się zapisać po odnalezieniu połączenia, 
          ale lepiej spróbuj najpierw znaleźć sieć...
          <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={()=>setStan(null)}
            >
              <CloseIcon />
          </IconButton>
          </> }
          {/* {siec} */}
        </Alert>
      </Snackbar>

      { !isOnline && <Snackbar
        open={stan.isSuccess}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
      >
          <Alert severity="error">
          <Avatar sx={{ bgcolor: 'white', width: 28, height: 28, mr: 1, border: "2px solid red" }}>
                  <WifiOffIcon color='error' />
                </Avatar>
          Uwaga - nie masz połączenia z Internetem - spróbuj najpierw znaleźć sieć...
          </Alert>
      </Snackbar> }
      </>
    )}
    { stan && stan.isError && ( // loading && porazka
      (<Snackbar
        open={stan.isError}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
        // sx={konfig.sx}
      >
        <Alert severity="error">
          Coś poszło nie tak... ({stan.error}) - spróbuj jeszcze raz...
        </Alert>
      </Snackbar>)
    )}
    { stan && stan.isSuccess && ( // loading && sukces
      (<Snackbar
        open={stan.isSuccess}
        autoHideDuration={konfig.autoHide}
        anchorOrigin={konfig.pozycja}
        // sx={konfig.sx}
      >
        <Alert severity="success">Dane zapisane!!!!</Alert>
      </Snackbar>)
    )}
  </>);
}

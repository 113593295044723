import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import PanToolIcon from '@mui/icons-material/PanTool';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import BungalowIcon from '@mui/icons-material/Bungalow';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import Tooltip from '@mui/material/Tooltip'

export default function IconState(props) {

  return ( <>
          { Number(props.state) === 0  && <Tooltip title="oczekujące" placement="left"><ContactSupportIcon color="warning" /></Tooltip> }
          { Number(props.state) === 1  && <Tooltip title="zaakceptowane" placement="left"><ThumbUpIcon color="primary" /></Tooltip> }
          { Number(props.state) === 2  && <Tooltip title="anulowane" placement="left"><SyncDisabledIcon color="disabled" /></Tooltip> }
          { Number(props.state) === 3  && <Tooltip title="do kontaktu" placement="left"><ContactPhoneIcon color="info" /></Tooltip> }
          { Number(props.state) === -2 && <Tooltip title="usunięte" placement="left"><SpeakerNotesOffIcon color="disabled" /></Tooltip> }
          { Number(props.state) === -3 && <Tooltip title="dodana przez Gospodarza" placement="left"><BungalowIcon /></Tooltip> }
          </>
  );
}



// if (id==="0") { s =  "oczekujące" }
// if (id==="1") { s =  "zaakceptowane" }
// if (id==="2") { s =  "anulowane" }
// if (id==="3") { s =  "do kontaktu" }
// if (id==="-2") { s = "usunięte" }
// if (id==="-3") { s = "dodana przez Gospodarza" }

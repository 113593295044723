import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from "../../api";
import CFButtonSwitch from "../../komponenty/ButtonSwitchSmall";
import { Nocleg4bSzczegoly } from "./Nocleg4bSzczegoly";
import Nocleg4cNiestandard from "./Nocleg4cNiestandard";


// const NoclegFormularz = React.forwardRef((props, ref) => {
const Nocleg4aPlatnosc = (props) => {

  const cred = useQuery(['dane','cred'], fetchDane);
  // const odwiedziny = useQuery(['dane','odwiedziny'], fetchDane);
  
  const {
    register,
    setValue,
    watch,
    resetField,
    formState: { errors },
  } = props.formularz;
  
  // ustawienie uprawnień
  let isViewer = false;
  let isEditor = false;
  // let isOwner = false;
  
  if (cred.isSuccess) {
    if (cred.data.grupa === "GOSP") {
      isEditor = true;
    }
    else if (cred.data.grupa === "RADA") {
      isViewer = true;
    }
    // else if (odwiedziny.isSuccess && watch("gid")) {
    //   let grupa = odwiedziny.isSuccess && odwiedziny.data.odwiedziny.filter(o=>Number(o.id)===Number(watch("gid")))[0];
    //   if (cred.data.userID === grupa.created_by) {
    //     isOwner = true;
    //   }
    // }
  }

  // let n = transNocleg(watch());
  React.useEffect(() => {
      resetField("rodzaj");
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("standard")]);

  React.useEffect(() => {
      watch("rodzaj")>0 && setValue("standard",false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>

        <Nocleg4bSzczegoly n={watch()} />
        
        { (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA") && <Grid xs={12}>
          <CFButtonSwitch
            {...register("standard")}
            value={watch("standard")}
            onChange={(event) => {
              setValue("standard",event);
            }}
            label={watch("standard")===true?"wpłata zgodna z wyliczeniem":"wpłata niestandardowa"}
            error={errors.standard ? errors.standard : false}
          />
        </Grid> }
        { (isEditor || isViewer) && watch("standard")!==true && <Nocleg4cNiestandard formularz={props.formularz} /> } 

        { (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA") && <Grid xs={12} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
          <CFButtonSwitch
            {...register("klimat_zw")}
            defaultValue={true}
            value={watch('klimat_zw')}
            onChange={(event) => {
              // console.log(event);
              setValue("klimat_zw",event);
            }}
            // color="warning"
            label={watch('klimat_zw')?"opłata klimatyczna - nie pobrana":"opłata klimatyczna - pobrana"}
          />
        </Grid> }

    </React.Fragment>
  );
}


export default Nocleg4aPlatnosc;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FunctionsIcon from '@mui/icons-material/Functions';
import { useQuery } from '@tanstack/react-query';
import { useTheme } from '@mui/material/styles'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
        Autocomplete,
        Box,
        Button,
        Grid,
        MenuItem,
        TextField,
        Typography,
      } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import useMediaQuery from "@mui/material/useMediaQuery";
import { fetchDane } from "../api";
import { opcjeLista } from "../komponenty/labelki";
import ZestawieniaOperacje from './ZestawieniaOperacje';
      
///// schemat walidacji pól formularza ///////////////////////////////////////
const schema = yup
  .object({
        kategoria: yup.string().required("No, najpierw wybierz kryterium... ;-)"),
        // wybor: yup.string().nullable()
        //   .when('kategoria', {
        //   is: "9_uwagi",
        //   then: yup.string().nullable().optional(),
        //   otherwise: yup.string().nullable()
        //     .typeError("...i trzeba coś wskazać... ;-)")
        //     .required("...i trzeba coś wskazać... ;-)"),
        // }),
        // uwagi: yup.string().nullable()
        //   .when('kategoria', {
        //   is: "9_uwagi",
        //   then: yup.string().nullable()
        //         .typeError("...no trzeba coś napisać... ;-)")
        //         .required("...no trzeba coś napisać... ;-)"),
        //   otherwise: yup.string().nullable().optional(),
        // }),
  })
  .required();
// ############################################################################

export default function ButtonZestawienia(props) {
  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const operacje = useQuery(['dane'], fetchDane);

  const startoweDane = {
    rok: null,
    kategoria: "",
    wybor: "",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
        setOpen(false);
  };

  let opcje_kategorie = [
        // { value: "", label: "" },
        { value: "1_osoba_wyk", label: "Załatwiał/a" },
        { value: "2_osoba_dok", label: "Dokumenty są u..." },
        { value: "3_projekt", label: "Projekt" },
        { value: "4_kategoria", label: "Kategoria" },
        { value: "5_przedmiot", label: "Przedmiot" },
        // { value: "6_operacja", label: "Inna operacja" },
        // { value: "7_plik", label: "Dołącz pliki" },
        { value: "9_uwagi", label: "Dodatkowe uwagi" },
  ];

  const {
        register,
        handleSubmit,
        // reset,
        setValue,
        resetField,
        watch,
        formState: { errors },
      } = useForm({
        defaultValues: startoweDane,
        resolver: yupResolver(schema),
        // mode: "onSubmit",
        reValidateMode: "onSubmit" | "onChange",
  });
    
  const onSubmit = (data) => {
        // console.log(data);
        if (operacje.isSuccess) {
        let lista = operacje.data.uzupelnienia.filter(u=>u.kategoria===data.kategoria);
        if (data.wybor) { lista = lista.filter(u=>String(u.wybor)===String(data.wybor)); }
        lista = lista.map(o=>{
           let wiersz = {};
           if(!data.rok) { wiersz = operacje.data[o.tabela.toLowerCase()].filter(i=>i.id===o.wiersz)[0] } 
           else { wiersz = operacje.data[o.tabela.toLowerCase()].filter(i=> i.id===o.wiersz && i.data.substr(0,4) === data.rok)[0] }
           if (wiersz&&!wiersz.tabela) { wiersz.tabela = o.tabela }
           return wiersz
        });
        lista = lista.filter(o=>o);
        setList(lista);
        // console.log(lista);
        }
  };
    
  // console.log(props);

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen} endIcon={<FunctionsIcon sx={{ mt: "-3px" }} />}>
        Zestawienia
      </Button>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
                <Typography variant='body2'>Wyszukiwanie operacji wg zadanych kryteriów:</Typography>
        <Box component="form" sx={{ mt: 2 }}>
        <Grid container spacing={1}>

{/* ############### RODZAJ ######################################################################### */}
          <Grid item md={3} xs={12}>
            <TextField
              {...register("kategoria")}
              select
              label="kategoria"
              defaultValue="4_kategoria"
              value={watch("kategoria")}
              onChange={(event) => {
                setValue("kategoria", event.target.value);
                resetField("wybor");
                resetField("kwota");
              }}
              variant="outlined"
              size="small"
              error={errors.kategoria ? true : false}
              helperText={errors.kategoria && errors.kategoria.message}
          >
              {opcje_kategorie.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {!watch("kategoria") && (
          <Grid item md={6} xs={12}>
              <Typography sx={{ p: 1, ml: 3 }}>
                Najpierw wybierz kryterium...
              </Typography>
          </Grid>
          )}

{/* ############### OSOBA ######################################################################### */}
          {( watch("kategoria") === "1_osoba_wyk" 
          || watch("kategoria") === "2_osoba_dok" ) 
          && (
            <Grid item xs={12} md={6}>
            <Autocomplete
              {...register("wybor")}
              options={operacje.isSuccess && operacje.data.osoby}
              getOptionLabel={(o) => {
                if (typeof o === "string"||typeof o === "number") {
                  let os = operacje.data.osoby.filter(oso=>Number(oso.id)===Number(o))[0];
                  return os ? os.id+". "+os.imie+" "+os.nazwisko+" - PESEL: "+os.PESEL : "";
                } else if (o) {
                  return o.id+". "+o.imie+" "+o.nazwisko+" - PESEL: "+o.PESEL;
                }
              }}
              // filterOptions={createFilterOptions({
              //   matchFrom: 'any',
              //   trim: true,
              // })}
              value={watch("wybor")}
              onChange={(i,v) => {
                setValue("wybor", v?v.id:null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="wybierz osobę"
                  // placeholder="wpisz trzy litery aby zacząć wyszukiwanie"
                  // helperText="wpisuj w kolejności: nazwisko imie PESEL"
                  size="small"
                  margin="dense"
                  variant="outlined" 
                  error={errors.wybor ? true : false}
                  helperText={errors.wybor && errors.wybor.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px" },
                  }}
                />
              )}
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}
            />
          </Grid>
          )}
{/* ############# PROJEKT ########################################################################### */}
          {watch("kategoria") === "3_projekt" && (
            <Grid item xs={12} md={6}>
            <Autocomplete
              {...register("wybor")}
              id="projektTag"
              options={opcjeLista(operacje.isSuccess && operacje.data.uzupelnienia,"wybor",{key: "kategoria", val: "3_projekt"})}
              freeSolo
              // autoComplete
              autoHighlight
              value={watch("wybor")}
              onChange={(i,v) => {
                // console.log(i,v);
                setValue("wybor", v);
              }}
              onInputChange={(i,v) => {
                setValue("wybor", v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="wybierz projekt"
                  // placeholder="wpisz trzy litery aby zacząć wyszukiwanie"
                  // helperText="wpisuj w kolejności: nazwisko imie PESEL"
                  size="small"
                  margin="dense"
                  variant="outlined" 
                  error={errors.wybor ? true : false}
                  helperText={errors.wybor && errors.wybor.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px" },
                  }}
                />
              )}
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}
            />
            </Grid>
          )}
{/* ############# KATEGORIA ########################################################################### */}
          {watch("kategoria") === "4_kategoria" && (
            <Grid item xs={12} md={6}>
            <Autocomplete
              {...register("wybor")}
              id="kategoriaTag"
              options={opcjeLista(operacje.isSuccess && operacje.data.uzupelnienia,"wybor",{key: "kategoria", val: "4_kategoria"})}
              freeSolo
              // autoComplete
              autoHighlight
              value={watch("wybor")}
              onChange={(i,v) => {
                // console.log(i,v);
                setValue("wybor", v);
              }}
              onInputChange={(i,v) => {
                setValue("wybor", v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="wybierz kategorię"
                  // placeholder="wpisz trzy litery aby zacząć wyszukiwanie"
                  // helperText="wpisuj w kolejności: nazwisko imie PESEL"
                  size="small"
                  margin="dense"
                  variant="outlined" 
                  error={errors.wybor ? true : false}
                  helperText={errors.wybor && errors.wybor.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px" },
                  }}
                />
              )}
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}
            />
            </Grid>
          )}
{/* ############## PRZEDMIOT ########################################################################## */}
          { watch("kategoria") === "5_przedmiot" && (
            <Grid item xs={12} md={6}>
            <Autocomplete
              {...register("wybor")}
              id="przedmiotTag"
              options={operacje.isSuccess && operacje.data.inwentarz}
              getOptionLabel={(o) => {
                if (typeof o === "string"||typeof o === "number") {
                  let opt = operacje.data.inwentarz.filter(p=>Number(p.id)===Number(o))[0];
                  return opt ? opt.id+". "+opt.nazwa : "";
                } else if (o) {
                  return o.id+". "+o.nazwa;
                }
              }}
              // freeSolo
              // autoComplete
              autoHighlight
              value={watch("wybor")}
              onChange={(i,v) => {
                setValue("wybor", v.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="wybierz przedmiot"
                  // placeholder="wpisz trzy litery aby zacząć wyszukiwanie"
                  // helperText="wpisuj w kolejności: nazwisko imie PESEL"
                  size="small"
                  margin="dense"
                  variant="outlined" 
                  error={errors.wybor ? true : false}
                  helperText={errors.wybor && errors.wybor.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px" },
                  }}
                />
              )}
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}
            />
            </Grid>
          )}
{/* ########## KWOTA ############################################################################## */}
          {/* {    watch("kategoria")
            && watch("kategoria") !== "2_osoba_dok" 
            && watch("kategoria") !== "9_uwagi" 
            && (
            <Grid item xs={12} md={3} sx={{'& .MuiFormControl-root': { mt: 0 }}} >
              <TextField 
                {...register("kwota")}
                label="kwota"
                type="number"
                step="0.01"
                min="0"
                id="kwota"
                value={watch('kwota')}
                onChange={(event) => {
                  setValue("kwota", event.target.value.replace(',', '.'));
                }}
                error={errors.kwota ? true : false}
                helperText={errors.kwota && errors.kwota.message}
                size="small"
                margin="dense"
                variant="outlined" 
                sx={{ 
                  '& .MuiOutlinedInput-input': { p: "8.5px" },
                  // '& .MuiTextField-root': { mt: "15px" } 
                }}
              />
            </Grid>
          )} */}
{/* ############### ROK ########################################################################## */}
          { watch("kategoria") && <Grid item md={1} xs={5} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
          <TextField
              {...register("rok")}
              label="rok"
              value={watch("rok")}
              onChange={(event) => {
                setValue("rok", event.target.value);
              }}
              variant="outlined"
              size="small"
              error={errors.rok ? true : false}
              helperText={errors.rok && errors.rok.message}
              margin="dense"
              sx={{ 
                '& .MuiOutlinedInput-input': { p: "8.5px" },
                '& .MuiFormControl-root': { m: 0 },
              }}
              />
          </Grid> }
{/* ############### PRZYCISK WYŚLIJ ################################################################# */}
          <Grid item md={2} xs={7} sx={{ textAlign: "right" }}>
            <Button 
              variant="outlined" 
              // disabled 
              endIcon={<SendIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              szukaj
            </Button>
          </Grid>
        </Grid>
      </Box>
      </DialogTitle>
        <DialogContent>

        {list && <ZestawieniaOperacje operacje={list} />}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            zamknij okno
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import * as React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import dayjs from 'dayjs';
import {
  // Autocomplete,
  Box,
  Button,
  DialogContent,
  // Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SendIcon from '@mui/icons-material/Send';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { postWydatek, fetchDane } from '../api';
import KomunikatyWysylka from '../komponenty/KomunikatyWysylka';
import { transWydatek } from '../komponenty/labelki';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pl';
import AutoVirOsobeWybierz from "../komponenty/AutoVirOsobeWybierz";

/// schemat walidacji pól formularza ///////////////////////////////////////
const schema = yup.object().shape({
  data: yup.string()
          .required("Podaj datę zrobienia zakupów"),
  kwota: yup.string()
          .required("Wpisz kwotę"),
  tytul: yup.string()
          .required("Opisz z grubsza zakupy"),
});
// ############################################################################

// początkowe wartości formularza - potrzebne do resetu
// const poczatek = {
//   id: "nowa",
//   data: null,
//   tytul: "",
//   kwota: null,
//   zobowiazanie: false,
//   wykonawca: null,
//   wierzyciel: null,
//   splaconeprzez: null,
//   bank: null,
//   nrfaktury: null,
//   uwagi: null,
//   info: {},
// }

const WydatekFormularz = (props) => {

  // inicjalizacja formularza
  const {
    register,
    handleSubmit,
    setValue,
    // trigger,
    watch,
    // reset,
    formState: { errors },
  } = useForm({
    defaultValues: transWydatek(props.wydatek),
    resolver: yupResolver(schema),
  });

  // const cred = useQuery(['JUser'], fetchJUser);
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const cred = useQuery(['dane','cred'], fetchDane);
  const operacje = useQuery(['dane','wydatki'], fetchDane);
  var wydatki = operacje.isSuccess && operacje.data.wydatki;
  const queryClient = useQueryClient();
    
  // const [files, setFiles] = React.useState([]);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const mutateWydatek = useMutation(wydatek => postWydatek(['operacje'],wydatek), {
  //   onError: (rollback) => {
  //     rollback();
  //   },
  //   onSettled: data => {
  //     // console.log(data);
  //     let newLista = wydatki.filter((o) => o.id !== data.data.id);
  //     newLista.push(data.data);
  //     let newOper = { 
  //       ...operacje.data, 
  //       wydatki: newLista,
  //     };
  //     queryClient.setQueryData(['operacje'], newOper);

  //     // queryClient.setQueryData('kasa', { ...kasaLista, data: [...kasaLista.data, data.data]});
  //     setTimeout(function () {
  //       props.setOpen(false);
  //     }, 1300);
  //   },
  // });
  const mutateWydatek = useMutation(wydatek => postWydatek(['x'],wydatek), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      // console.log({wydatki: [data.data]});
      queryClient.setQueryData(['dane','wydatki',{id: data.data.id}], {wydatki: [data.data]});
      if (data.data.dodane===data.data.aktualizacja) {
        // console.log(wydatki);
        wydatki = [...wydatki, data.data];
        queryClient.setQueryData(['dane','wydatki'], {wydatki: wydatki});
      }
      setTimeout(function () {
        props.setOpen(false);
      }, 1300);
    },
  });

  // ustawienie wartości formularza, jeśli zmienią się props:
  // React.useEffect(() => {
  //   setValue("id",props.wydatek.id);
  //   setValue("data",props.wydatek.data);
  //   setValue("tytul",props.wydatek.tytul);
  //   setValue("kwota",props.wydatek.kwota);
  //   setValue("zobowiazanie",props.wydatek.zobowiazanie);
  //   setValue("wykonawca",props.wydatek.wykonawca);
  //   setValue("wierzyciel",props.wydatek.wierzyciel);
  //   setValue("splaconeprzez",props.wydatek.splaconeprzez);
  //   // setValue("bank",props.wydatek.bank);
  //   // setValue("info",props.wydatek.info);
  //   setValue("uwagi",props.wydatek.uwagi);
  //   setValue("nrfaktury",props.wydatek.nrfaktury);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.wydatek]);

  const onSubmit = (data) => {
    let dane = {
      formularz: data, 
    };
    !mutateWydatek.isLoading && mutateWydatek.mutate(dane);
  };
  // console.log(watch());

  let opcjeWydatek=[
        // { value: "0", label: "wydatek zwykły / spłata" },
        // { value: "1", label: "do zapłacenia później" },
        // { value: "2", label: "do zapłacenia przelewem" },
        // { value: "3", label: "opłacony..." },
        { value: "0", label: "wydatek Gospodarza", cred: ["SKAR", "RADA", "GOSP"] },
        { value: "1", label: "sprzedawca czeka na zapłatę Gospodarza", cred: ["SKAR", "RADA", "GOSP", "STOW"] },
        // { value: "2", label: "sprzedawca czeka na przelew", cred: ["SKAR", "RADA", "GOSP", "STOW"] },
        // { value: "3", label: "kupujący czeka na zwrot - PRZELEW", cred: ["SKAR", "RADA", "GOSP", "STOW"] },
        // { value: "4", label: "kupujący czeka na zwrot - od Gospodarza", cred: ["SKAR", "RADA", "GOSP", "STOW"] },
        { value: "5", label: "faktura opłacona przez kupującego - sprawa zamknięta", cred: ["SKAR", "RADA", "GOSP", "STOW"] },
        // { value: "99", label: "zakończony i zarchiwizowany", cred: ["SKAR"] },
      ];

  /**
   * 
   */

  return (
    <>
        <DialogContent>
        <Box component="form" sx={{ mt: 2 }}>

              <KomunikatyWysylka stany={mutateWydatek} />

              
              <Grid container spacing={1}>

              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                <Grid xs={12} md={4}>
                  <input type="hidden" {...register("id")} value={watch("id")} />
                  <DatePicker 
                    {...register("data")}
                    label="Data wydatku"
                    value={watch("data")?dayjs(watch("data")):null}
                    id="dataWydatku"
                    format="YYYY-MM-DD"
                    onChange={(event) => {
                      setValue("data", event.format("YYYY-MM-DD"));
                    }}
                    clearable
                    slotProps={{
                      textField: {
                        error: errors.data ? true : false,
                        helperText: errors.data ? errors.data.message : false,
                        size: 'small',
                      },
                    }}
                    localeText={{ datePickerToolbarTitle: 'Data wydatku' }}
                    sx={{ 
                      '& .MuiOutlinedInput-input': { backgroundColor: "none",pt:1,pb:1 },
                      '& .MuiInputLabel-root': { zIndex: 0 }
                    }}
                  /> 
                </Grid>
              </LocalizationProvider>

              <Grid xs={12} md={8}>
                <TextField 
                  select
                  {...register("zobowiazanie")}
                  value={watch("zobowiazanie")}
                  onChange={(event) => {
                    setValue("zobowiazanie", event.target.value);
                  }}
                  label="Typ"
                  defaultValue={0}
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="outlined"
                >
                  {opcjeWydatek.map((option) => (
                    <MenuItem key={option.value} value={option.value} disabled={cred.isSuccess && option.cred.includes(cred.data.grupa)?false:true}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid xs={12} md={4}>
                <TextField 
                  {...register("kwota")}
                  id="kwota"
                  value={watch("kwota")}
                  onChange={(event) => {
                    setValue("kwota",event.target.value.replace(',','.'));
                  }}
                  label="Kwota"
                  error={errors.kwota ? true : false}
                  helperText={errors.kwota && errors.kwota.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px" },
                    '& .MuiInputLabel-root': { marginTop: "-8px" }, 
                    '& .MuiInputLabel-shrink': { marginTop: "0px" }, 
                  }}
                />

              </Grid>

              <Grid xs={12} md={8} >
                <TextField
                  {...register("nrfaktury")}
                  id="nrfaktury"
                  value={watch("nrfaktury")}
                  onChange={(event) => setValue("nrfaktury", event.target.value)}
                  label="Nr faktury"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  error={errors.nrfaktury ? true : false}
                  helperText={errors.nrfaktury && errors.nrfaktury.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px", mt:0 },
                  }}
                />
              </Grid>

              <Grid xs={12}>
                <TextField 
                  {...register("tytul")}
                  id="tytul"
                  value={watch("tytul")}
                  onChange={(event) => setValue("tytul", event.target.value)}
                  label="Opis"
                  error={errors.tytul ? true : false}
                  helperText={errors.tytul && errors.tytul.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px" },
                    '& .MuiInputLabel-root': { marginTop: "-8px" }, 
                    '& .MuiInputLabel-shrink': { marginTop: "0px" }, 
                  }}
                />
              </Grid>

              {( Number(watch("zobowiazanie")) === 1 
              || Number(watch("zobowiazanie")) === 2
              || Number(watch("zobowiazanie")) === 3
              || Number(watch("zobowiazanie")) === 4
              ) && <Grid xs={12}>
                <AutoVirOsobeWybierz
                  {...register("wierzyciel")}
                  value={watch("wierzyciel")}
                  label="czeka na pieniądze:"
                  onChange={(v) => {
                    setValue("wierzyciel", v);
                  }}
                />
              </Grid> }

              {( Number(watch("zobowiazanie")) === 5 
              ) && <Grid xs={12}>
                <AutoVirOsobeWybierz
                  {...register("wykonawca")}
                  value={watch("wykonawca")}
                  label="zakupy robił:"
                  onChange={(v) => {
                    setValue("wykonawca", v);
                  }}
                />
              </Grid> }

              <Grid xs={12}>
                <TextField
                  {...register("uwagi")}
                  id="uwagi"
                  multiline
                  rows={6}
                  // placeholder="tu wpisz uwagi do tego konkretnego noclegu"
                  value={watch("uwagi")}
                  onChange={(event) => setValue("uwagi", event.target.value)}
                  label="Uwagi"
                  error={errors.uwagi ? true : false}
                  helperText={errors.uwagi && errors.uwagi.message}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: 0 },
                    '& .MuiFormControl-root': { m: 0 },
                  }}
                />
              </Grid>

              <Grid xs={12} sx={{ textAlign: "center" }}>
              <Button 
                variant="outlined" 
                endIcon={<SendIcon />}
                onClick={handleSubmit(onSubmit)}
                disabled={mutateWydatek.isLoading}
              >
                zapisz
              </Button>
              <br/><br/>
              </Grid>
            </Grid>
            </Box>
        </DialogContent>
        {/* <DialogActions sx={{ pt: "1em", borderTop: "1 solid grey" }}>
        </DialogActions> */}
    </>
  );
}

export default WydatekFormularz;

import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FinanseTabsSzczegoly from './FinanseTabsSzczegoly';
import { Badge, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import { useQueryClient } from '@tanstack/react-query';

function Row(props) {
  const { row } = props;
  if (row.info && typeof row.info === 'string') { row.info = JSON.parse(row.info); }
  let przeplywy = row.uzup?Object.keys(row.uzup.filter(u=>u.wybor==="przepływy")).length:0;
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const invisible = queryClient.getQueryData(['badzeSumy']);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  // console.log(props);
  
  return (
    <React.Fragment>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer', backgroundColor: (row.zobowiazanie===1||row.zobowiazanie===2||przeplywy>0)&&"#eef" }} onClick={() => setOpen(!open)} >
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.id}
        </TableCell>
        { !smallScreen && <TableCell><Typography variant="body2" noWrap>{row.data}</Typography></TableCell> }

        <TableCell sx={{ '& .MuiBadge-root': { display: "block", top: "10px", right: "10px" } }}>

        <Tooltip title="Liczba operacji uzupełnionych informacjami" placement="top">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={row.uzup&&row.uzup.length}
          variant="outlined"
          color="jasnyzielony"
          invisible={invisible}
        />
        </Tooltip>

        <Tooltip title="Liczba operacji z dodanym plikiem" placement="top-start">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={row.pliki&&row.pliki.length}
          variant="outlined"
          color="info"
          invisible={invisible}
          sx={{ mr: row.uzup&&row.uzup.length?3:0 }}
        />
        </Tooltip>

          {smallScreen?<b>{row.data}<br/></b>:null}
          
          {row.tabela==="Banki" && row.info.opis}
          {row.tabela==="Przychody" && row.gadzet}
          {row.tabela==="Wydatki" && row.tytul}
          {row.tabela==="Noclegi" && "Noclegi imienne"}
          {row.tabela==="ipos" && "Zarejestrowane w kasie fiskalnej"}
          
          {przeplywy>0&&<i><br/>UWAGA: operacja niesumowana - kategoria: "przepływy"</i>}
          
          {(
               row.zobowiazanie===2
            || row.zobowiazanie===3
            ) && <i><br/>UWAGA: operacja niesumowana - płatność przelewem</i>}
          
          {(
               row.zobowiazanie===1
            || row.zobowiazanie===4
            ) && <i><br/>UWAGA: operacja niesumowana - dług - do spłacenia przez Gospodarza</i>}

          {(
               row.zobowiazanie===5
            ) && <i><br/>UWAGA: operacja niesumowana - płatność bezpośrednio z wykonawcą</i>}
          
          { smallScreen && <Typography variant="body2" align="right" noWrap>
            <b>{row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</b>
          </Typography> }
        </TableCell>
        { !smallScreen && <TableCell align="right">
          <Typography variant="body2" noWrap>
            {row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł
          </Typography>
        </TableCell> }
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#dfd" }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
                {/* struktura Tabs dla wyświetlenia szczegółów wybranej operacji: */}
                <FinanseTabsSzczegoly dane={row} />
            </Box>
            {/* {JSON.stringify(row)} */}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ZestawieniaOperacje(props) {

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  let suma = 0;

  return (
    <div>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            { !smallScreen && <TableCell>data</TableCell> }
            <TableCell>nazwa</TableCell>
            { !smallScreen && <TableCell align="right">kwota</TableCell> }
          </TableRow>
        </TableHead>
        <TableBody>
          {props.operacje && props.operacje.map((k) => {
            suma += k.kwota;
            return <Row key={"operacja-"+k.id+k.tabela} typ={props.typ} row={k} />
          })}
          {/* {Object.keys(props.operacje).sort().reverse().map((k) => (
            <Row key={"operacja-"+k} row={props.operacje[k]} />
          ))} */}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align='right'>SUMA wszystkich operacji:</TableCell>
            <TableCell align='right'>{suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}

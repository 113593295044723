import * as React from "react";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postOdwiedziny } from "../api";
import { getSumyGrupowe, transGrupa } from "../komponenty/labelki";
import GrupaSzczegoly from "../odwiedziny/GrupaSzczegoly";
import ButtonNoclegDodaj from "./ButtonNoclegDodaj";
import NoclegiListaGrupa from "./NoclegiListaGrupa";
import KomunikatyWysylka from "../komponenty/KomunikatyWysylka";
// import NoclegiLista from "./NoclegiLista";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import DialogInstrukcje from "../statyczne/DialogInstrukcje";

/**
 * W Odwiedzinach zawsze dodawanie Grupy
 * w szczegółach grupy lista noclegów
 * 
 * w Noclegu dwa przyciski: noclegi w grupie i edycja noclegu
 * na liście dodawanie pojedynczego noclegu
 *    - możliwość dodania grupy do noclegu - jako select
 *    - możliwość dodania kolejnego noclegu ze skopiowanymi danymi oprócz Osoby
 *    - możliwość dodania i zamknięcia (resetu) formularza
 */

// #######################################################################
function NoclegiFormularzSzczegolyGrupy(props) {

  let grupa = transGrupa(props.grupa);
  let sumyGrupowe = {kwota: 0, klimat: 0, noclegow: 0}
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const queryClient = useQueryClient();      
  const noclegi = queryClient.getQueryData(['dane','noclegi']).noclegi;
        
  if (noclegi) {
    sumyGrupowe = getSumyGrupowe(noclegi.filter(n=>n.gid===grupa.gid));
  }

  const [grupaOpen, setGrupaOpen] = React.useState(grupa.id==="nowa" ? true : false);

  React.useEffect(() => {
    grupa.id==="nowa" ? setGrupaOpen(true) : setGrupaOpen(false)
  }, [grupa.id]);

  return (
    <>
      <Typography variant="h6" onClick={() => setGrupaOpen(!grupaOpen)} sx={{ cursor: "pointer" }}>
        <IconButton aria-label="expand row">
          {grupaOpen ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
        </IconButton>
        {grupa.nazwa}
      </Typography>

      <Typography variant="body1" noWrap textAlign={"right"}>
        {grupa.od} - {grupa.do}
        <br />
        osób <i>(zgł.: {grupa.liczba})</i> - zarejestrowanych:{" "}
        <b>{sumyGrupowe.noclegow}</b>
        <br />
        suma opłat w grupie: <b>{sumyGrupowe.kwota}</b> zł
        <br />
        { sumyGrupowe.klimat > 0 && <i>klimatyczne: {sumyGrupowe.klimat}</i> }
      </Typography>

      <Collapse in={grupaOpen} timeout="auto" unmountOnExit>
        <hr/>
        <GrupaSzczegoly grupa={grupa} resetujGrupe={props.resetujGrupe} />
      </Collapse>
      
      <hr/>
      <Typography variant="body2">Noclegi w Grupie:</Typography>
      <NoclegiListaGrupa grupa={grupa} />
      {/* <NoclegiLista grupa={grupa} /> */}

    </>
  );
}










// ### przycisk otwierający Dialog z noclegami w grupie ####################################

function ButtonNoclegiGrupa(props) {
  
  console.log(props);
  const queryClient = useQueryClient();      
  const cred = queryClient.getQueryData(['dane','cred']);
  const odwiedzinyLista = queryClient.getQueryData(['dane','odwiedziny']).odwiedziny;
  console.log(odwiedzinyLista);
  let grupa = queryClient.getQueryData(['dane','odwiedziny',{id:props.id}]);
  grupa = grupa && grupa.odwiedziny[0];
  console.log(grupa);

  // const [grupa, setGrupa] = React.useState(props.grupa);
  const [open, setOpen] = React.useState(false);
  const [openDialBlokada, setOpenDialBlokada] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // React.useEffect(() => {
  //   setGrupa(props.grupa)
  // }, [props.grupa]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  // console.log(grupa);

  // const odwiedzinyLista = useQuery('odwiedziny', fetchOdwiedziny);
  // const operacje = useQuery(['operacje'], fetchOperacje);

  // const cred = useQuery(['JUser'], fetchJUser);
  const mutateOdwiedziny = useMutation(gr => postOdwiedziny(['x'],gr)
    , {
      onError: (rollback) => {
        rollback()
      },
      onSuccess: data => {
        let zgloszenia = odwiedzinyLista.filter((odw) => odw.id !== data.data[0].id);
        queryClient.setQueryData(['dane','odwiedziny'], {odwiedziny: [data.data[0], ...zgloszenia]});
        queryClient.setQueryData(['dane','odwiedziny',data.data[0].id], {odwiedziny: [data.data[0]]});
        setTimeout(function () {
          mutateOdwiedziny.reset();
          setOpenDialBlokada(false);
        }, 10);
      }
    }
  );

  const handleConfirmBlokada = () => {
    grupa.blokada = Number(grupa.blokada)===0?1:0;
    mutateOdwiedziny.mutate(grupa);
  }
  console.log(grupa);

  return (
    <>
      {((props.button || fullScreen) && grupa) && <Fab
        color="primary"
        aria-label="edit"
        sx={props.pozycja}
        onClick={handleClickOpen}
        size={props.size ? props.size : ""}
        // disabled
      >
          <PeopleOutlineIcon />
      </Fab> }
      {!props.button && !fullScreen && grupa && <Button
        // disabled
        color="primary"
        aria-label="edit"
        variant="outlined"
        sx={props.pozycja}
        onClick={handleClickOpen}
        size={props.size ? props.size : ""}
      >
        <GroupAddIcon sx={{ mr: 1 }} /> Noclegi w Grupie
      </Button> }

      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Fab
            size="small"
            aria-label="zamknij"
            sx={{ position: "absolute", top: "1em", right: "1em" }}
            onClick={handleClose}
          >
            <CloseOutlinedIcon />
          </Fab>
          Noclegi w Grupie - ID: {grupa&&grupa.id}
          <DialogInstrukcje tekst="noclegiGrupa" />
        </DialogTitle>
        <DialogContent>
          
          <KomunikatyWysylka stany={mutateOdwiedziny} />
          <NoclegiFormularzSzczegolyGrupy grupa={grupa} />

        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }} >
          <Grid container spacing={0}>
            <Grid item xs={6} align="left">
              <Button 
                disabled={ !(cred.grupa==="GOSP") }
                onClick={()=>setOpenDialBlokada(true)} 
                variant="outlined" 
                size="small"
              >
                  {(cred.grupa==="GOSP") 
                    ? (Number(grupa.blokada)===0 ? "zablokuj" : "odblokuj")
                    : (Number(grupa.blokada)===0 ? "odblokowane" : "zablokowane")
                  }
              </Button>
            </Grid>
            <Grid item xs={6} align="right">
              <ButtonNoclegDodaj grupa={grupa} dodajwgrupie 
                disabled={Number(grupa.blokada)===0?false:true} 
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openDialBlokada}
        onClose={()=>setOpenDialBlokada(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Czy aby na pewno???</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { mutateOdwiedziny.isIdle ? <>
              Potwierdź chęć <b>{Number(grupa.blokada)===0?"zablokowania":"odblokowania"}</b><br/>edycji noclegów w Grupie:
            </> : <>
              Trwa przesyłanie danych... 
            </>}
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmBlokada}
            disabled={!mutateOdwiedziny.isIdle}
            sx={{ position: "absolute", left: "1em" }}
          >
            Tak, {Number(grupa.blokada)===0?"zablokuj":"odblokuj"}
          </Button>
          <Button 
            onClick={()=>setOpenDialBlokada(false)} 
            disabled={!mutateOdwiedziny.isIdle}
            autoFocus
          >
            Przemyślę...
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

export default ButtonNoclegiGrupa;

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Badge, Box, DialogContent, Fab, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useQueryClient } from '@tanstack/react-query';
import FinanseTab3 from '../FinanseTab3';
import dayjs from 'dayjs';

export default function ButtonPliki(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const operacje = useQuery(['operacje'], fetchOperacje);
  // const operacje = useQuery(['dane','pliki_polaczenia'], fetchDane);
  // const pliki = operacje.isSuccess && operacje.data.pliki_polaczenia.filter(u=>u&&(u.tabela==="Przychody" && u.wiersz===props.id));
  const queryClient = useQueryClient();
  const operacje = queryClient.getQueryData(['dane']);
  let data = dayjs(props.miesiac).endOf('month').format("YYYY-MM-DD");
  let rapKas = operacje.przychody.filter(o=>o.gadzet==="RapKas"&&o.data===data)[0];
  let pliki = rapKas && operacje.pliki_polaczenia.filter(u=>u&&(u.tabela==="Przychody" && u.wiersz===rapKas.id));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
        setOpen(false);
  };

  // console.log(rapKas);
  // button edycji plików - widoczny w szczegółach listy wydatków (WydatkiLista)
  return (
    <React.Fragment>
      <Fab
            color="primary"
            aria-label="edit"
            sx={props.pozycja}
            onClick={handleClickOpen}
            size="small"
            disabled={!rapKas}
      >
        <Badge anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} 
            sx={{ p: '5px' }}
            badgeContent={pliki?pliki.length:0}
            color="secondary"
        >
              <AttachFileIcon />
        </Badge>
      </Fab>



      { rapKas && <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Fab
            size="small"
            aria-label="zamknij"
            sx={{ position: "absolute", top: "1em", right: "1em" }}
            onClick={handleClose}
          >
            <CloseOutlinedIcon />
          </Fab>
          Załączniki do Raportu Kasowego
        </DialogTitle>

        <DialogContent>
          <Box sx={{ width: "100%" }}>
                za miesiąc: {props.miesiac} <small> <i> (ID:{rapKas.id})</i></small><br/>
                <hr/>
                <FinanseTab3 
                        dane={{ tabela: "Przychody", id: rapKas.id }} 
                        // setOpen={setOpen} 
                />
          </Box>
        </DialogContent>

      </Dialog> }
    </React.Fragment>
    )
}

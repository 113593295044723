import { postPlik } from "./Plik";
import {
  postOdwiedziny,
  postNocleg,
  postWydatek,
  postUzupelnienia,
  postRapKas,
  postMigracje,
  deleteObiekt,
} from "./Post";

/*
żeby wygenerowanie tokenu CQRS zadziałało - 
- w szablonie musi być linia dodająca token:
<div id="check_ChS"><?php echo JHtml::_( 'form.token' ); ?></div>
*/
export const token = document.querySelector("div#check_ChS input")?document.querySelector("div#check_ChS input").name:'4324242424224242342424';

export {postOdwiedziny};
export {postNocleg};
export {postWydatek};
export {postUzupelnienia};
export {postRapKas};
export {postMigracje};
export {postPlik};
export {deleteObiekt}

// OSP:
// export {fetchOSP};
// export {postOSP};




// pobranie listy Danych wg kryteriów przekazanych w kluczu 
export const fetchDane = async (key) => {

  // definiuję URL dla API na podstawie klucza:
  let url = "";
  let suffix = "";
  if(key.queryKey[1]) { // jeśli zdefiniowany zakres to:
    if (key.queryKey[1]==="cred") { // jeśli pytamy o credentials, to:
        url = "/index.php?option=com_content&task=api.cred&format=json"
    } else {
        if(key.queryKey[2]) { // jeśli zdefiniowane dodatkowe warunki to:
          // console.log("key2: ",key.queryKey[2]);
                Object.keys(key.queryKey[2]).map(klucz=>{
                  suffix += "&"+klucz+"="+key.queryKey[2][klucz]
                  return true;
                });
        }
        url = "/index.php?option=com_content&task=api.lista&format=json&zakres=" + key.queryKey[1] + suffix
    }
  } else { // url pobierające całość danych 
    url = "/index.php?option=com_content&task=api.lista&format=json&zakres=all"
  }
  // wykonanie zapytania
  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN + url
  ).then(res=>res.json()).then(res=>{
        // console.log(res);
        let cred = {
                grupa: res.messages.grupa[0],
                userID: res.messages.user[0],
                username: res.messages.user[1],
                name: res.messages.user[2],
                PESEL: res.messages.user[3],
        }
        res.data = {...res.data,cred};
        return res.data
  });
  return response;
};







// #######################################################
// pobranie historii zmian danego elementu
export const fetchHistoria = async (key) => {
  let tabela = key['queryKey'][1];
  let id = key['queryKey'][2];
  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN +
    "/index.php?option=com_content&task=api.historia&format=json&tabela="+tabela+"&id="+id
  ).then(res => res.json()).then(json=> {
    return json.data;
  });
  return response;
};

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogContent, DialogTitle, Fab } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';
// import KomunikatyWysylka from '../../komponenty/KomunikatyWysylka';
import Nocleg1Pesel from './Nocleg1Pesel';
import Nocleg2Osoba from './Nocleg2Osoba';
import Nocleg3Nocleg from './Nocleg3Nocleg';
import Nocleg4aPlatnosc from './Nocleg4aPlatnosc';
import { 
  useMutation, 
  useQueryClient, 
  // useQuery 
} from '@tanstack/react-query';
import { postNocleg } from '../../api';
import { useForm } from "react-hook-form";

import DialogInstrukcje from '../../statyczne/DialogInstrukcje';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KomunikatyWysylka from '../../komponenty/KomunikatyWysylka';


const steps = ['PESEL', 'Osoba', 'Nocleg', 'Opłata'];

export default function NoclegFormularzStepper(props) {

  const [activeStep, setActiveStep] = React.useState(0);
  const queryClient = useQueryClient();
  let nocleg = props.nocleg && queryClient.getQueryData(['dane','noclegi',{id:props.nocleg.id}]);
  nocleg = nocleg ? nocleg.noclegi[0] : {};
  let osoba = nocleg && nocleg.idosoby && queryClient.getQueryData(['dane','osoby',{id:nocleg.idosoby}]);
  osoba = osoba ? osoba.osoby[0] : {};
  let grupa = queryClient.getQueryData(['dane','odwiedziny',{id:props.grupa?props.grupa.id:nocleg.gid}]);
  grupa = grupa ? grupa.odwiedziny[0] : {};
  // console.log(nocleg);
  // console.log(osoba);
  // console.log(grupa);
  nocleg.nuwagi = nocleg && ((nocleg.info && JSON.parse(nocleg.info).uwagi) || (nocleg.ninfo && nocleg.ninfo.uwagi));
  nocleg.karta = nocleg && ((nocleg.info && Number(JSON.parse(nocleg.info).karta)) || (nocleg.ninfo && Number(nocleg.ninfo.karta)));
  
  let defaultValues = {
    id: "nowy",
    gid: null,
    idosoby: "nowa",
    PESEL: "",
    imie: "",
    nazwisko: "",
    dataur: null,
    panstwo: "",
    kategoria: "",
    od: null,
    do: null,
    klimat_zw: true,
    standard: true,
    rodzaj: 0,
    oplata: null,
    karta: null,
    nuwagi: "",
  }

  const formularz = useForm({
        defaultValues: { ...defaultValues, ...osoba, ...nocleg }
  });

  React.useEffect(() => {
    if (props.grupa) {
            setValue("gid", grupa.id);
            setValue("od", grupa.od);
            setValue("do", grupa.do);
            setValue("kategoria", grupa.kategoria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.grupa]);


  const mutateNocleg = useMutation(nocleg => postNocleg(['x'],nocleg), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      queryClient.setQueryData(['dane','noclegi',{id:data.data.id}], {noclegi: [data.data]});
      queryClient.setQueryData(['dane','osoby',{id:data.data.idosoby}], {osoby: [data.data.osoba]});
      if (data.data.dodane===data.data.aktualizacja) {
        let noclegi = [...queryClient.getQueryData(['dane','noclegi']).noclegi, data.data];
        queryClient.setQueryData(['dane','noclegi'], {noclegi: noclegi});
      }
      mutateNocleg.reset();
    },
  });





// ### stepper #######################################################

  const {
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = formularz;

  const handleNowy = () => {
    let gid = watch("gid");
    reset(defaultValues);
    if (gid) {
      setValue("gid", grupa.id);
      setValue("od", grupa.od);
      setValue("do", grupa.do);
      setValue("kategoria", grupa.kategoria);
    }
    setActiveStep(0);
    // console.log(watch());
  };

  const handleSave = () => {
    mutateNocleg.mutate(watch());
    setActiveStep(4);
  };

  const handleNext = () => {
    activeStep===0 && setActiveStep(1);
    activeStep===1 && (Object.keys(errors).length===0) && setActiveStep(2);
    activeStep===2 && (Object.keys(errors).length===0) && setActiveStep(3);
    activeStep===3 && (Object.keys(errors).length===0) && handleSave();
    activeStep===4 && props.handleClose();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  // ### stepper #######################################################
  // console.log(watch(),errors);

  return (
    <React.Fragment>
      
      <DialogTitle id="alert-dialog-title">
          <Fab
            size="small"
            aria-label="zamknij"
            sx={{ position: "absolute", top: "1em", right: "1em" }}
            onClick={props.handleClose}
          >
            <CloseOutlinedIcon />
          </Fab>
          {watch('id')==="nowy"?"Dodaj":"Edytuj"} nocleg {watch('id')!=="nowy"&&" - ID: "+watch('id')}
          <DialogInstrukcje tekst="noclegFormularz" />
      </DialogTitle>

      <KomunikatyWysylka stany={mutateNocleg} />

      <DialogContent>
          
      <Box component="form" sx={{ mt: 2, width: "100%", minHeight: "400px" }}>
          
          <input type="hidden" {...register("gid")} value={watch("gid")} />
          <input type="hidden" {...register("id")} value={watch("id")} />
          <input type="hidden" {...register("idosoby")} value={watch("idosoby")} />

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Grid container spacing={2} sx={{ mt:3 }}>
                  
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Nocleg dodany, możesz dodać kolejną osobę w grupie, albo zakończyć.
              </Typography>
              {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>
                Krok {activeStep + 1}
              </Typography> */}
              {activeStep === 0 && (
                <Nocleg1Pesel formularz={formularz} />
              )}
              {activeStep === 1 && (
                <Nocleg2Osoba formularz={formularz} />
              )}
              {activeStep === 2 && (
                <Nocleg3Nocleg formularz={formularz} />
              )}
              {activeStep === 3 && (<>
                <Nocleg4aPlatnosc formularz={formularz} />
                </>)}
            </React.Fragment>
          )}
          <br />
          <br />
          <br />
        </Grid>
      </Box>
      </DialogContent>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid xs align="left">
            <Button
            // color="inherit"
            variant="outlined"
            disabled={activeStep === 0}
            onClick={activeStep===steps.length ? handleNowy : handleBack}
            sx={{ mr: 1 }}
          >
            {activeStep===steps.length ? "Kolejna" : "<< Wróć"}
          </Button>
            </Grid>

            <Grid xs align="center">
              
            </Grid>

            <Grid xs align="right">
            <Button 
              id="nocleg-next-step"
              onClick={handleNext}
              variant="outlined"
              // disabled={activeStep === steps.length}
            >
            {activeStep===3 && "Zapisz"}
            {activeStep===4 && "Zakończ"}
            {activeStep<3 && "Dalej >>"}
            </Button>
            </Grid>

            </Grid>
            </Box>
      </DialogActions>
    </React.Fragment>
  );
}

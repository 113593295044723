import * as React from 'react';
// import { DataGrid, plPL } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { 
  // fetchJUser, 
  fetchDane,
  // fetchOperacje 
} from '../api';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import GrupaSzczegoly from './GrupaSzczegoly';
// import NoclegiFormularz from '../formularze/NoclegiFormularz';
import { getSumyGrupowe, transGrupa } from '../komponenty/labelki';
import IconState from '../statyczne/IconState';
import OdwiedzinyFormularz from './OdwiedzinyFormularz';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { pages } from '../statyczne/Naglowek';
// import OtrytGridToolbar from '../statyczne/OtrytGridToolbar';
// import { GCCalendar } from '../komponenty/GoogleChartCalendar';
// import Kalendarz from '../komponenty/HeatMap';
import OdwiedzinyKalendarz from './OdwiedzinyKalendarz';
import ButtonNoclegiGrupa from '../noclegi/ButtonNoclegiGrupa';
// import { fetchStart } from '../api/Finanse';
// import KomunikatyWysylka from '../komponenty/KomunikatyWysylka';
import KomunikatyLadowanie from '../komponenty/KomunikatyLadowanie';
import MUIDataTable from "mui-datatables";


///// pojedynczy wiersz listy ////////////////////////////////// 
// function Grupa(props) {
const Grupa = (props) => {

  // console.log(props);
  // let gID = props.g[0];
  // let row = transGrupa(props.row);
  const [openColOdw, setOpenColOdw] = React.useState(false);
  const grupa = useQuery(['dane','odwiedziny',{id:props.g[0]}], fetchDane);
  const nocki = useQuery(['dane','noclegi'], fetchDane);
  const cred = useQuery(['dane','cred'], fetchDane);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // grupa.isSuccess && console.log(grupa.data.odwiedziny[0]);

  let row = grupa.isSuccess && transGrupa(grupa.data.odwiedziny[0]);
  const noclegi = nocki.isSuccess && nocki.data.noclegi;
  let sumyGrupowe = {kwota: 0, klimat: 0, noclegow: 0};

  if (nocki.isSuccess && noclegi) {
      sumyGrupowe = nocki.isSuccess && getSumyGrupowe(noclegi.filter(n=>n&&(n.gid===row.id)));
  }


  let canEdit = false;
  if (
    cred.isSuccess 
    && ( 
      cred.data.grupa==="GOSP"
      || cred.data.grupa==="RADA"
      || cred.data.userID===row.created_by
    )
  ) {
    canEdit = true;
  }

  

  return (
    <React.Fragment>
        <TableRow sx={{ width: "100%", cursor: "pointer", backgroundColor: openColOdw&&"#ffd" }} onClick={() => setOpenColOdw(!openColOdw)}>
        <TableCell align="center" width="50px">
        <small>{row.id}</small><br/>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {openColOdw ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={6}>
                <Typography noWrap variant='body2' component='span'><b>{row.od} - {row.do}</b></Typography>
                <br/>{row.nazwa}
                { canEdit && sumyGrupowe.noclegow>0 && <Typography noWrap variant='caption' ml={2} component='span'><br/><i>( zarejestrowanych: <b>{sumyGrupowe.noclegow}</b> - suma: {sumyGrupowe.kwota} zł )</i></Typography> }
        </TableCell>
        <TableCell align="right">
        { row.state === "-3" 
              ? <IconState state={row.state} />
              : <Typography noWrap variant='caption' component='span'>
                  osób: {row.liczba}<br/>
                  <IconState state={row.state} />
                </Typography>
        }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openColOdw} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              
              <GrupaSzczegoly grupa={row} />
              
              { canEdit && <ButtonNoclegiGrupa id={row.id} pozycja={{ bottom: 0, left: "3em" }} /> }

            </Box>
            <hr style={{ height: "10px", border: 0, backgroundColor: "lightgrey" }} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}











export default function OdwiedzinyLista() {

  // const cred = useQuery(['JUser'], fetchJUser);
  const cred = useQuery(['dane','cred'], fetchDane, {refetchOnWindowFocus: false});
  const location = useLocation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const operacje = useQuery(['dane','odwiedziny'], fetchDane, {
    refetchOnWindowFocus: false, 
    // notifyOnChangeProps: `['data', 'error']`,
  });
  const odwiedzinyLista = operacje.isSuccess && operacje.data.odwiedziny;
  const sciezka = pages.filter(e=>e.url===location.pathname).length;
  // console.log(operacje);


  let placeholder = "znajdź po nazwie lub dacie"
  let owner = [
    {
      name: 'id',
      options: {
        customHeadLabelRender: ()=>"",
        display: false,
        filter: false,
        sort: false,
        searchable: false,
      }
    },
    {
      name: 'id',
      options: {
        customHeadLabelRender: ()=>"",
        display: false,
        filter: false,
        sort: false,
        searchable: false,
      }
    },
    {
      name: 'id',
      options: {
        customHeadLabelRender: ()=>"",
        display: false,
        filter: false,
        sort: false,
        searchable: false,
      }
    },
    {
      name: 'id',
      options: {
        customHeadLabelRender: ()=>"",
        display: false,
        filter: false,
        sort: false,
        searchable: false,
      }
    },
  ];
  if (
    cred.isSuccess 
    && ( 
      cred.data.grupa==="GOSP"
      || cred.data.grupa==="RADA"
    )
  ) {
    placeholder = "przeszukuj po: nazwisku, mailu, loginie, tytule, numerze itp.";
    owner = [
      {
        name: 'owner',
        label: 'telefon',
        options: {
          customHeadLabelRender: ()=>"",
          customBodyRender: (value, tableMeta, updateValue) => { return value.tel },
          display: true,
          filter: false,
          sort: false,
          searchable: true,
        }
      },
      {
        name: 'owner',
        label: 'email',
        options: {
          customHeadLabelRender: ()=>"",
          customBodyRender: (value, tableMeta, updateValue) => { return value.email },
          display: true,
          filter: false,
          sort: false,
          searchable: true,
        }
      },
      { 
        name: 'owner',
        label: 'login',
        options: {
          customHeadLabelRender: ()=>"",
          customBodyRender: (value, tableMeta, updateValue) => { return value.username },
          display: true,
          filter: false,
          sort: false,
          searchable: true,
        }
      },
      { 
        name: 'owner',
        label: 'name',
        options: {
          customHeadLabelRender: ()=>"",
          customBodyRender: (value, tableMeta, updateValue) => { return value.name },
          display: true,
          filter: false,
          sort: false,
          searchable: true,
        }
      },
    ]
  }

  // ###########################################################################################33
  const options = {
    customRowRender: (g)=><Grupa g={g} key={g[0]} />,
    rowsPerPage: sciezka===0?10:30,
    rowsPerPageOptions: [10,30,100,300],
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false, // ukrycie ikony drukowania
    filter: cred.isSuccess && (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA") ? true : false, // ukrycie ikony filtrowania
    // searchOpen: true,
    download: false, // ukrycie ikony eksportu do csv
    sortOrder: {
      name: 'id',
      direction: 'desc'
    },
    setFilterChipProps: () => {return{ sx: { display: 'none' } }}, // ukrycie reguł filtrowania
    searchPlaceholder: placeholder,
    textLabels: { // polonizacja... 
      body: {
        noMatch: "Nie załadowano żadnych zgłoszeń odwiedzin...",
        toolTip: "Sortuj",
        columnHeaderTooltip: column => `Sortowanie ${column.label}`,
      },
      pagination: {
        next: "następna strona",
        previous: "poprzednia strona",
        rowsPerPage: "wierszy na stronę:",
        displayRows: "z",
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz CSV",
        print: "Drukuj",
        viewColumns: "Kolumny widoczne",
        filterTable: "Filtrowanie tabeli",
      },
      filter: {
        all: "wszystkie",
        title: "filtruj wg statusu zgłoszenia ",
        reset: " resetuj",
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleAria: "Pokaż/Ukryj kolumny Tabeli",
      },
      selectedRows: {
        text: "zaznaczono",
        delete: "Usuń",
        deleteAria: "Usuń zaznaczone wiersze",
      },
    },
  };



  // ###########################################################################################
  const columns = [
    { 
      name: 'id', 
      label: 'ID', 
      options: {
        // filterType: 'textField',
        filter: false,
        // sort: true,
      }
    },
    {
      name: 'od',
      // width: 170,
      label: 'data przyjścia',
      options: {
        filter: false,
        // sort: true,
      }
    },
    {
      name: 'nazwa',
      options: {
        customHeadLabelRender: ()=>"",
        // customBodyRender: (value, tableMeta, updateValue) => { return value.username },
        // display: true,
        filter: false,
        sort: false,
        // searchable: true,
      }
    },
    ...owner,
    {
      name: 'state',
      label: "status",
      options: {
        customHeadLabelRender: ()=>"",
        // headerAlign: 'right',
        sort: false,
        filterType: 'multiselect',
        filterOptions: {
          fullWidth: true,
          renderValue: v => (
            (v===0 && "oczekujące") ||
            (v===1 && "zaakceptowane") ||
            (v===2 && "anulowane") ||
            (v===3 && "do kontaktu") ||
            (v===-2 && "usunięte") ||
            (v===-3 && "dodana przez Gospodarza")
        )},
        customFilterListOptions: { 
          render: v=>null,
        //   v => {
        //   return (
        //     v===0 && "oczekujące" ||
        //     v===1 && "zaakceptowane" ||
        //     v===2 && "anulowane" ||
        //     v===3 && "do kontaktu" ||
        //     v===-2 && "usunięte" ||
        //     v===-3 && "dodana przez Gospodarza"
        // )}
        },
        filterList: ( cred.isSuccess && (
          cred.data.grupa==="GOSP" 
          || cred.data.grupa==="RADA" 
        )) ? [-3,0,1,3] : [0,1,3],
      }
    },
  ];
  // ###########################################################################################33
  

  // console.log("odwiedziny");

  let status_start = 0;
  if ( cred.isSuccess && (
      cred.data.grupa==="GOSP" 
      || cred.data.grupa==="RADA" 
    )) { 
      status_start = -3;
    }
  
 
  return (
      <div style={{ width: '100%' }}>
        {/* <KomunikatyLadowanie stany={operacje} /> */}
        { cred.isSuccess && cred.data.grupa!=="PUBL" && <KomunikatyLadowanie stany={operacje} /> }
        { cred.isSuccess && cred.data.grupa!=="PUBL" && <OdwiedzinyKalendarz /> }
     
      <div style={{ display: 'fixed', height: '100%' }}>
        <div style={{ flexGrow: 1 }}>

      <MUIDataTable
        // title={"Employee List"}
        data={operacje.isSuccess?odwiedzinyLista:undefined}
        columns={columns}
        options={options}
      />

      { cred.isSuccess && cred.data.grupa!=="PUBL" && <OdwiedzinyFormularz 
        row={{id: "nowa", state: status_start, od: null, do: null}} // do sprawdzenia czemu warunek dla state nie działał w tym miejscu???
        pozycja={{ 
          position: fullScreen?'fixed':'relative', 
          bottom: fullScreen?'2em':'3.5em', 
          left: fullScreen?"2em":'25%', 
          zIndex: 'modal',
          // left: fullScreen?"2em":'25%', top: "-3.5em", zIndex: 'modal' 
          // bottom: fullScreen?'2em':sciezka===0?'-630px':'6px', 
        }} 
        /> }

      <br/><br/><br/>
    </div>
    </div>
    </div>
    
  );
}
// actions: https://mui.com/components/data-grid/columns/#special-properties

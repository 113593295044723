import * as React from "react";
import { 
  TextField, 
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// import dayjs from 'dayjs';
// import 'dayjs/locale/pl';
import { PeselDecode } from "../../komponenty/labelki";
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from '../../api';

// TODO:
// - przetestować wszystkie uprawnienia


// const NoclegFormularz = React.forwardRef((props, ref) => {
const Nocleg1Pesel = (props) => {

  const dane = useQuery(['dane'], fetchDane);

  const {
    register,
    setValue,
    trigger,
    watch,
    clearErrors,
    formState: { errors },
  } = props.formularz;
  
  
  React.useEffect(() => {
    let osoba = dane.isSuccess && dane.data.osoby.filter(o=>o.id===watch("idosoby"))[0];
    if (osoba) {
      setValue("PESEL",osoba.PESEL);
      setValue("imie",osoba.imie);
      setValue("nazwisko",osoba.nazwisko);
      setValue("dataur",osoba.dataur);
      setValue("panstwo",osoba.panstwo);
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("idosoby")]);

  // console.log(watch());
  // console.log(props);

  let helperPESEL = <>Wpisz PESEL.<br/><br/>Jeśli go nie znasz lub nie pamiętasz zacznij od daty urodzenia w formacie RRMMDD.<br/><br/>Możesz też pominąć ten krok, ale wtedy trzeba bedzie wpisać więcej informacji.</>;
  // let helperPESEL = "poprawny PESEL = krótszy formularz ;-)";
  // if (watch("idosoby") && errors) {
  //       helperPESEL = "wskazana osoba ID: "+watch("idosoby")+" - popraw dane"
  // }
  if (watch("PESEL").length>5 && !PeselDecode(watch("PESEL")).valid) {
        helperPESEL = "data ur. "+PeselDecode(watch("PESEL")).date+", PESEL nieprawidłowy"
  }
  if (PeselDecode(watch("PESEL")).valid) {
        helperPESEL = "PESEL OK - ur. "+PeselDecode(watch("PESEL")).date+", Polska"
  }


  return (
    <React.Fragment>

        <Grid xs={12} sx={{ '& .MuiFormControl-root': { m: 0 },mt:4 }}>
          <TextField
            {...register("PESEL", {
                // validate: (e) => (e.length>5 && PeselDecode(e).valid) || 'PESEL nieprawidłowy'
            })}
            label="PESEL"
            value={watch("PESEL")}
            type="tel"
            onKeyDown={(event)=>{ // nie wiedzieć czemu enter zamykał tu okno, więc enter przenosi do przycisku "dalej":
              if (event.keyCode === 13) {
                document.getElementById("nocleg-next-step").focus();
                event.preventDefault();
              }
            }}
            onWheel={(e) => e.target.blur()}
            onChange={(event) => {
                  setValue("PESEL", event.target.value);
                  trigger();
            }}
            error={errors.PESEL ? true : false}
            helperText={ errors.PESEL ? errors.PESEL.message : helperPESEL }
            variant="outlined"
            size="small"
            // margin="dense"
            sx={{ 
              '& .MuiOutlinedInput-input': { p: "8.5px" },
              '& .MuiFormControl-root': { m: 0 },
            }}
          /> 
          {/* <input type="hidden" {...register("validPESEL")} value={watch("validPESEL")} /> */}
        </Grid>

    </React.Fragment>
  );
}

export default Nocleg1Pesel;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteObiekt, fetchDane } from '../api';
import KomunikatyWysylka from '../komponenty/KomunikatyWysylka';

export default function ButtonDeletePlik(props) {

  const [openDial, setOpenDial] = React.useState(false);
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const operacje = useQuery(['dane'], fetchDane);
  const queryClient = useQueryClient();
  const row = props.obiekt;
  
  const delPlik = useMutation(plik => deleteObiekt('operacje',plik), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      // console.log(data);
//       console.log(data,operacje.data.uzupelnienia);
      let newPolaczenia = operacje.data.pliki_polaczenia.filter((p) => p.id !== Number(data.data));
      let newOper = { 
        ...operacje.data, 
        pliki_polaczenia: newPolaczenia,
      };
      setTimeout(function () {
        queryClient.setQueryData(['dane'], newOper);
        setOpenDial(false);
      }, 1500);
    },
  });

  const handleConfirm = () => {
    delPlik.mutate({tabela: "pliki_polaczenia", wiersz: props.obiekt.id_polaczenia});
  }


  return (
    <React.Fragment>
      <Button variant="link" onClick={()=>setOpenDial(true)}>
        <DeleteForeverIcon color="error" />
      </Button>

      <Dialog
        open={openDial}
        onClose={()=>setOpenDial(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Czy aby na pewno???</DialogTitle>
        <DialogContent>
          <KomunikatyWysylka stany={delPlik} />
          <DialogContentText id="alert-dialog-description">
            Chcesz usunąć połączenie pliku z operacją.<br/>
            <small><i>ID: {row.id} - {row.data}</i></small><br/>
            {row.kategoria} - {row.nazwa} { row.opis && <small><i> - {row.opis}</i></small> }<br/>
            <small>{row.plik} - <i>{row.wielkosc}</i></small><br/><br/>
            UWAGA: Nie usuwasz pliku - będzie on jedynie niewidoczny na liście plików przyłączonych do tej operacji (pozycji).
            <br/><br/>
            Czy na pewno chcesz to zrobić?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            sx={{ position: "absolute", left: "1em" }}
          >
            Tak!!!
          </Button>
          <Button onClick={()=>setOpenDial(false)} autoFocus>
            Przemyślę...
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

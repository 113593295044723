import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FinanseTab1 from './FinanseTab1';
// import FinanseTab2 from './FinanseTab2';
import FinanseTab3 from './FinanseTab3';
import UzupelnieniaLista from './UzupelnieniaLista';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FinanseTabsSzczegoly(props) {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log(props)

  return (
    <Box sx={{ width: '100%', backgroundColor: "#fff" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
                value={value}
                variant="fullWidth" 
                onChange={handleChange} 
                aria-label="basic tabs example"
                
                // TabProps={{style: {background:'yellow'}}}
        >
          <Tab label="Informacje podstawowe" {...a11yProps(0)} sx={{ textTransform: 'inherit', borderRight: '1px solid #ccc' }} />
          <Tab label="Informacje dodatkowe" {...a11yProps(1)} sx={{ textTransform: 'inherit', borderRight: '1px solid #ccc' }} />
          <Tab label="Załączniki" {...a11yProps(2)} sx={{ textTransform: 'inherit', borderRight: '1px solid #ccc' }} />
          { !smallScreen && <Tab label="Historia zmian" {...a11yProps(3)} sx={{ textTransform: 'inherit' }} /> }
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FinanseTab1 dane={props.dane} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="h6">Informacje uzupełniające:</Typography>
        <hr/>
        <UzupelnieniaLista dane={props.dane} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FinanseTab3 dane={props.dane} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        trelemorele
      </TabPanel>
    </Box>
  );
}

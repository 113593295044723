import * as React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FinanseTabsSzczegoly from './FinanseTabsSzczegoly';
import { Badge, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'

import { useQueryClient } from '@tanstack/react-query';
// import { fetchDane } from '../api';
// import { operacjeMiesiac } from './sumy';

function Row(props) {
  const { row, typ } = props;
  const queryClient = useQueryClient();
  const invisible = queryClient.getQueryData(['badzeSumy']);
  const dane = queryClient.getQueryData(['dane']);

  let uzup = [];
  let pliki = [];
  let tytul = "";
    
  if (row.info && typeof row.info === 'string') { row.info = JSON.parse(row.info); }
  // {row.tabela==="Banki" && row.info.opis}
  // {row.tabela==="Przychody" && row.gadzet}
  // {row.tabela==="Wydatki" && row.tytul}
  // {row.tabela==="Noclegi" && "Noclegi imienne"}
  // {row.tabela==="iposS" && "Sprzedaż zarejestrowana w kasie fiskalnej"}
  // {row.tabela==="iposN" && "Noclegi zarejestrowane w kasie fiskalnej"}
  
  if (typ==="pch") {
    tytul = row.gadzet ? ( row.gadzet==="RapKas" ? "Skarbonka - z Raportu Kasowego" : row.gadzet ) : row.tytul;
    uzup = dane.uzupelnienia.filter(u=>u.tabela===props.tabela&&u.wiersz===row.id);
    pliki = dane.pliki_polaczenia.filter(u=>u.tabela===props.tabela&&u.wiersz===row.id).map(e=>{
      return dane.pliki.filter(p=>p.id===e.idpliku)
    });
  }
  if (typ==="pb") {
    tytul=row.info.opis;
    uzup = dane.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id);
    pliki = dane.pliki_polaczenia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id).map(e=>{
      return dane.pliki.filter(p=>p.id===e.idpliku)
    });
  }
  if (typ==="pk") {
    tytul=row.info.opis;
    uzup = dane.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id);
    pliki = dane.pliki_polaczenia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id).map(e=>{
      return dane.pliki.filter(p=>p.id===e.idpliku)
    });
  }
  if (typ==="wch") {
    tytul=row.tytul;
    uzup = dane.uzupelnienia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===row.id);
    pliki = dane.pliki_polaczenia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===row.id).map(e=>{
      return dane.pliki.filter(p=>p.id===e.idpliku)
    });
  }
  if (typ==="wb") {
    tytul=row.info.opis;
    uzup = dane.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id);
    pliki = dane.pliki_polaczenia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id).map(e=>{
      return dane.pliki.filter(p=>p.id===e.idpliku)
    });
  }
  if (typ==="wk") {
    tytul=row.info.opis;
    uzup = dane.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id);
    pliki = dane.pliki_polaczenia.filter(u=>u.tabela==="Banki"&&u.wiersz===row.id).map(e=>{
      return dane.pliki.filter(p=>p.id===e.idpliku)
    });
  }


  
  let przeplywy = uzup?Object.keys(uzup.filter(u=>u.wybor==="przepływy")).length:0;

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  // console.log(props);
  
  return (
    <React.Fragment>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer', backgroundColor: (row.zobowiazanie===1||row.zobowiazanie===2||przeplywy>0)&&"#eef" }} onClick={() => setOpen(!open)} >
        <TableCell component="th" scope="row">
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {row.id}
        </TableCell>
        { !smallScreen && <TableCell><Typography variant="body2" noWrap>{row.data}</Typography></TableCell> }

        <TableCell sx={{ '& .MuiBadge-root': { display: "block", top: "10px", right: "10px" } }}>

        <Tooltip title="Liczba operacji uzupełnionych informacjami" placement="top">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={uzup.length}
          variant="outlined"
          color="jasnyzielony"
          invisible={invisible}
        />
        </Tooltip>

        <Tooltip title="Liczba operacji z dodanym plikiem" placement="top-start">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={pliki.length}
          variant="outlined"
          color="info"
          invisible={invisible}
          sx={{ mr: uzup.length?3:0 }}
        />
        </Tooltip>

          {smallScreen?<b>{row.data}<br/></b>:null}
          
          {tytul}
          
          { !props.rk && przeplywy>0&&<i><br/>UWAGA: operacja niesumowana - kategoria: "przepływy"</i>}
          
          {(
               row.zobowiazanie===2
            || row.zobowiazanie===3
            ) && <i><br/>UWAGA: operacja niesumowana - płatność przelewem</i>}
          
          {(
               row.zobowiazanie===1
            || row.zobowiazanie===4
            ) && <i><br/>UWAGA: operacja niesumowana - dług - do spłacenia przez Gospodarza</i>}

          {(
               row.zobowiazanie===5
            ) && <i><br/>UWAGA: operacja niesumowana - płatność bezpośrednio z wykonawcą</i>}
          
          
          { smallScreen && <Typography variant="body2" align="right" noWrap>
            <b>{row.kwota && row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</b>
          </Typography> }
        </TableCell>
        { !smallScreen && <TableCell align="right">
          <Typography variant="body2" noWrap>
            {row.kwota && row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł
          </Typography>
        </TableCell> }
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#dfd" }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
                {/* struktura Tabs dla wyświetlenia szczegółów wybranej operacji: */}
                <FinanseTabsSzczegoly dane={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

// pch
// pb
// pk
// wch
// wb
// wk

// lista operacji w wybranej komórce miesiąca:
export default function FinanseOperacje(props) {

  const {miesiac,typ} = props;
  const queryClient = useQueryClient();
  const operacje = queryClient.getQueryData(['dane']);
  const sumy = queryClient.getQueryData(['sumyMiesieczne']);
  // const operacje = useQuery(['dane'], fetchDane);
  let suma=0;
  let tytul="SUMA operacji:"
  let listaOperacji = []; // dafiniowana poniżej w zależności od typu operacji:

  if (typ==="pch") {
    suma = sumy.filter(o=>o.miesiac===miesiac)[0].przychody;
    let przychody = operacje.przychody.filter(o=>dayjs(o.data).format("YYYY-MM")===miesiac).map(o=>({...o, tabela:"Przychody"}));
    listaOperacji = [
      {id:"1",tabela:"iposN",data:dayjs(miesiac).endOf('month').format("YYYY-MM-DD"),tytul:"Noclegi zarejestrowane w kasie fiskalnej",kwota:suma.iposN.kwota},
      {id:"2",tabela:"Noclegi",data:dayjs(miesiac).endOf('month').format("YYYY-MM-DD"),tytul:"Noclegi imienne zarejestrowane w aplikacji (lub w Rap.Kas.)",kwota:suma.noclegi.kwota},
      {id:"3",tabela:"iposS",data:dayjs(miesiac).endOf('month').format("YYYY-MM-DD"),tytul:"Sprzedaż zarejestrowana w kasie fiskalnej",kwota:suma.iposS.kwota},
    ]
    // dla przychodów a od 2023 RapKasowych - dodajemy liste pozycji poniżej:
    listaOperacji = przychody.length>0 ? [...listaOperacji, ...przychody] : listaOperacji;
    suma = suma.suma;
  }
  if (typ==="pb") {
    listaOperacji = operacje.banki.filter(o=>dayjs(o.data).format("YYYY-MM")===miesiac && (o.bank==="mBankGl"||o.bank==="VWBank") && o.typ===1).map(o=>({...o, tabela:"Banki"}));
    suma = sumy.filter(o=>o.miesiac===miesiac)[0].bankP.suma;
  }
  if (typ==="pk") {
    listaOperacji = operacje.banki.filter(o=>dayjs(o.data).format("YYYY-MM")===miesiac && (o.bank==="mBank"||o.bank==="kasa") && o.typ===1).map(o=>({...o, tabela:"Banki"}));
    suma = sumy.filter(o=>o.miesiac===miesiac)[0].kasaP.suma;
  }
  if (typ==="wch") {
    tytul="SUMA wydatków z kasy Chaty:";
    listaOperacji = operacje.wydatki.filter(o=>dayjs(o.data).format("YYYY-MM")===miesiac).map(o=>({...o, tabela:"Wydatki"}));
    let sumaW = sumy.filter(o=>o.miesiac===miesiac)[0].wydatki;
    suma = props.rk ? sumaW.chata : sumaW.suma; 
    // w Raporcie Kasowym bierzemy sume wypływów z Chaty, razem  z przepływami
    // w zestawieniu SIWKO nie liczymy przepływów.
  }
  if (typ==="wb") {
    listaOperacji = operacje.banki.filter(o=>dayjs(o.data).format("YYYY-MM")===miesiac && (o.bank==="mBankGl"||o.bank==="VWBank") && o.typ===2).map(o=>({...o, tabela:"Banki"}));
    suma = sumy.filter(o=>o.miesiac===miesiac)[0].bankW.suma;
  }
  if (typ==="wk") {
    listaOperacji = operacje.banki.filter(o=>dayjs(o.data).format("YYYY-MM")===miesiac && (o.bank==="mBank"||o.bank==="kasa") && o.typ===2).map(o=>({...o, tabela:"Banki"}));
    suma = sumy.filter(o=>o.miesiac===miesiac)[0].kasaW.suma;
  }
  // uwaga: do każdej listy dodane jest info o tabeli, żeby móc w FinanseTab1 transponować opisy.




  
  // const listaOperacji = operacje.isSuccess && operacjeMiesiac(operacje.data,props);
  // if (typ==="wch") {
  //   operacje.isSuccess && listaOperacji.filter(o=>o.zobowiazanie===0).map(o=>{
  //     let p = o.uzup && o.uzup.filter(u=>u.wybor==="przepływy");
  //     if (props.rk || p.length===0) suma += o.kwota; // przepływy z Chaty sumujemy w raporcie kasowym
  //     tytul="SUMA wydatków z kasy Chaty:"
  //     return true;
  //   });
  // } else {
  //   operacje.isSuccess && listaOperacji.map(o=>{
  //     suma += o.kwota;
  //     return true;
  //   });
  // }
  // console.log(listaOperacji);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            { !smallScreen && <TableCell>data</TableCell> }
            <TableCell>nazwa</TableCell>
            { !smallScreen && <TableCell align="right">kwota</TableCell> }
          </TableRow>
        </TableHead>
        <TableBody>
          {listaOperacji.map((k) => (
            <Row key={"operacja-"+k.id} typ={typ} row={k} rk={props.rk} />
          ))}
          {/* {Object.keys(props.operacje).sort().reverse().map((k) => (
            <Row key={"operacja-"+k} row={props.operacje[k]} />
          ))} */}
        </TableBody>
        <TableHead>
          { smallScreen ? <TableRow>
            <TableCell colSpan={2} sx={{ textAlign: "right" }} >
              {tytul}
              <b><nobr> {suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</nobr></b>
            </TableCell>
          </TableRow> 
          :
          <TableRow>
            <TableCell colSpan={3} sx={{ textAlign: "right" }} >{tytul}</TableCell>
            <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
              <nobr>{suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</nobr>
            </TableCell>
          </TableRow> }
        </TableHead>
      </Table>
    </TableContainer>
    </div>
  );
}

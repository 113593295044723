import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from '../api';
// import ButtonDelete from './ButtonDelete';
import UzupelnieniaFormularz from '../formularze/UzupelnieniaFormularz';
// import { fetchOperacje } from '../api/Finanse';
import ButtonDeleteUzupelnienie from './ButtonDeleteUzupelnienie';


export default function UzupelnieniaLista(props) {

        const [stan, setStan] = React.useState(0);
        // const cred = useQuery(['JUser'], fetchJUser);
        const cred = useQuery(['dane','cred'], fetchDane);
        const operacje = useQuery(['dane'], fetchDane);
        // const operacje = useQuery(['operacje'], fetchOperacje);
        const inwentarz = operacje.isSuccess && operacje.data.inwentarz; 
        const osoby = operacje.isSuccess && operacje.data.osoby; 
        const uzup = operacje.isSuccess && operacje.data.uzupelnienia.filter(u=>u.tabela===props.dane.tabela&&u.wiersz===props.dane.id); 

        // console.log(props);

        let rows = [];
        operacje.isSuccess && uzup.map((uz)=>{
                if ( uz.kategoria==="1_osoba_wyk" || uz.kategoria==="2_osoba_dok" ) {
                      let osoba = operacje.isSuccess && osoby.filter(o=>o.id===Number(uz.wybor))[0];
                      let osoba_label = osoba && osoba.imie + " " + osoba.nazwisko + ", PESEL: " + osoba.PESEL;
                      rows = [ ...rows,
                              { id: uz.id, name: (uz.kategoria==="1_osoba_wyk" ? "załatwiał/a:" : "dokumenty są u:"), value: osoba_label, kwota: uz.kwota },
                      ];
                }
                if ( uz.kategoria==="3_projekt" ) {
                      rows = [ ...rows,
                              { id: uz.id, name: "projekt:", value: uz.wybor, kwota: uz.kwota },
                      ];
                }
                if ( uz.kategoria==="4_kategoria" ) {
                      rows = [ ...rows,
                              { id: uz.id, name: "kategoria:", value: uz.wybor, kwota: uz.kwota },
                      ];
                }
                if ( uz.kategoria==="5_przedmiot" ) {
                      // wybór to id przedmiotu - dorobić nazwę + link
                      let przedmiot = operacje.isSuccess && inwentarz.filter(p=>p.id===Number(uz.wybor))[0];
                      // console.log(uz.wybor,przedmiot,inwentarz);
                      rows = [ ...rows,
                              { id: uz.id, name: "przedmiot:", value: przedmiot.id+". "+przedmiot.nazwa, kwota: uz.kwota },
                      ];
                }
                if ( uz.kategoria==="6_operacja" ) {
                      // wybór to: tabela-id - dorobić linkowanie - jak?
                      rows = [ ...rows,
                              { id: uz.id, name: "inna operacja:", value: uz.wybor, kwota: uz.kwota },
                      ];
                }
                if ( uz.kategoria==="9_uwagi" ) {
                      rows = [ ...rows,
                              { id: uz.id, name: "dodatkowe uwagi:", value: JSON.parse(uz.info)['uwagi'] },
                      ];
                }
                return true;
        });

        // console.log(stan);

        return (
        <React.Fragment>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name+"-"+row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" component="th" scope="row">
                <b>{row.name}</b>
              </TableCell>
              <TableCell>{row.value}</TableCell>
              <TableCell>{row.kwota && row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
              <TableCell align="right">
                { cred.isSuccess && cred.data.grupa==="RADA" && <ButtonDeleteUzupelnienie obiekt={{tabela: "Uzupelnienia", wiersz: row.id}} /> }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    { cred.isSuccess && cred.data.grupa==="RADA" && <UzupelnieniaFormularz row={props.dane} stan={stan} setStan={setStan} /> }
    </React.Fragment>
  );
}

// import { Tooltip } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Switch, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { HeatMapGrid } from "react-grid-heatmap";
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from "../api";
import OdwiedzinyDzien from "./OdwiedzinyDzien";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

dayjs.locale('pl');

// const xLabels = new Array(8).fill(0).map((_, i) => `${i}`);
// const xLabels = ["Sty", "Lut", "Mar", "Kwie", "Maj", "Fri", "Fri", "Fri"];

const OdwiedzinyKalendarz = () => {
        const [expanded, setExpanded] = React.useState(false);
        const [pokaDzien, setPokaDzien] = React.useState(true);
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
        const yLabels = ["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"];
        const operacje = useQuery(['dane','odwiedziny'], fetchDane);
        const odwiedzinyLista = operacje.isSuccess && operacje.data.odwiedziny;
      
        let zbior = []
        let xLabels = []
        let poczatek = dayjs().startOf('week');
        let m = 0;

        for (let dzien = 0; dzien < 7; dzien++) {
                zbior[dzien] = [];
                for (let i = 0; i < (fullScreen?5:12); i++) {
                        let data = poczatek.add(i*7, 'day').format('YYYY-MM-DD');
                        if(dzien===0) {
                                if (i===0) {
                                        xLabels[i] = dayjs(data).format("MMM");
                                } else if (xLabels[m] === dayjs(data).format("MMM")) { 
                                        xLabels[i] = ""; 
                                } else {
                                        xLabels[i] = dayjs(data).format("MMM");
                                        m = i; 
                                }
                                // console.log("m: ",m,", i: ",i)
                        }
                        let dane = operacje.isSuccess && odwiedzinyLista.length>0 && odwiedzinyLista.filter((v)=>v.od<=data&&v.do>data&&Number(v.state)!==-2&&Number(v.state)!==2);
                        if (dane.length===0) {zbior[dzien][i] = {data: data, osob: 0, grup: 0}}
                        if (dane.length===1) {zbior[dzien][i] = {data: data, osob: Number(dane[0].liczba), grup: 1}}
                        if (dane.length>1) {
                                let suma = 0
                                dane.forEach(e => {
                                        suma += Number(e.liczba);
                                });
                                zbior[dzien][i] = {data: data, osob: suma, grup: dane.length};
                        }
                }
                poczatek = poczatek.add(1, 'day');
        }

  return (
    (<div
      style={{
        width: "100%",
        fontFamily: "sans-serif"
      }}
    >
      <Accordion expanded={expanded} onChange={()=>setExpanded(expanded ? false : true)}>
      <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{expanded ? "ukryj" : "zobacz"} Kalendarz</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{p:1}}>
      <div align="center"><small align="center">Liczba osób zostających na noc. Szczegóły po kliknięciu.</small></div>
      <div width="100%" align="right">
      <i><FormControlLabel control={<Switch
          checked={!pokaDzien}
          onChange={()=>setPokaDzien(pokaDzien?false:true)}
          inputProps={{ 'aria-label': 'controlled' }}
        />} label={`${pokaDzien?"pokaż":"ukryj"} dni miesiąca`} />
      </i></div>
      

    <HeatMapGrid
      data={zbior}
      xLabels={xLabels}
      yLabels={yLabels}
      // Reder cell with tooltip
      cellRender={(x, y, value) => (
              (<>
                {/* <Typography sx={{fontSize: ".8em", textAlign: "left"}}>{dayjs(value.data).format("DD.MM")}</Typography> */}
                <OdwiedzinyDzien key={"key-"+x+"-"+y} data={value.data} pokaDzien={pokaDzien} />
              </>)
      //   <Tooltip key={"key-"+x+"-"+y} placement="top" title={`${value.data} - osób: ${value.osob} (grup: ${value.grup})`}>
      //     {/* <div>{value.osob}</div> */}
      //   </Tooltip>
      //   <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>
      )}
      xLabelsStyle={(index) => ({
        // color: index % 2 ? "transparent" : "#f7f",
        color: "#363",
        fontSize: ".65rem",
        textTransform: "uppercase",
      })}
      yLabelsStyle={() => ({
        fontSize: ".65rem",
        // textTransform: "uppercase",
        color: "#777"
      })}
      cellStyle={(_x, _y, ratio) => ({
        background: zbior[_x][_y].data===dayjs().format("YYYY-MM-DD") ? `rgb(190, 0, 0, ${zbior[_x][_y].osob/50 + 0.1})` : `rgb(0, 140, 0, ${zbior[_x][_y].osob/50 + 0.1})`,
      //   fontSize: ".2rem",
      //   borderColor: zbior[_x][_y].data===dayjs().format("YYYY-MM-DD") ? 'red' : 'white',
      //   borderWith: "2px",
      //   color: `rgb(0, 0, 0, ${zbior[_x][_y].osob/50 / 2 + 0.4})`
      })}
      cellHeight="2.1rem"
      xLabelsPos="top"
      // onClick={(x, y) => alert(`Clicked (${x}, ${y})`)}
      // yLabelsPos="right"
      // square
    />
      </AccordionDetails>
    </Accordion>
    </div>)
  );
};

export default OdwiedzinyKalendarz;

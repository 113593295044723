import * as React from 'react';
// import { DataGrid, plPL } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { transWydatek } from '../komponenty/labelki';
import { 
  Box, 
  Typography 
} from '@mui/material';
// import OtrytGridToolbar from '../statyczne/OtrytGridToolbar';
import { fetchDane } from '../api';
import ButtonWydatekDodaj from './ButtonWydatekDodaj';
import ButtonUzupelnienia from './ButtonUzupelnienia';
import ButtonPliki from './ButtonPliki';
import KomunikatyLadowanie from '../komponenty/KomunikatyLadowanie';
import MUIDataTable from "mui-datatables";


///// pojedynczy wiersz listy ////////////////////////////////// 
function Wydatek(props) {

  // console.log(props);
  // let row = transWydatek(props.row);
  const wiersz = useQuery(['dane','wydatki',{id: props.g[1]}], fetchDane, {enabled: false});
  let row = wiersz.isSuccess && transWydatek(wiersz.data.wydatki[0]);

  const [open, setOpen] = React.useState(false);
  // const cred = useQuery(['JUser'], fetchJUser);
  const cred = useQuery(['dane','cred'], fetchDane);
//   console.log()

  return (
    <React.Fragment>
      {/* <TableContainer component={Paper} sx={{ minWidth: "100%" }}>
      <Table aria-label="collapsible table" size="small">
      <TableBody> */}
      <TableRow 
        sx={{ '& > *': { borderBottom: 'unset', cursor: "pointer" }, borderTop: open?"5px solid #090":"unset" }} 
        onClick={() => setOpen(!open)}
      >
        <TableCell width={70} align="center" >
        <small>{row.id}</small><br/>
          <IconButton
            aria-label="expand row"
            size="small"
            
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={2}>

                <Typography noWrap variant='body2' component='span'><b>{row.data}</b></Typography><br/>

                {row.tytul}
        
        </TableCell>
        <TableCell align="right">{row.kwota} zł</TableCell>
      </TableRow>
      
      <TableRow sx={{ backgroundColor: "#efe" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            
            <Box sx={{ margin: 1 }}>
              <Typography variant="body1">
                {row.uwagi && <>{row.uwagi}<br/></>}
                {row.nrfaktury && <>nr faktury: {row.nrfaktury}</>}
              </Typography>
              
              <ButtonWydatekDodaj button wydatek={row} pozycja={{ left: "0", mt: 1, mr: 3 }} setOpen={setOpen} />
              { cred.data.grupa === "RADA" && <ButtonUzupelnienia button wydatek={row} pozycja={{ mt: 1, mr: 3 }} /> }
              { (cred.data.grupa === "RADA" || cred.data.grupa === "GOSP") && <ButtonPliki button wydatek={row} pozycja={{ mt: 1, mr: 3 }} /> }
            
            </Box>
            <Typography variant="body2" fontStyle={'italic'} textAlign={'right'} width={"100%"} >
              wprowadzone: {row.dodane}
            </Typography>
          </Collapse>
        </TableCell>
      </TableRow>
      {/* </TableBody>
      </Table>
      </TableContainer> */}
    </React.Fragment>
  );
}

const WylaczenieFragmentu = () => {
  return <React.Fragment />;
};

export default function WydatkiLista(props) {

  // const cred = useQuery(['dane','cred'], fetchDane);
  const operacje = useQuery(['dane','wydatki'], fetchDane);
  let lista = operacje.isSuccess && operacje.data.wydatki;
  let komponenty = {};
  if (props.miesiac) { 
    lista = lista.filter(o=>dayjs(o.data).format("YYYY-MM")===props.miesiac); 
    komponenty = {
      TableFooter: WylaczenieFragmentu,
      TableHead: WylaczenieFragmentu,
      TableToolbar: WylaczenieFragmentu,
    }
  }

  // ###########################################################################################33
  const options = {
    customRowRender: (w)=><Wydatek g={w} key={w[1]} />,
    rowsPerPage: 30,
    rowsPerPageOptions: [10,30,100,300],
    selectableRowsHideCheckboxes: true,
    viewColumns: false,
    print: false, // ukrycie ikony drukowania
    filter: false, // ukrycie ikony filtrowania
    // searchOpen: true,
    download: false, // ukrycie ikony eksportu do csv
    sortOrder: {
      name: 'id',
      direction: 'desc'
    },
    setFilterChipProps: () => {return{ sx: { display: 'none' } }}, // ukrycie reguł filtrowania
    searchPlaceholder: "przeszukuj po: nazwie, dacie, kwocie, numerze...",
    textLabels: { // polonizacja... 
      body: {
        noMatch: "Nie udało się załadować żadnych wydatków...",
        toolTip: "Sortuj",
        columnHeaderTooltip: column => `Sortowanie ${column.label}`,
      },
      pagination: {
        next: "następna strona",
        previous: "poprzednia strona",
        rowsPerPage: "wierszy na stronę:",
        displayRows: "z",
      },
      toolbar: {
        search: "Szukaj",
        downloadCsv: "Pobierz CSV",
        print: "Drukuj",
        viewColumns: "Kolumny widoczne",
        filterTable: "Filtrowanie tabeli",
      },
      filter: {
        all: "wszystkie",
        title: "filtrowanie wg kryteriów ",
        reset: " resetuj",
      },
      viewColumns: {
        title: "Pokaż kolumny",
        titleAria: "Pokaż/Ukryj kolumny Tabeli",
      },
      selectedRows: {
        text: "zaznaczono",
        delete: "Usuń",
        deleteAria: "Usuń zaznaczone wiersze",
      },
    },
  };
  
  
  
  // ###########################################################################################33
  const columns = [
    {
      name: 'kwota',
      label: 'Kwota',
      options: {
        customHeadLabelRender: ()=>"",
        filter: false,
      }
    },
    { 
      name: 'id', 
      label: 'ID', 
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'data',
      label: 'Data operacji',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: 'tytul',
      label: 'Opis',
      options: {
        customHeadLabelRender: ()=>"",
        filter: false,
        sort: true,
      }
    },
    // {
    //   name: 'bank',
    //   label: 'Miejsce',
    //   options: {
    //     // customHeadLabelRender: ()=>"",
    //     // headerAlign: 'right',
    //     filter: cred.data.grupa === "RADA" ? true : false,
    //     sort: false,
    //     filterType: 'multiselect',
    //     filterOptions: {
    //       fullWidth: true,
    //       renderValue: v => (
    //         (v==="Chata" && "wydatki w Chacie") ||
    //         (v==="mBankGl" && "operacje bankowe (mBank)") ||
    //         (v==="VWBank" && "operacje bankowe (VWBank)") ||
    //         (v==="mBank" && "operacje kasowe")
    //       )},
    //     customFilterListOptions: { 
    //       render: v => {
    //         return (
    //           (v==="Chata" && "wydatki w Chacie") ||
    //           (v==="mBankGl" && "operacje bankowe (mBank)") ||
    //           (v==="VWBank" && "operacje bankowe (VWBank)") ||
    //           (v==="mBank" && "operacje kasowe")
    //         )}
    //     },
    //     filterList: ["Chata"],
    //   }
    // },
  ];
  

  return (
    <div style={{ width: '100%' }}>
    <KomunikatyLadowanie stany={operacje} />
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>

    <MUIDataTable
        data={lista ? lista : undefined}
        columns={columns}
        options={options}
        components={komponenty}
    />

    {/* <DataGrid
        // rows={kasaLista.isSuccess?kasaLista.data.data:[]}
        rows={operacje.isSuccess?wydatki:[]}
        getRowId={(row) => row.id}
        columns={columns}
        loading={operacje.isLoading}
        disableColumnMenu
        disableVirtualization
        columnVisibilityModel={{
          // Hide columns status and traderName, the other columns will remain visible
          // state: false,
          // traderName: false,
          kwota: false,
          nazwa: false,
          // bank: false,
        }}
        // onColumnHeaderClick={(params, event) => {
        //   console.log(params, event);
        //   showFilterPanel();
        // }}
        

        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 30, 100, 500]}
        pagination
        sortingOrder={['desc','asc']}

        components={{
                Row: Wydatek,
                Toolbar: OtrytGridToolbar,
        }}
        componentsProps={{
          baseTextField: {
            variant: "outlined",
            margin: "dense",
            size: "small",
          },
          filterPanel: {
	            filterFormProps: {
                columnInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 3, width: '100%',
                  '& .MuiInputBase-root': { width: '100%' } },
                },
                operatorInputProps: {
                  variant: 'outlined',
                  size: 'small',
                  sx: { mt: 3, width: '100%',
                  '& .MuiInputBase-root': { width: '100%' } },
                },
                valueInputProps: {
                  sx: { mt: 3, mb: 3, width: '100%',
                    '& .MuiInputBase-root': { width: '100%' } },
                },
                deleteIconProps: {
                  sx: {
                    '& .MuiSvgIcon-root': { color: '#d32f2f' },
                    textAlign: "right",
                  },
                },
              },
        },
      }}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          // filter: {
          //   filterModel: {
          //     items: [
          //       { field: 'bank', operator: 'is', value: 'Chata' }
          //     ],
          //   },
          // },
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
      }}
      /> */}
    
    { !props.miesiac && <ButtonWydatekDodaj dodawanie /> }
    <br/><br/><br/>
    </div>
    </div>
    </div>
    
  );
}
// actions: https://mui.com/components/data-grid/columns/#special-properties

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { 
  // TableHead, 
  Typography } from '@mui/material';
// import { kategoriaLabel } from '../komponenty/labelki';
import TabelaNoclegi from './TabelaNoclegi';
import TabelaIPOS from './TabelaIPOS';
// import dayjs from 'dayjs';


// function TabelaNoclegi(props) {

//   // console.log(props);

//     // let rows = [
//     //   { name: kategoriaLabel(1),  value: props.dane['kat']['1'].wizyt, val2: props.dane['kat']['1'].nocy, val3: props.dane['kat']['1'].suma },
//     //   { name: kategoriaLabel(2),  value: props.dane['kat']['2'].wizyt, val2: props.dane['kat']['2'].nocy, val3: props.dane['kat']['2'].suma },
//     //   { name: kategoriaLabel(3),  value: props.dane['kat']['3'].wizyt, val2: props.dane['kat']['3'].nocy, val3: props.dane['kat']['3'].suma },
//     //   { name: kategoriaLabel(9),  value: props.dane['kat']['9'].wizyt, val2: props.dane['kat']['9'].nocy, val3: props.dane['kat']['9'].suma },
//     //   { name: kategoriaLabel(4),  value: props.dane['kat']['4'].wizyt, val2: props.dane['kat']['4'].nocy, val3: props.dane['kat']['4'].suma },
//     //   { name: kategoriaLabel(5),  value: props.dane['kat']['5'].wizyt, val2: props.dane['kat']['5'].nocy, val3: props.dane['kat']['5'].suma },
//     //   { name: kategoriaLabel(6),  value: props.dane['kat']['6'].wizyt, val2: props.dane['kat']['6'].nocy, val3: props.dane['kat']['6'].suma },
//     //   { name: kategoriaLabel(7),  value: props.dane['kat']['7'].wizyt, val2: props.dane['kat']['7'].nocy, val3: props.dane['kat']['7'].suma },
//     // ];

//     // console.log(props.dane);

//   // kat1: 15, // Chata
//   // kat2: 10, // Student
//   // kat3: 10, // Namiot
//   // kat9: 10, // Lektorium
//   // kat4: 0,  // Dziecko
//   // kat5: 0,  // KO
//   // kat6: 0,  // GG
//   // kat7: 0,  // Inne
//   // kat8: 0,  // Bank

//   return (
//     <React.Fragment>
//     <Typography variant="h6">Szczegóły operacji:</Typography>
//     <hr/>
//     <TableContainer component={Paper}>
//       <Table size="small" aria-label="a dense table">
//         <TableHead>
//           <TableRow sx={{ borderBottom: 2 }}>
//             <TableCell align="right"><b>kategoria</b></TableCell>
//             <TableCell align="right"><b>odwiedzin</b></TableCell>
//             <TableCell align="right"><b>noclegów</b></TableCell>
//             <TableCell align="right"><b>wpływy</b></TableCell>
//           </TableRow>
//         </TableHead> 
        
//         <TableBody>
//           {props.dane.kat.map((row) => (
//             <TableRow
//               key={row.id}
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell align="right" component="th" scope="row">
//                 <b>{kategoriaLabel(row.id)}</b>
//               </TableCell>
//               <TableCell align={row.kwota!=null ? "right" : "left"}>{row.wizyt}</TableCell>
//               <TableCell align="right">{row.nocy}</TableCell>
//               <TableCell align="right">{row.kwota && row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
//             </TableRow>
//           ))}
//             <TableRow
//                              sx={{ borderTop: 2 }}
//                             //  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell align="right" component="th" scope="row">
//                 <b>Sumy</b>
//               </TableCell>
//               <TableCell align="right">{props.dane.wizyt}</TableCell>
//               <TableCell align="right">{props.dane.nocy}</TableCell>
//               <TableCell align="right">{props.dane.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
//             </TableRow>
//             <TableRow
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell align="right" component="th" scope="row">
//                 <i>liczba osób:</i>
//               </TableCell>
//               <TableCell align="left" ><i>{Object.keys(props.dane.osoby).length}</i></TableCell>
//               <TableCell></TableCell>
//               <TableCell></TableCell>
//             </TableRow>
//         </TableBody>
//       </Table>
//     </TableContainer>
//     </React.Fragment>
//   );
// }


// // ##############################################################################################

// function TabelaIPOS(props) {
//   // console.log(props);

//   let rows = Object.keys(props.dane.noclegi).map(o=>{
//     return { name: o, ...props.dane.noclegi[o] }
//   })
//   Object.keys(props.dane.sprzedaz).map((o)=>{
//     rows[rows.length++] = { name: o, ...props.dane.sprzedaz[o] };
//     return true;
//   });

//   return (
//     <React.Fragment>
//     <Typography variant="h6">Szczegóły operacji:</Typography>
//     <hr/>
//     <TableContainer component={Paper}>
//       <Table size="small" aria-label="a dense table">
//         <TableHead>
//           <TableRow>
//             <TableCell sx={{ borderRight: 1 }} align="center" rowSpan={2}><b>kategoria</b></TableCell>
//             <TableCell sx={{ borderRight: 1 }} align="center" colSpan={2}><b>gotówka</b></TableCell>
//             <TableCell sx={{ borderRight: 1 }} align="center" colSpan={2}><b>karta / przelew</b></TableCell>
//             <TableCell align="center" rowSpan={2}><b>operacji</b></TableCell>
//           </TableRow>
//           <TableRow sx={{ borderBottom: 2 }}>
//             <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right"><b>noclegi</b></TableCell>
//             <TableCell sx={{ borderRight: 1 }} align="right"><b>opłaty</b></TableCell>
//             <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right"><b>noclegi</b></TableCell>
//             <TableCell sx={{ borderRight: 1 }} align="right"><b>opłaty</b></TableCell>
//           </TableRow>
//         </TableHead> 
        
//         <TableBody>
//           {rows.map((row) => (
//             <TableRow
//               key={row.name}
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell sx={{ borderRight: 1 }} align="right" component="th" scope="row">
//                 <b>{row.name}</b>
//               </TableCell>
//               <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{row.liczba}</TableCell>
//               <TableCell sx={{ borderRight: 1 }} align="right">{row.przychody.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
//               <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{row.karta_licz}</TableCell>
//               <TableCell sx={{ borderRight: 1 }} align="right">{row.karta.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
//               <TableCell align="right">{row.operacji}</TableCell>
//             </TableRow>
//           ))}
//             <TableRow
//               sx={{ borderTop: 2 }}
//               // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell sx={{ borderRight: 1 }} align="right" component="th" scope="row">
//                 <b>Sumy</b>
//               </TableCell>
//               <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{props.dane.liczba}</TableCell>
//               <TableCell sx={{ borderRight: 1 }} align="right">{props.dane.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
//               <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{props.dane.karta_licz}</TableCell>
//               <TableCell sx={{ borderRight: 1 }} align="right">{props.dane.karta.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
//               <TableCell align="right">{props.dane.operacji}</TableCell>
//             </TableRow>
//         </TableBody>
//       </Table>
//     </TableContainer>
//     </React.Fragment>
//   );
// }

// #####################################################################################

export default function FinanseTab1(props) {
  
  // console.log(props);

  let rows = [];
  if ( props.dane.tabela==="Banki" ) {
        rows = [
                { name: "Data:", value: props.dane.data },
                { name: "Rodzaj operacji:", value: props.dane.info.rodzaj },
                { name: "Opis:", value: props.dane.info.opis },
                { name: "Nadawca / Odbiorca:", value: props.dane.info.kto },
                { name: "Nr konta:", value: props.dane.info.konto },
                { name: "Kwota:", value: props.dane.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł" },
        ];
  }

  if ( props.dane.tabela==="Wydatki" ) {
        rows = [
                { name: "Data:", value: props.dane.data },
                { name: "nazwa:", value: props.dane.tytul },
                { name: "uwagi:", value: props.dane.info.uwagi },
                { name: "nr faktury:", value: props.dane.info.nrfaktury },
                { name: "Kwota:", value: props.dane.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł" },
        ];
  }

  if ( props.dane.tabela==="Przychody" ) {
  // if ( !props.dane.tabela ) {
      rows = [
                { name: "data:", value: props.dane.data },
                { name: "nazwa:", value: props.dane.gadzet==="RapKas" ? "Skarbonka ujęta w Raporcie Kasowym" : props.dane.gadzet },
                { name: "liczba:", value: props.dane.liczba },
                { name: "kwota:", value: props.dane.kwota ? props.dane.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł" : 0+" zł" },
                { name: "stan Kasy na koniec miesiąca:", value: props.dane.gadzet==="RapKas" && props.dane.info.stanKasy ? props.dane.info.stanKasy.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł" : "brak danych" },
                { name: "potwierdzony przez Gospodarza?:", value: props.dane.gadzet==="RapKas" && props.dane.info.potwierdzone ? props.dane.info.potwierdzone : "nie" },
                { name: "uwagi:", value: props.dane.info.uwagi },
        ];
  }

       if ( props.dane.tabela==="Noclegi" ) { return props.dane.suma>0 && <TabelaNoclegi dane={props.dane} miesiac={props.dane.data} /> }
       if ( props.dane.tabela==="iposS" ) { return <TabelaIPOS dane={props.dane} /> }
  else if ( props.dane.tabela==="iposN" ) { return <TabelaIPOS dane={props.dane} noclegi /> }
  else {

  return (
    <React.Fragment>
    <Typography variant="h6">Szczegóły operacji:</Typography>
    <hr/>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => (
            row.value && <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" component="th" scope="row">
                <b>{row.name}</b>
              </TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
  }
}

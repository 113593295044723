import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Fab, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import NoclegFormularzStepper from './formularz/NoclegFormularzStepper';

export default function ButtonNoclegDodaj(props) {

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    (<React.Fragment>
      {props.button && ( // button edycji noclegu - widoczny w szczegółach listy noclegów (NoclegiLista)
          (<Fab
            color="primary"
            aria-label="edit"
            sx={props.pozycja}
            size="small"
            disabled={props.disabled}
            onClick={handleClickOpen}
          >
            <EditIcon />
          </Fab>)
      )}
      {props.dodajwgrupie && <Button
        color="primary"
        aria-label="edit"
        variant="outlined"
        onClick={handleClickOpen}
        size="small"
        disabled={props.disabled}
      >dodaj nocleg</Button>}
      {fullScreen && !props.button && props.pozycja && <Fab
        color="primary"
        aria-label="edit"
        sx={props.pozycja}
        onClick={handleClickOpen}
        disabled={props.disabled}
        size={props.size ? props.size : ""}
      >
        {props.dodawanie ? (
          <AddCircleOutlineIcon fontSize="large" />
        ) : (
          <GroupAddIcon />
        )}
      </Fab> }
      {!fullScreen && !props.dodajwgrupie && !props.button && <Button
        color="primary"
        aria-label="edit"
        variant="outlined"
        sx={props.pozycja}
        onClick={handleClickOpen}
        size={props.size ? props.size : ""}
        disabled={props.disabled}
      >{props.dodawanie?<><AddCircleOutlineIcon sx={{ mr: 1 }} /> Dodaj noclegi</>:<><GroupAddIcon sx={{ mr: 1 }} /> Edytuj noclegi</>}</Button> }
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <NoclegFormularzStepper nocleg={props.nocleg} grupa={props.grupa} handleClose={handleClose} />

      </Dialog>
    </React.Fragment>)
  );
}

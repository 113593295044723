import * as React from 'react';
// import { useQuery } from '@tanstack/react-query';
// import { fetchDane } from '../../api';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Button, Container, TextField, Typography } from '@mui/material';
// import Grid from '@mui/system/Unstable_Grid/Grid';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { miesiacLabel } from '../../komponenty/labelki';
import TabelaIPOS from '../TabelaIPOS';
import FinanseOperacje from '../FinanseOperacje';
import RapKasFormularz from './RapKasFormularz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function RaportyKasowe(props) {

        const [miesiac, setMiesiac] = React.useState({id: dayjs().format("YYYY-MM"), label: miesiacLabel(dayjs().format("MM"),true)+" "+dayjs().format("YYYY"), rok: dayjs().format("YYYY")});
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

        let miesiace = []; // ponizej stworzenie listy wyboru okresów dla Raportów
        for (let r = Number(dayjs().format("YYYY")); r > 2022; r--) {
                for (let m = 12; m > 0; m--) {
                        if (dayjs(r+"-"+m)<dayjs()) {
                                let mies = r+"-"+m;
                                let nazwa = miesiacLabel(m,true)+" "+r;
                                if (m<10) { mies = r+'-0'+m }
                                miesiace.push({id: mies, label: nazwa, rok: r});
                        }
                }
        }
        let disabled = dayjs(miesiac.id).format("YYYY-MM")<dayjs("2023-02-01").format("YYYY-MM") ? true : false;
        let disabled2 = dayjs(miesiac.id).format("YYYY-MM")>dayjs().format("YYYY-MM") ? true : false;
        // console.log(miesiac);

        return (
          <Container>
            <Grid container spacing={3} sx={{ m: 3 }}>
              <Grid xs={12} md={5} display="flex" justifyContent={!fullScreen&&"right"} alignItems="center" >
                <Typography variant="h6">Raport kasowy za miesiąc: </Typography>
              </Grid>
              <Grid xs={12} md={7} display="flex" alignItems="center" >
                <Button disabled={disabled} onClick={()=>setMiesiac({id: dayjs(miesiac.id).subtract(1, 'month').format("YYYY-MM"), label: miesiacLabel(dayjs(miesiac.id).subtract(1, 'month').format("MM"),true)+" "+dayjs(miesiac.id).subtract(1, 'month').format("YYYY"), rok: dayjs(miesiac.id).subtract(1, 'month').format("YYYY")})}><ArrowBackIosIcon /></Button>
                <Autocomplete
                  id="miesiac"
                  value={miesiac}
                  onChange={(e,v)=>setMiesiac(v)}
                  options={miesiace}
                  groupBy={(o)=>o.rok}
                  sx={{ 
                        width: 240, 
                        // pl: 4, 
                        // '& .MuiAutocomplete-listbox': { textAlign: 'right' },
                        // '& .MuiAutocomplete-popper': { textAlign: 'right' },
                        // '& .MuiAutocomplete-paper': { textAlign: 'right' },
                        // '& .MuiAutocomplete-groupLabel': { textAlign: 'right' },
                  }}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
                  isOptionEqualToValue={(o,w)=>o.id===w.id}
                  disableClearable
                />
                <Button disabled={disabled2} onClick={()=>setMiesiac({id: dayjs(miesiac.id).add(1, 'month').format("YYYY-MM"), label: miesiacLabel(dayjs(miesiac.id).add(1, 'month').format("MM"),true)+" "+dayjs(miesiac.id).add(1, 'month').format("YYYY"), rok: dayjs(miesiac.id).add(1, 'month').format("YYYY")})}><ArrowForwardIosIcon /></Button>
              </Grid>
              <Grid xs={12}>
                <RapKasFormularz miesiac={miesiac.id} />
                {/* {JSON.stringify(dayjs(miesiac.id).endOf('month').format("YYYY-MM-DD"))}<br/> */}
                <TabelaIPOS miesiac={miesiac.id} noclegi /><br/>
                <TabelaIPOS miesiac={miesiac.id} /><br/>
                <Typography variant='h6'>Wydatki w Chacie:</Typography>
                <FinanseOperacje miesiac={miesiac.id} typ="wch" rk />
                {/* <WydatkiLista miesiac={miesiac.id} /><br/> */}
              </Grid>
            </Grid>
          </Container>
        );
}
            
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from '../api';
import NoclegiWiersz from './NoclegiWiersz';

function NoclegiListaGrupa(props) {
        
  const operacje = useQuery(['dane','noclegi'], fetchDane);
  const noclegiGrupa = operacje.isSuccess && operacje.data.noclegi.filter(n=>Number(n.gid)===Number(props.grupa.gid));

  // const sorter = natsort({ desc: true });

  return (
    <TableContainer component={Paper} sx={{ mt: "1em", mb: "3em" }}>
      <Table aria-label="table" size="small">
        <TableBody>
                {/* {lista && lista.sort((a, b) => sorter(a['dodane'], b['dodane'])).map((o) => ( */}
                {operacje.isSuccess && noclegiGrupa.map((o,i) => (
                        <NoclegiWiersz key={"gos-"+i+o.idosoby} nid={o.id} odwiedziny index={i+1} />
                ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NoclegiListaGrupa;

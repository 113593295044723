import * as React from "react";
// import { serialize } from "object-to-formdata";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import { useMutation } from '@tanstack/react-query';
// import { fetchJUser } from "../api";
import KomunikatyWysylka from "../komponenty/KomunikatyWysylka";
// import FileUpload from "react-material-file-upload";
import { postMigracje } from "../api";
import {DropzoneArea} from 'mui-file-dropzone'

///// schemat walidacji pól formularza ///////////////////////////////////////
const schema = yup
  .object({
    //     nazwa: yup.string().required("Wpisz jakąś nazwę dla swojego zgłoszenia ;-)"),
    //     od: yup.string().nullable().required("Musisz podać datę przyjścia"),
    //     do: yup.string().nullable().required("Musisz podać datę wyjścia"),
    //     liczba: yup.number("Wpisz ile osób będzie razem z Tobą")
    //                 .min(1,"jeśli będziesz sam, to wpisz 1")
    //                 .max(50,"w Chacie nie zmieści się więcej niż 50 osób...")
    //                 .required("Wpisz ile osób będzie razem z Tobą"),
    //     noclegchata: yup.string().nullable().required("Wybierz czy chcesz spać w Chacie, czy poza Chatą?"),
    //     info: yup.string().required("Napisz choć dwa słowa o sobie..."),
    //     zapoznalem: yup.boolean().required("Musisz zapoznać się z Regulaminem :-)"),
  })
  .required();
// ############################################################################

function MigracjeFormularz() {
  
  const startoweDane = {
    kategoria: "",
  };
  // const cred = useQuery(['JUser'], fetchJUser);
  const [files, setFiles] = React.useState([]);

  // const handleFilesChange = (files) => {
  //   // Update chosen files
  //   setFiles([ ...files ])
  // };

  const {
    register,
    // handleSubmit,
    reset,
    setValue,
    trigger,
    watch,
    // formState: { errors },
  } = useForm({
    defaultValues: startoweDane,
    resolver: yupResolver(schema),
    // mode: "onSubmit",
    // reValidateMode: "onSubmit" | "onBlur" | "onChange",
  });

  // const queryClient = useQueryClient();
  const mutateMigracje = useMutation(mig => postMigracje('migracje',mig), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      // console.log(data);
      reset();
      setFiles([]);
    },
  });


  const onSubmit = () => {
    let dane = {
      formularz: watch(), 
      pliki: files
    };
    // console.log(dane);
    !mutateMigracje.isLoading && mutateMigracje.mutate(dane);
  };

  // console.log(props);
  let mopcje=[
    { value: "ipos", label: "iPOSweb - csv" },
    // { value: "mBank", label: "mBank - nieoficjalny" },
    { value: "mBank", label: "mBank" },
  ];

  return (
    <React.Fragment>
      <div>
      <KomunikatyWysylka stany={mutateMigracje} />
      <Box component="form" sx={{ mt: 2 }}>
        <Grid container spacing={1}>

          <Grid item xs={12} md={3}>
            <TextField
              select
              {...register("kategoria")}
              value={watch("kategoria")}
              onChange={(event) => {
                setValue("kategoria", event.target.value);
                trigger();
              }}
              label="kategoria"
              variant="outlined"
              size="small"
              margin="dense"
              // sx={{ 
              //   '& .MuiOutlinedInput-input': { p: "8.5px" } 
              // }}
            >
              {mopcje.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
            
          <Grid item xs={12}>
            <DropzoneArea
                onChange={setFiles}
                maxFileSize={99999999}
                showFileNames
                showFileNamesInPreview
                showPreviews
                showPreviewsInDropzone={false}
                previewText="podgląd: "
                filesLimit={1}
                acceptedFiles={['text/csv']}
                dropzoneText="dodaj plik"
                dropzoneClass="otryckaDropZone"
              />

            {/* <FileUpload
              // buttonText="Załącz"
              // title="Przeciągnij lub wybierz plik z dysku"
              // value={files}
              // onChange={setFiles}
              title="wybierz JEDEN plik z dysku - tylko PDF lub JPG"
              header={files.length>0?"jeden plik na raz":"przeciągnij plik tutaj"}
              leftLabel={files.length>0?"":"albo"}
              rightLabel={files.length>0?"zostanie dodany tylko pierwszy plik":"by dodać plik"}
              buttonLabel={files.length>0?"stop":"kliknij tutaj"}
              buttonRemoveLabel="usuń wszystko"
              maxFileSize={10}
              maxUploadFiles={1}
              maxFilesContainerHeight={400}
              acceptedType={'text/csv'}
              errorSizeMessage={'fill it or remove it to use the default error message'}
              allowedExtensions={['csv']}
              onFilesChange={handleFilesChange}
            /> */}
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button variant="outlined" endIcon={<SendIcon />} onClick={onSubmit}>
              zapisz
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br/>
      </div>
    </React.Fragment>
  );
}

export default MigracjeFormularz;

import dayjs from "dayjs";
import { policzNocleg, 
        // transNocleg 
} from "../komponenty/labelki";
// import dayjs from 'https://cdn.jsdelivr.net/npm/dayjs@1.11.10/dayjs.min.js';
// import dayjs from 'dayjs';

// export function podsumowania(operacje) {
//         // console.log(operacje);

//         let sumyRoczne = {};
//         console.time("calosc");

//         for (let index = 2012; index <= 2023; index++) {
//                 sumyRoczne[index] = {'rok':{nazwa:index}};
//         }

//         operacje.ipos.forEach(o=>{
//                 let rok = o.czas.substr(0,4);
//                 let miesiac = o.czas.substr(5,2);
//                 let kat = o.kat==="Noclegi"?"noclegi":"sprzedaz";
//                 let nazwa = o.nazwa;
//                 o.tabela = "ipos";

//                 // sumy roczne:
//                 // if (!sumyRoczne[rok]) { sumyRoczne[rok] = {} }
//                 // if (!sumyRoczne[rok]['rok']) { sumyRoczne[rok]['rok'] = {} }
//                 if (!sumyRoczne[rok]['rok']['ipos']) { sumyRoczne[rok]['rok']['ipos'] = {operacji:0,liczba:0,przychody:0,karta:0,karta_licz:0,karta_oper:0,noclegi:{},sprzedaz:{}} }

//                 sumyRoczne[rok]['rok']['ipos']['operacji'] += 1;
//                 sumyRoczne[rok]['rok']['ipos']['liczba'] += o.liczba;
//                 sumyRoczne[rok]['rok']['ipos']['przychody'] += o.oplata;
//                 if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                         sumyRoczne[rok]['rok']['ipos']['karta'] += o.oplata;
//                         sumyRoczne[rok]['rok']['ipos']['karta_oper'] += 1;
//                         sumyRoczne[rok]['rok']['ipos']['karta_licz'] += o.liczba;
//                 }

//                 // sumy w miesiącu:
//                 if (!sumyRoczne[rok][miesiac]) { sumyRoczne[rok][miesiac] = {} }
//                 if (!sumyRoczne[rok][miesiac]['ipos']) { 
                        
//                         let pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="ipos"&&u.wiersz===rok+"-"+miesiac);
//                         pliki.map((p,i)=>{
//                                 let plik = operacje.pliki.filter(u=>u.id===p.idpliku);
//                                 pliki[i]['info'] = typeof plik[0]['info'] === 'string' ? JSON.parse(plik[0]['info']) : plik[0]['info'];
//                                 return true;
//                         });
        
//                         sumyRoczne[rok][miesiac]['ipos'] = {
//                                 operacji:0,liczba:0,przychody:0,kwota:0,karta:0,karta_licz:0,karta_oper:0,
//                                 uzup: operacje.uzupelnienia.filter(u=>u.tabela==="ipos"&&u.wiersz===rok+"-"+miesiac),
//                                 pliki: pliki,
//                                 id: rok+"-"+miesiac,
//                                 noclegi:{},sprzedaz:{},
//                                 tabela:"ipos",
//                                 data: rok+"-"+miesiac,
//                         }
//                 }

//                 sumyRoczne[rok][miesiac]['ipos']['operacji'] += 1;
//                 sumyRoczne[rok][miesiac]['ipos']['liczba'] += o.liczba;
//                 sumyRoczne[rok][miesiac]['ipos']['przychody'] += o.oplata;
//                 sumyRoczne[rok][miesiac]['ipos']['kwota'] += o.oplata;
//                 if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                         sumyRoczne[rok][miesiac]['ipos']['karta'] += o.oplata;
//                         sumyRoczne[rok][miesiac]['ipos']['karta_oper'] += 1;
//                         sumyRoczne[rok][miesiac]['ipos']['karta_licz'] += o.liczba;
//                 }

//                 // sumy w kategoriach - rocznie:
//                 if (!sumyRoczne[rok]['rok']['ipos'][kat][nazwa]) { sumyRoczne[rok]['rok']['ipos'][kat][nazwa] = {operacji:0,liczba:0,przychody:0,karta_oper:0,karta_licz:0,karta:0} }

//                 sumyRoczne[rok]['rok']['ipos'][kat][nazwa]['operacji'] += 1;
//                 sumyRoczne[rok]['rok']['ipos'][kat][nazwa]['liczba'] += o.liczba;
//                 sumyRoczne[rok]['rok']['ipos'][kat][nazwa]['przychody'] += o.oplata;
//                 if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                         sumyRoczne[rok]['rok']['ipos'][kat][nazwa]['karta_oper'] += 1;
//                         sumyRoczne[rok]['rok']['ipos'][kat][nazwa]['karta_licz'] += o.liczba;
//                         sumyRoczne[rok]['rok']['ipos'][kat][nazwa]['karta'] += o.oplata;
//                 }

//                 // sumy w kategoriach - miesięczne:
//                 if (!sumyRoczne[rok][miesiac]['ipos'][kat][nazwa]) { sumyRoczne[rok][miesiac]['ipos'][kat][nazwa] = {operacji:0,liczba:0,przychody:0,karta_oper:0,karta_licz:0,karta:0} }

//                 sumyRoczne[rok][miesiac]['ipos'][kat][nazwa]['operacji'] += 1;
//                 sumyRoczne[rok][miesiac]['ipos'][kat][nazwa]['liczba'] += o.liczba;
//                 sumyRoczne[rok][miesiac]['ipos'][kat][nazwa]['przychody'] += o.oplata;
//                 if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                         sumyRoczne[rok][miesiac]['ipos'][kat][nazwa]['karta_oper'] += 1;
//                         sumyRoczne[rok][miesiac]['ipos'][kat][nazwa]['karta_licz'] += o.liczba;
//                         sumyRoczne[rok][miesiac]['ipos'][kat][nazwa]['karta'] += o.oplata;
//                 }
//         });

//         operacje.banki.forEach(o=>{
//                 let rok = o.data.substr(0,4);
//                 let miesiac = o.data.substr(5,2);
//                 let kat = "";
//                 if (o.bank==="mBankGl"||o.bank==="VWBank") {kat="bank"}
//                 if (o.bank==="mBank"||o.bank==="kasa") {kat="kasa"}
//                 let typ = o.typ===1?"przychody":"wydatki";
//                 o.uzup = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                 o.pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                 o.pliki.map((p,i)=>{
//                         let plik = operacje.pliki.filter(u=>u.id===p.idpliku);
//                         o.pliki[i]['info'] = typeof plik[0]['info'] === 'string' ? JSON.parse(plik[0]['info']) : plik[0]['info'];
//                         return true;
//                 });
//                 o.tabela = "Banki";

//                 // sumy roczne:
//                 // if (!sumyRoczne[rok]) { sumyRoczne[rok] = {rok:{}} }
//                 if (!sumyRoczne[rok]['rok'][kat]) { sumyRoczne[rok]['rok'][kat] = {} }
//                 if (!sumyRoczne[rok]['rok'][kat][typ]) { sumyRoczne[rok]['rok'][kat][typ] = {oper:0,uzup:0,plik:0,suma:0,przeplywy:0,przepliczba:0} }

//                 sumyRoczne[rok]['rok'][kat][typ]['oper'] += 1;
//                 sumyRoczne[rok]['rok'][kat][typ]['uzup'] += Object.keys(o.uzup).length;
//                 sumyRoczne[rok]['rok'][kat][typ]['plik'] += Object.keys(o.pliki).length;
//                 sumyRoczne[rok]['rok'][kat][typ]['suma'] += o.kwota;
//                 o.uzup.forEach(u=>{
//                         if(u.wybor==="przepływy") {
//                                 sumyRoczne[rok]['rok'][kat][typ]['przeplywy'] += (u.kwota?u.kwota:o.kwota);
//                                 sumyRoczne[rok]['rok'][kat][typ]['przepliczba'] += 1;
//                         }
//                 });

//                 // sumy miesięczne:
//                 if (!sumyRoczne[rok][miesiac]) { sumyRoczne[rok][miesiac] = {} }
//                 if (!sumyRoczne[rok][miesiac][kat]) { sumyRoczne[rok][miesiac][kat] = {} }
//                 if (!sumyRoczne[rok][miesiac][kat][typ]) { sumyRoczne[rok][miesiac][kat][typ] = {oper:0,uzup:0,plik:0,suma:0,przeplywy:0,przepliczba:0,operacje:{}} }

//                 sumyRoczne[rok][miesiac][kat][typ]['oper'] += 1;
//                 sumyRoczne[rok][miesiac][kat][typ]['uzup'] += Object.keys(o.uzup).length;
//                 sumyRoczne[rok][miesiac][kat][typ]['plik'] += Object.keys(o.pliki).length;
//                 sumyRoczne[rok][miesiac][kat][typ]['suma'] += o.kwota;
//                 o.uzup.forEach(u=>{
//                         if(u.wybor==="przepływy") {
//                                 sumyRoczne[rok][miesiac][kat][typ]['przeplywy'] += (u.kwota?u.kwota:o.kwota);
//                                 sumyRoczne[rok][miesiac][kat][typ]['przepliczba'] += 1;
//                         }
//                 });
//                 sumyRoczne[rok][miesiac][kat][typ]['operacje'][o.id] = o;

//         });

//         operacje.przychody.forEach(o=>{
//                 let rok = o.data.substr(0,4);
//                 let miesiac = o.data.substr(5,2);
//                 o.uzup = operacje.uzupelnienia.filter(u=>u.tabela==="Przychody"&&u.wiersz===o.id);
//                 o.pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="Przychody"&&u.wiersz===o.id);
//                 o.pliki.map((p,i)=>{
//                         let plik = operacje.pliki.filter(u=>u.id===p.idpliku);
//                         o.pliki[i]['info'] = typeof plik[0]['info'] === 'string' ? JSON.parse(plik[0]['info']) : plik[0]['info'];
//                         return true;
//                 });
//                 o.tabela = "Przychody";

//                 // sumy roczne:
//                 if (!sumyRoczne[rok]['rok']['chata']) { sumyRoczne[rok]['rok']['chata'] = {} }
//                 if (!sumyRoczne[rok]['rok']['chata']['przychody']) { sumyRoczne[rok]['rok']['chata']['przychody'] = {oper:0,liczba:0,suma:0,przeplywy:0,przepliczba:0} }

//                 sumyRoczne[rok]['rok']['chata']['przychody']['oper'] += 1;
//                 sumyRoczne[rok]['rok']['chata']['przychody']['liczba'] += o.liczba;
//                 sumyRoczne[rok]['rok']['chata']['przychody']['suma'] += o.kwota;
//                 o.uzup.forEach(u=>{
//                         if(u.wybor==="przepływy") {
//                                 sumyRoczne[rok]['rok']['chata']['przychody']['przeplywy'] += (u.kwota?u.kwota:o.kwota);
//                                 sumyRoczne[rok]['rok']['chata']['przychody']['przepliczba'] += 1;
//                         }
//                 });

//                 // sumy miesięczne:
//                 if (!sumyRoczne[rok][miesiac]['chata']) { sumyRoczne[rok][miesiac]['chata'] = {} }
//                 if (!sumyRoczne[rok][miesiac]['chata']['przychody']) { sumyRoczne[rok][miesiac]['chata']['przychody'] = {oper:0,liczba:0,suma:0,przeplywy:0,przepliczba:0,operacje:{}} }
//                 // if (!sumyRoczne[rok][miesiac]['chata']) { sumyRoczne[rok][miesiac]['chata'] = {przychody:{oper:0,liczba:0,suma:0}} }

//                 sumyRoczne[rok][miesiac]['chata']['przychody']['oper'] += 1;
//                 sumyRoczne[rok][miesiac]['chata']['przychody']['liczba'] += o.liczba;
//                 sumyRoczne[rok][miesiac]['chata']['przychody']['suma'] += o.kwota;
//                 o.uzup.forEach(u=>{
//                         if(u.wybor==="przepływy") {
//                                 sumyRoczne[rok][miesiac]['chata']['przychody']['przeplywy'] += (u.kwota?u.kwota:o.kwota);
//                                 sumyRoczne[rok][miesiac]['chata']['przychody']['przepliczba'] += 1;
//                         }
//                 });

//                 sumyRoczne[rok][miesiac]['chata']['przychody']['operacje'][o.id] = o;
//         });

//         operacje.wydatki.forEach(o=>{
//                 let rok = o.data.substr(0,4);
//                 let miesiac = o.data.substr(5,2);
//                 o.uzup = operacje.uzupelnienia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id);
//                 o.pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id);
//                 o.pliki.map((p,i)=>{
//                         let plik = operacje.pliki.filter(u=>u.id===p.idpliku);
//                         o.pliki[i]['info'] = typeof plik[0]['info'] === 'string' ? JSON.parse(plik[0]['info']) : plik[0]['info'];
//                         return true;
//                 });
//                 o.tabela = "Wydatki";

//                 // sumy roczne:
//                 if (!sumyRoczne[rok]['rok']['chata']) { sumyRoczne[rok]['rok']['chata'] = {} }
//                 if (!sumyRoczne[rok]['rok']['chata']['wydatki']) { sumyRoczne[rok]['rok']['chata']['wydatki'] = {oper:0,uzup:0,plik:0,suma:0,przeplywy:0,przepliczba:0} }

//                 sumyRoczne[rok]['rok']['chata']['wydatki']['oper'] += 1;
//                 sumyRoczne[rok]['rok']['chata']['wydatki']['uzup'] += Object.keys(o.uzup).length;
//                 sumyRoczne[rok]['rok']['chata']['wydatki']['plik'] += Object.keys(o.pliki).length;
//                 sumyRoczne[rok]['rok']['chata']['wydatki']['suma'] += o.zobowiazanie===0?o.kwota:0;
//                 o.uzup.forEach(u=>{
//                         if(u.wybor==="przepływy") {
//                                 sumyRoczne[rok]['rok']['chata']['wydatki']['przeplywy'] += (u.kwota?u.kwota:o.kwota);
//                                 sumyRoczne[rok]['rok']['chata']['wydatki']['przepliczba'] += 1;
//                         }
//                 });

//                 // sumy miesięczne:
//                 if (!sumyRoczne[rok][miesiac]['chata']) { sumyRoczne[rok][miesiac]['chata'] = {} }
//                 if (!sumyRoczne[rok][miesiac]['chata']['wydatki']) { sumyRoczne[rok][miesiac]['chata']['wydatki'] = {oper:0,uzup:0,plik:0,suma:0,przeplywy:0,przepliczba:0,operacje:{}} }

//                 sumyRoczne[rok][miesiac]['chata']['wydatki']['oper'] += 1;
//                 sumyRoczne[rok][miesiac]['chata']['wydatki']['uzup'] += Object.keys(o.uzup).length;
//                 sumyRoczne[rok][miesiac]['chata']['wydatki']['plik'] += Object.keys(o.pliki).length;
//                 sumyRoczne[rok][miesiac]['chata']['wydatki']['suma'] += o.zobowiazanie===0?o.kwota:0;
//                 o.uzup.forEach(u=>{
//                         if(u.wybor==="przepływy") {
//                                 sumyRoczne[rok][miesiac]['chata']['wydatki']['przeplywy'] += (u.kwota?u.kwota:o.kwota);
//                                 sumyRoczne[rok][miesiac]['chata']['wydatki']['przepliczba'] += 1;
//                         }
//                 });
//                 sumyRoczne[rok][miesiac]['chata']['wydatki']['operacje'][o.id] = o;
//         });

//         operacje.noclegi.forEach(n=>{
//                 let rok = n.dataod.substr(0,4);
//                 let miesiac = n.dataod.substr(5,2);
//                 let o = transNocleg(n);
//                 let kat = o.kategoria;
//                 o.tabela = "Noclegi";

//                 if(o.do) { // sprawdzenie czy jest podana data wyjścia, sumowanie tylko dla prawidłowych noclegów:

//                 // sumy roczne:
//                 if (!sumyRoczne[rok]['rok']['chata']) { sumyRoczne[rok]['rok']['chata'] = {} }
//                 if (!sumyRoczne[rok]['rok']['chata']['noclegi']) { sumyRoczne[rok]['rok']['chata']['noclegi'] = {wizyt:0,nocy:0,suma:0,osoby:{}} }

//                 sumyRoczne[rok]['rok']['chata']['noclegi']['wizyt'] += 1;
//                 sumyRoczne[rok]['rok']['chata']['noclegi']['nocy'] += o.nocy;
//                 sumyRoczne[rok]['rok']['chata']['noclegi']['suma'] += o.kwota;

//                 // sumy miesięczne:
//                 if (!sumyRoczne[rok][miesiac]['chata']) { sumyRoczne[rok][miesiac]['chata'] = {} }
//                 if (!sumyRoczne[rok][miesiac]['chata']['noclegi']) { 

//                         let pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="Noclegi"&&u.wiersz===rok+"-"+miesiac);
//                         pliki.map((p,i)=>{
//                                 let plik = operacje.pliki.filter(u=>u.id===p.idpliku);
//                                 pliki[i]['info'] = typeof plik[0]['info'] === 'string' ? JSON.parse(plik[0]['info']) : plik[0]['info'];
//                                 return true;
//                         });
        
//                         sumyRoczne[rok][miesiac]['chata']['noclegi'] = {
//                                 wizyt:0,nocy:0,suma:0,kwota:0,
//                                 uzup: operacje.uzupelnienia.filter(u=>u.tabela==="Noclegi"&&u.wiersz===rok+"-"+miesiac),
//                                 pliki: pliki,
//                                 id: rok+"-"+miesiac,
//                                 lista:{},osoby:{},
//                                 tabela:"Noclegi",
//                                 data: rok+"-"+miesiac,
//                         }
//                 }

//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['wizyt'] += 1;
//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['nocy'] += o.nocy;
//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['suma'] += o.kwota;
//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['kwota'] += o.kwota;

//                 // sumy roczne w kategoriach:
//                 if (!sumyRoczne[rok]['rok']['chata']['noclegi']['kat']) {
//                         sumyRoczne[rok]['rok']['chata']['noclegi']['kat'] = {}; 
//                         for (let index = 1; index < 10; index++) { // trzeba od razu utworzyć wszystkie kategorie
//                                 sumyRoczne[rok]['rok']['chata']['noclegi']['kat'][index] = {wizyt:0,nocy:0,suma:0};
//                         }
//                 }

//                 sumyRoczne[rok]['rok']['chata']['noclegi']['kat'][kat]['wizyt'] += 1;
//                 sumyRoczne[rok]['rok']['chata']['noclegi']['kat'][kat]['nocy'] += o.nocy;
//                 sumyRoczne[rok]['rok']['chata']['noclegi']['kat'][kat]['suma'] += o.kwota;

//                 // sumy miesięczne w kategoriach:
//                 if (!sumyRoczne[rok][miesiac]['chata']['noclegi']['kat']) { 
//                         sumyRoczne[rok][miesiac]['chata']['noclegi']['kat'] = {}; 
//                         for (let index = 1; index < 10; index++) { // trzeba od razu utworzyć wszystkie kategorie
//                                 sumyRoczne[rok][miesiac]['chata']['noclegi']['kat'][index] = {wizyt:0,nocy:0,suma:0} 
//                         }
//                 }

//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['kat'][kat]['wizyt'] += 1;
//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['kat'][kat]['nocy'] += o.nocy;
//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['kat'][kat]['suma'] += o.kwota;

//                 // lista noclegów w miesiącu:
//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['lista'][o.nid] = o;
//                 sumyRoczne[rok][miesiac]['chata']['noclegi']['osoby'][o.idosoby] = o.idosoby;
//                 sumyRoczne[rok]['rok']['chata']['noclegi']['osoby'][o.idosoby] = o.idosoby;
//         }

//         });

//         console.timeEnd("calosc");
//         return {
//                 lata: sumyRoczne,
//                 inwentarz: operacje.inwentarz,
//         }
// }

// // ###########################################################################
// export function bilanseLata(operacje) {
//         // console.log(operacje);

//         let sumyRoczne = {};
//         // console.time("calosc");

//         // console.time("f1");
//         for (let index = 2012; index <= 2023; index++) {
//                 sumyRoczne[index] = {nazwa:index,c:{przychody:0,wydatki:0},k:{przychody:0,wydatki:0}};
//         }
//         // console.timeEnd("f1");

//         // console.time("ipos");
//         operacje.ipos.forEach(o=>{
//                 let rok = o.czas.substr(0,4);
//                 sumyRoczne[rok]['k']['przychody'] += o.oplata; 
//                 if (o.kat!=="Noclegi") { sumyRoczne[rok]['c']['przychody'] += o.oplata }
//         });
//         // console.timeEnd("ipos");

//         // console.time("banki");
//         operacje.banki.forEach(o=>{
//                 let rok = o.data.substr(0,4);
//                 let typ = o.typ===1?"przychody":"wydatki";
//                 let uzup = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id&&u.wybor==="przepływy");
//                 sumyRoczne[rok]['c'][typ] += o.kwota;
//                 if (o.bank==="mBankGl"||o.bank==="VWBank") {
//                         sumyRoczne[rok]['k'][typ] += o.kwota;
//                         uzup.forEach(u=>{ sumyRoczne[rok]['k'][typ] -= u&&u.kwota>0?u.kwota:o.kwota; });
//                 }
//                 uzup.forEach(u=>{ sumyRoczne[rok]['c'][typ] -= u&&u.kwota>0?u.kwota:o.kwota; });
//         });
//         // console.timeEnd("banki");

//         // console.time("prz");
//         operacje.przychody.forEach(o=>{
//                 let rok = o.data.substr(0,4);
//                 let uzup = operacje.uzupelnienia.filter(u=>u.tabela==="Przychody"&&u.wiersz===o.id&&u.wybor==="przepływy");
//                 if (isNaN(o.kwota)) { console.log(o); }
//                 sumyRoczne[rok]['c']['przychody'] += o.kwota;
//                 uzup.forEach(u=>{ sumyRoczne[rok]['c']['przychody'] -= u&&u.kwota>0?u.kwota:o.kwota; });
//         });
//         // console.timeEnd("prz");

//         // console.time("wyd");
//         operacje.wydatki.forEach(o=>{
//                 let rok = o.data.substr(0,4);
//                 let przeplywy = operacje.uzupelnienia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id&&u.wybor==="przepływy");
//                 let koszty = operacje.uzupelnienia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id&&u.wybor==="koszt");
//                 sumyRoczne[rok]['c']['wydatki'] += o.zobowiazanie===0?o.kwota:0;
//                 przeplywy.forEach(u=>{ sumyRoczne[rok]['c']['wydatki'] -= u&&u.kwota>0?u.kwota:o.kwota; });
//                 koszty.forEach(u=>{ sumyRoczne[rok]['k']['wydatki'] += u&&u.kwota>0?u.kwota:o.kwota; });
//         });
//         // console.timeEnd("wyd");

//         console.time("noc");
//         operacje.noclegi.forEach(n=>{
//                 n = transNocleg(n);
//                 let rok = n.od.substr(0,4);
//                 if ( Number(n.rodzaj)===1 ) { n.kwota = Number(n.oplata?n.oplata:0)+Number(n.karta?n.karta:0) }
//                 else if ( Number(n.rodzaj)>1 ) { n.kwota = Number(n.oplata?n.oplata:0) }
//                 if ( Number(n.kategoria)===7 ) { n.kwota = Number(n.oplata?n.oplata:0) }
//                 if (isNaN(n.kwota)) { console.log("wadliwy: ",n); }
//                 if(n.do){ sumyRoczne[rok]['c']['przychody'] += Number(n.kwota); }
//         });
//         // console.timeEnd("noc");
//         // console.timeEnd("calosc");

//         return sumyRoczne
// }

// // ###########################################################################
// export function bilanseMiesiac(operacje,rok) {
        

//         let sumyMiesieczne = {};
//         let miesiac = "";
//         // console.log(rok)
//         // console.time("caloscM");
//         for (let index = 1; index <= 12; index++) {
//                 if (index<10) { miesiac = '0'+index } else { miesiac = index }
//                 sumyMiesieczne[miesiac] = {
//                         c:{ // całość
//                                 przychody:{chata:0,bank:0,kasa:0},
//                                 wydatki:{chata:0,bank:0,kasa:0}
//                         },
//                         k:{ // księgowe
//                                 przychody:{chata:0,bank:0,kasa:0},
//                                 wydatki:{chata:0,bank:0,kasa:0}
//                         },
//                         u:{ // uzupełnienia
//                                 przychody:{chata:{o:0,u:0,p:0,f:0},bank:{o:0,u:0,p:0,f:0},kasa:{o:0,u:0,p:0,f:0}},
//                                 wydatki:{chata:{o:0,u:0,p:0,f:0},bank:{o:0,u:0,p:0,f:0},kasa:{o:0,u:0,p:0,f:0}}
//                         }
//                 };
//         }
        
//         let ipos = []; // żeby zliczyć noclegi jako jedną pozycję na liście w miesiącu
//         operacje.ipos.filter(o=>Number(o.czas.substr(0,4))===rok).forEach(o=>{
//                 // console.log(o);
//                 let miesiac = o.czas.substr(5,2);
//                 let uz = operacje.uzupelnienia.filter(u=>u.tabela==="ipos"&&u.wiersz===o.id);
//                 let pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="ipos"&&u.wiersz===o.id);
//                 if (!ipos[miesiac]) { sumyMiesieczne[miesiac]['u']['przychody']['chata']['o'] += 1; ipos[miesiac]="a"; }
//                 if (rok>2022) {
//                         if (o.platnosc==="gotówka") {
//                                 sumyMiesieczne[miesiac]['c']['przychody']['chata'] += o.oplata;
//                                 sumyMiesieczne[miesiac]['k']['przychody']['chata'] += o.oplata;
//                         }
//                 } else {
//                         if (o.kat!=="Noclegi") {
//                                 sumyMiesieczne[miesiac]['c']['przychody']['chata'] += o.oplata;
//                         }
//                         sumyMiesieczne[miesiac]['k']['przychody']['chata'] += o.oplata;
//                 }
//                 sumyMiesieczne[miesiac]['u']['przychody']['chata']['u'] += uz.length;
//                 sumyMiesieczne[miesiac]['u']['przychody']['chata']['p'] += pliki.length;
//         });

//         operacje.banki.filter(o=>Number(o.data.substr(0,4))===rok).forEach(o=>{
//                 let miesiac = o.data.substr(5,2);
//                 // console.log(o);
//                 let typ = o.typ===1?"przychody":"wydatki";
//                 let uz = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                 let pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                 let uzup = uz.filter(u=>u&&u.wybor==="przepływy");
//                 if (o.bank==="mBankGl"||o.bank==="VWBank") {
//                         sumyMiesieczne[miesiac]['u'][typ]['bank']['o'] += 1;
//                         sumyMiesieczne[miesiac]['u'][typ]['bank']['u'] += uz.length;
//                         sumyMiesieczne[miesiac]['u'][typ]['bank']['p'] += pliki.length;
//                         sumyMiesieczne[miesiac]['u'][typ]['bank']['f'] += uzup.length;
//                         sumyMiesieczne[miesiac]['k'][typ]['bank'] += o.kwota;
//                         uzup.forEach(u=>{ sumyMiesieczne[miesiac]['k'][typ]['bank'] -= u&&u.kwota>0?u.kwota:o.kwota; });
//                 } else {
//                         sumyMiesieczne[miesiac]['u'][typ]['kasa']['o'] += 1;
//                         sumyMiesieczne[miesiac]['u'][typ]['kasa']['u'] += uz.length;
//                         sumyMiesieczne[miesiac]['u'][typ]['kasa']['p'] += pliki.length;
//                         sumyMiesieczne[miesiac]['u'][typ]['kasa']['f'] += uzup.length;
//                         sumyMiesieczne[miesiac]['c'][typ]['kasa'] += o.kwota;
//                         uzup.forEach(u=>{ sumyMiesieczne[miesiac]['c'][typ]['kasa'] -= u&&u.kwota>0?u.kwota:o.kwota; });
//                 }
//         });

//         operacje.przychody.filter(o=>Number(o.data.substr(0,4))===rok).forEach(o=>{
//                 let miesiac = o.data.substr(5,2);
//                 if (isNaN(o.kwota)) { console.log(o); }
//                 let uz = operacje.uzupelnienia.filter(u=>u.tabela==="Przychody"&&u.wiersz===o.id);
//                 let pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="Przychody"&&u.wiersz===o.id);
//                 let uzup = uz.filter(u=>u&&u.wybor==="przepływy");
//                 sumyMiesieczne[miesiac]['u']['przychody']['chata']['o'] += 1;
//                 sumyMiesieczne[miesiac]['u']['przychody']['chata']['u'] += uz.length;
//                 sumyMiesieczne[miesiac]['u']['przychody']['chata']['p'] += pliki.length;
//                 sumyMiesieczne[miesiac]['u']['przychody']['chata']['f'] += uzup.length;
//                 sumyMiesieczne[miesiac]['c']['przychody']['chata'] += o.kwota;
//                 uzup.forEach(u=>{ sumyMiesieczne[miesiac]['c']['przychody']['chata'] -= u&&u.kwota>0?u.kwota:o.kwota; });
//         });

//         operacje.wydatki.filter(o=>Number(o.data.substr(0,4))===rok).forEach(o=>{
//                 let miesiac = o.data.substr(5,2);
//                 let uz = operacje.uzupelnienia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id);
//                 let pliki = operacje.pliki_polaczenia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id);
//                 let uzup = uz.filter(u=>u&&u.wybor==="przepływy");
//                 let koszt = uz.filter(u=>u&&u.wybor==="koszt");
//                 sumyMiesieczne[miesiac]['u']['wydatki']['chata']['o'] += 1;
//                 sumyMiesieczne[miesiac]['u']['wydatki']['chata']['u'] += uz.length;
//                 sumyMiesieczne[miesiac]['u']['wydatki']['chata']['p'] += pliki.length;
//                 sumyMiesieczne[miesiac]['u']['wydatki']['chata']['f'] += uzup.length;
//                 sumyMiesieczne[miesiac]['c']['wydatki']['chata'] += o.zobowiazanie===0?o.kwota:0;
//                 uzup.forEach(u=>{ sumyMiesieczne[miesiac]['c']['wydatki']['chata'] -= u&&u.kwota>0?u.kwota:o.kwota; });
//                 koszt.forEach(u=>{ sumyMiesieczne[miesiac]['k']['wydatki']['chata'] += u&&u.kwota>0?u.kwota:o.kwota; });
//         });

//         let noclegi_liczba = []; // żeby zliczyć noclegi jako jedną pozycję na liście w miesiącu
//         operacje.noclegi.filter(o=>Number(o.dataod.substr(0,4))===rok).forEach(n=>{
//                 n = transNocleg(n);
//                 let miesiac = n.od.substr(5,2);
//                 if (!noclegi_liczba[miesiac]) { sumyMiesieczne[miesiac]['u']['przychody']['chata']['o'] += 1; noclegi_liczba[miesiac]="a"; }

//                 if ( Number(n.rodzaj)===1 ) { n.kwota = Number(n.oplata?n.oplata:0)+Number(n.karta?n.karta:0) }
//                 else if ( Number(n.rodzaj)>1 ) { n.kwota = Number(n.oplata?n.oplata:0) }
//                 if ( Number(n.kategoria)===7 ) { n.kwota = Number(n.oplata?n.oplata:0) }
//                 if (isNaN(n.kwota)) { console.log("wadliwy: ",n); }
//                 if(rok<2023&&n.do){ sumyMiesieczne[miesiac]['c']['przychody']['chata'] += Number(n.kwota); }
//         });

//         // console.timeEnd("caloscM");
//         return sumyMiesieczne;
// }

// export function operacjeMiesiac(operacje,props) {
//         // console.time("OperMies");
//         // console.log(props);
//         let miesiac = props.rok+"-"+props.m;
//         let lista = [];
//         if (props.typ==="pb") {
//                 operacje.banki.filter(o=>o.data.substr(0,7)===miesiac && o.typ===1 && (o.bank==="mBankGl"||o.bank==="VWBank"))
//                 .map(o=>{
//                         let newOp = o;
//                         newOp['uzup'] = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                         newOp['pliki'] = operacje.pliki_polaczenia.filter(p=>p.tabela==="Banki"&&p.wiersz===o.id)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]);
//                         newOp['tabela'] = "Banki";
//                         lista.push(newOp);
//                         return true;
//                 })
//         }
//         if (props.typ==="wb") {
//                 operacje.banki.filter(o=>o.data.substr(0,7)===miesiac && o.typ===2 && (o.bank==="mBankGl"||o.bank==="VWBank"))
//                 .map(o=>{
//                         let newOp = o;
//                         newOp['uzup'] = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                         newOp['pliki'] = operacje.pliki_polaczenia.filter(p=>p.tabela==="Banki"&&p.wiersz===o.id)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]);
//                         newOp['tabela'] = "Banki";
//                         lista.push(newOp);
//                         return true;
//                 })
//         }
//         if (props.typ==="pk") {
//                 operacje.banki.filter(o=>o.data.substr(0,7)===miesiac && o.typ===1 && !(o.bank==="mBankGl"||o.bank==="VWBank"))
//                 .map(o=>{
//                         let newOp = o;
//                         newOp['uzup'] = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                         newOp['pliki'] = operacje.pliki_polaczenia.filter(p=>p.tabela==="Banki"&&p.wiersz===o.id)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]);
//                         newOp['tabela'] = "Banki";
//                         lista.push(newOp);
//                         return true;
//                 })
//         }
//         if (props.typ==="wk") {
//                 operacje.banki.filter(o=>o.data.substr(0,7)===miesiac && o.typ===2 && !(o.bank==="mBankGl"||o.bank==="VWBank"))
//                 .map(o=>{
//                         let newOp = o;
//                         newOp['uzup'] = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
//                         newOp['pliki'] = operacje.pliki_polaczenia.filter(p=>p.tabela==="Banki"&&p.wiersz===o.id)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]);
//                         newOp['tabela'] = "Banki";
//                         lista.push(newOp);
//                         return true;
//                 })
//         }
//         if (props.typ==="pch") {
//                 // gadżety:
//                 operacje.przychody.filter(o=>o.data.substr(0,7)===miesiac)
//                 .map(o=>{
//                         let newOp = o;
//                         if (o.gadzet==="RapKas") o.gadzet = "Skarbonka"
//                         newOp['uzup'] = operacje.uzupelnienia.filter(u=>u.tabela==="Przychody"&&u.wiersz===o.id);
//                         newOp['pliki'] = operacje.pliki_polaczenia.filter(p=>p.tabela==="Przychody"&&p.wiersz===o.id)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]);
//                         newOp['tabela'] = "Przychody";
//                         lista.push(newOp);
//                         return true;
//                 })
//                 // NOCLEGI:
//                 let raport = {
//                         id: miesiac,
//                         wizyt:0,nocy:0,kwota:0,
//                         kat: [],
//                         uzup: operacje.uzupelnienia.filter(u=>u.tabela==="Noclegi"&&u.wiersz===miesiac),
//                         pliki: operacje.pliki_polaczenia.filter(p=>p.tabela==="Noclegi"&&p.wiersz===miesiac)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]),
//                         lista:{},osoby:{},
//                         tabela:"Noclegi",
//                         data: miesiac,
//                 }
//                 // trzeba od razu utworzyć wszystkie kategorie:
//                 let kategorie = [1,2,3,9,4,5,6,7]; // by ustawić kolejność na liście
//                 kategorie.forEach(index=>raport['kat'][index] = {id:index,wizyt:0,nocy:0,kwota:0})

//                 operacje.noclegi.filter(o=>o.dataod.substr(0,7)===miesiac)
//                 .map(n=>{
//                         let o = transNocleg(n);
//                         // console.log(o)
//                         // TODO: przypisanie właściwej kwoty - docelowo do zamiany na funkcję...
//                         // if ( Number(n.rodzaj)===1 ) { n.kwota = Number(n.oplata?n.oplata:0)+Number(n.karta?n.karta:0) }
//                         // else if ( Number(n.rodzaj)>1 ) { n.kwota = Number(n.oplata?n.oplata:0) }
//                         // if ( Number(n.kategoria)===7 ) { n.kwota = Number(n.oplata?n.oplata:0) }
//                         // if (isNaN(n.kwota)) { console.log(n); }

//                         if(o.do){
//                                 // sumy miesięczne całościowo:
//                                 raport['wizyt'] += 1;
//                                 raport['nocy'] += o.nocy;
//                                 raport['kwota'] += o.kwota;

//                                 // sumy miesięczne w kategoriach:
//                                 raport['kat'][o.kategoria]['wizyt'] += 1;
//                                 raport['kat'][o.kategoria]['nocy'] += o.nocy;
//                                 raport['kat'][o.kategoria]['kwota'] += o.kwota;
//                         }

//                         // lista noclegów w miesiącu:
//                         raport['lista'][o.nid] = o;
//                         raport['osoby'][o.idosoby] = 1;
//                         return true;
//                 });
//                 lista.push(raport);






//                 // IPOS - sprzedaż:
//                 let ipos = {
//                         id: miesiac,
//                         operacji:0,liczba:0,przychody:0,kwota:0,karta:0,karta_licz:0,karta_oper:0,
//                         uzup: operacje.uzupelnienia.filter(u=>u.tabela==="Noclegi"&&u.wiersz===miesiac),
//                         pliki: operacje.pliki_polaczenia.filter(p=>p.tabela==="Noclegi"&&p.wiersz===miesiac)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]),
//                         noclegi:{},sprzedaz:{},
//                         tabela:"iposS",
//                         data: miesiac,
//                 }
//                 // trzeba od razu utworzyć wszystkie kategorie:
//                 // let kategorie = [1,2,3,9,4,5,6,7]; // by ustawić kolejność na liście
//                 // kategorie.forEach(index=>raport['kat'].push({id:index,wizyt:0,nocy:0,kwota:0}))

//                 operacje.ipos.filter(o=>o.czas.substr(0,7)===miesiac && o.kat!=="Noclegi")
//                 .map(o=>{

//                         let kat = o.kat==="Noclegi"?"noclegi":"sprzedaz";
//                         // ipos['operacji'] += 1;
//                         // ipos['liczba'] += o.liczba;
//                         // ipos['przychody'] += o.oplata;
//                         // ipos['kwota'] += o.oplata;
//                         if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                                 ipos['karta'] += o.oplata;
//                                 ipos['karta_oper'] += 1;
//                                 ipos['karta_licz'] += o.liczba;
//                         } else {
//                                 ipos['operacji'] += 1;
//                                 ipos['liczba'] += o.liczba;
//                                 ipos['przychody'] += o.oplata;
//                                 ipos['kwota'] += o.oplata;
//                         }
        
//                         // sumy w kategoriach:
//                         if (!ipos[kat][o.nazwa]) { ipos[kat][o.nazwa] = {operacji:0,liczba:0,przychody:0,karta_oper:0,karta_licz:0,karta:0} }
        
//                         if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                                 ipos[kat][o.nazwa]['karta_oper'] += 1;
//                                 ipos[kat][o.nazwa]['karta_licz'] += o.liczba;
//                                 ipos[kat][o.nazwa]['karta'] += o.oplata;
//                         } else {
//                                 ipos[kat][o.nazwa]['operacji'] += 1;
//                                 ipos[kat][o.nazwa]['liczba'] += o.liczba;
//                                 ipos[kat][o.nazwa]['przychody'] += o.oplata;
//                         }
        
//                         // if (kat==="sprzedaz") {
//                         //         let newOp = o;
//                         //         newOp['uzup'] = [];  // operacje.uzupelnienia.filter(u=>u.tabela==="Przychody"&&u.wiersz===o.id);
//                         //         newOp['pliki'] = []; // operacje.pliki_polaczenia.filter(p=>p.tabela==="Przychody"&&p.wiersz===o.id)
//                         //                         // .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]);
//                         //         newOp['tabela'] = "Przychody";
//                         //         newOp['kwota'] = o.oplata;
//                         //         newOp['gadzet'] = o.nazwa+" - "+o.liczba+" szt.";
//                         //         newOp['data'] = dayjs(o.czas).format("YYYY-MM-DD");
//                         //         lista.push(newOp);
//                         // }


//                         // // lista noclegów w miesiącu:
//                         // raport['lista'][o.id] = o;
//                         // raport['osoby'][o.idosoby] = 1;
//                         return true;
//                 });
//                 lista.push(ipos);






//                 // IPOS - noclegi:
//                 ipos = {
//                         id: miesiac,
//                         operacji:0,liczba:0,przychody:0,kwota:0,karta:0,karta_licz:0,karta_oper:0,
//                         uzup: operacje.uzupelnienia.filter(u=>u.tabela==="Noclegi"&&u.wiersz===miesiac),
//                         pliki: operacje.pliki_polaczenia.filter(p=>p.tabela==="Noclegi"&&p.wiersz===miesiac)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]),
//                         noclegi:{},sprzedaz:{},
//                         tabela:"iposN",
//                         data: miesiac,
//                 }
//                 // trzeba od razu utworzyć wszystkie kategorie:
//                 // let kategorie = [1,2,3,9,4,5,6,7]; // by ustawić kolejność na liście
//                 // kategorie.forEach(index=>raport['kat'].push({id:index,wizyt:0,nocy:0,kwota:0}))

//                 operacje.ipos.filter(o=>o.czas.substr(0,7)===miesiac && o.kat==="Noclegi")
//                 .map(o=>{

//                         let kat = o.kat==="Noclegi"?"noclegi":"sprzedaz";
//                         if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                                 ipos['karta'] += o.oplata;
//                                 ipos['karta_oper'] += 1;
//                                 ipos['karta_licz'] += o.liczba;
//                         } else {
//                                 ipos['operacji'] += 1;
//                                 ipos['liczba'] += o.liczba;
//                                 ipos['przychody'] += o.oplata;
//                                 ipos['kwota'] += o.oplata;
//                         }
        
//                         // sumy w kategoriach:
//                         if (!ipos[kat][o.nazwa]) { ipos[kat][o.nazwa] = {operacji:0,liczba:0,przychody:0,karta_oper:0,karta_licz:0,karta:0} }
        
//                         if(o.platnosc==="karta"||o.platnosc==="przelew") {
//                                 ipos[kat][o.nazwa]['karta_oper'] += 1;
//                                 ipos[kat][o.nazwa]['karta_licz'] += o.liczba;
//                                 ipos[kat][o.nazwa]['karta'] += o.oplata;
//                         } else {
//                                 ipos[kat][o.nazwa]['operacji'] += 1;
//                                 ipos[kat][o.nazwa]['liczba'] += o.liczba;
//                                 ipos[kat][o.nazwa]['przychody'] += o.oplata;
//                         }
//                         return true;
//                 });
//                 lista.push(ipos);
//         }
//         if (props.typ==="wch") {
//                 operacje.wydatki.filter(o=>o.data.substr(0,7)===miesiac)
//                 .map(o=>{
//                         let newOp = o;
//                         newOp['uzup'] = operacje.uzupelnienia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id);
//                         newOp['pliki'] = operacje.pliki_polaczenia.filter(p=>p.tabela==="Wydatki"&&p.wiersz===o.id)
//                                         .map(p=>operacje.pliki.filter(plik=>plik.id===p.idpliku)[0]);
//                         newOp['tabela'] = "Wydatki";
//                         lista.push(newOp);
//                         return true;
//                 })
//         }
        
//         // console.timeEnd("OperMies");
//         return lista;
// }


// pch
// pb
// pk
// wch
// wb
// wk

// #######################################################################################################


export function sumyMiesiacIpos(operacje) {
  
  let ipos={operacji:0,liczba:0,kwota:0,karta_operacji:0,karta_liczba:0,karta_kwota:0,noclegi:{},sprzedaz:{}};

  operacje.map((o) => {
    let kat = o.kat === "Noclegi" ? "noclegi" : "sprzedaz";

    if (o.platnosc === "karta" || o.platnosc === "przelew") {
      ipos["karta_operacji"] += 1;
      ipos["karta_liczba"] += o.liczba;
      ipos["karta_kwota"] += o.oplata;
    } else {
      ipos["operacji"] += 1;
      ipos["liczba"] += o.liczba;
      ipos["kwota"] += o.oplata;
    }

    // sumy w kategoriach - utworzenie zmiennej:
    ipos[kat][o.nazwa]||(ipos[kat][o.nazwa]={name:o.nazwa,operacji:0,liczba:0,kwota:0,karta_operacji:0,karta_liczba:0,karta_kwota:0});
    
    if (o.platnosc === "karta" || o.platnosc === "przelew") {
      ipos[kat][o.nazwa]["karta_operacji"] += 1;
      ipos[kat][o.nazwa]["karta_liczba"] += o.liczba;
      ipos[kat][o.nazwa]["karta_kwota"] += o.oplata;
    } else {
      ipos[kat][o.nazwa]["operacji"] += 1;
      ipos[kat][o.nazwa]["liczba"] += o.liczba;
      ipos[kat][o.nazwa]["kwota"] += o.oplata;
    }
    return true;
  });

  let noclegi = Object.keys(ipos.noclegi).map(o=>{
    return { name: o, ...ipos.noclegi[o] }
  })
  let sprzedaz = Object.keys(ipos.sprzedaz).map(o=>{
    return { name: o, ...ipos.sprzedaz[o] }
  })
  ipos.noclegi = noclegi;
  ipos.sprzedaz = sprzedaz;

  return ipos;
}



// ####################################################################################################
export function sumyChataPrzychody(operacje,okres) {

        // console.time("ipos-"+okres);
        let sumy = { operacji:0,suma:0,ksiegowe:0,
                iposN:{operacji:0,liczba:0,kwota:0,karta_operacji:0,karta_liczba:0,karta_kwota:0},
                iposS:{operacji:0,liczba:0,kwota:0,karta_operacji:0,karta_liczba:0,karta_kwota:0},
                noclegi:{wizyt:0,noclegow:0,kwota:0},
                rk:{operacji:0,kwota:0},
                uzup:0,plik:0,czyUzup:0,czyPlik:0,przeplywy:0 
        }
        
        // Podsumowanie noclegów w IPOS
        operacje.ipos
                .filter( o=>dayjs(o.czas).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres) )
                .map(o=>{
                        // console.log(o);
                        if (o.kat==="Noclegi") {
                                if (o.platnosc === "karta" || o.platnosc === "przelew") {
                                  sumy['iposN']["karta_operacji"] += 1;
                                  sumy['iposN']["karta_liczba"] += o.liczba;
                                  sumy['iposN']["karta_kwota"] += o.oplata;
                                } else { // gotówką w Chacie:
                                  sumy['iposN']["operacji"] += 1;
                                  sumy['iposN']["liczba"] += o.liczba;
                                  sumy['iposN']["kwota"] += o.oplata;
                                  
                                  // sumy['suma'] => jest liczona niżej - przy Noclegach...
                                  sumy['ksiegowe'] += o.oplata;
                                }
                        } else { // Sprzedaż:
                                if (o.platnosc === "karta" || o.platnosc === "przelew") {
                                  sumy['iposS']["karta_operacji"] += 1;
                                  sumy['iposS']["karta_liczba"] += o.liczba;
                                  sumy['iposS']["karta_kwota"] += o.oplata;
                                } else { // gotówką w Chacie:
                                  sumy['iposS']["operacji"] += 1;
                                  sumy['iposS']["liczba"] += o.liczba;
                                  sumy['iposS']["kwota"] += o.oplata;
                                  
                                  sumy['suma'] += o.oplata;
                                  sumy['ksiegowe'] += o.oplata;
                                }
                        }
                        // do sumy ogólnej przychodów w Chacie liczymy tylko płatności gotówką.
                        // dla noclegów - najpierw porównamy kwotę w IPOS z imiennymi i wybierzemy wiekszą - poniżej
                        // do sumy księgowej nie bierzemy wogóle kwot kartą, bo one trafiają na konto oficjalne i tam są sumą księgową
                        return true;
                });
        sumy['operacji'] += 2;
        // console.timeEnd("ipos-"+okres);

        // console.time("skarb-"+okres);
        // wcześniej gadżety a od 2023 roku - Skarbonka (w raporcie kasowym):
        operacje.przychody
                .filter( o=>dayjs(o.data).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres) )
                .map(o=>{
                        sumy['rk']['operacji'] +=1;
                        sumy['rk']['kwota'] += o.kwota;
                        sumy['suma'] += o.kwota;
                        return true;
                });
        sumy['operacji'] += 1;

        // Podsumowanie noclegów imiennych

        // pobieramy wartość sumy noclegów wpisana do raportu kasowego:
        let RapKas = operacje.przychody.filter(o=>dayjs(o.data).format("YYYY-MM")===String(okres) && o.gadzet==="RapKas");
        RapKas = RapKas.length>0 && RapKas[0].info;
        if (RapKas && typeof RapKas === 'string') { RapKas = JSON.parse(RapKas); }

        // console.timeEnd("skarb-"+okres);

        // console.time("nocki-"+okres);

        operacje.noclegi
                .filter( o=>dayjs(o.dataod).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres) && o.datado )
                .map(nocleg=>{
                        // console.time("noc-"+nocleg.id);
                        let o = policzNocleg(nocleg);
                        // console.timeEnd("noc-"+nocleg.id);
                        sumy['noclegi']['wizyt'] += 1;
                        sumy['noclegi']['noclegow'] += o.nocy;
                        sumy['noclegi']['kwota'] += o.pobrano;
                        return true;
                });
        // ponizej dodajemy sumy w zalezności od roku:
        // do końca 2022 roku - sumy noclegów imiennych - później sumy z IPOS:
        // sumy['suma'] += ( okres>2022 || Number(dayjs(okres).format("YYYY"))>2022 ) ? sumy['iposN']["kwota"] : sumy['noclegi']['kwota'];
        // console.log(Number(okres),Number(dayjs(okres).format("YYYY")));

        // jesli w raporcie kasowym jest wpisana ręcznie suma i jest większa od rejestrowanych noclegów 
        // to poniżej to sprawdzamy i wybieramy większa kwotę:
        // console.time("math-"+okres);
        sumy['noclegi']['kwota'] = Math.max(...[
                sumy['noclegi']['kwota'],
                RapKas ? Number(RapKas.noclegi) : 0,
        ]);
        // console.timeEnd("math-"+okres);
        // console.time("math2-"+okres);
        // ponizej:
        // porównujemy sumę z IPOS, raport kasowy i sumą noclegów imiennych i bierzemy do sumy ogólnej kwotę większą.
        // sumy['suma'] += ( sumy['iposN']["kwota"] > sumy['noclegi']['kwota'] ) ? sumy['iposN']["kwota"] : sumy['noclegi']['kwota'];
        sumy['suma'] += Math.max(...[
                sumy['iposN']["kwota"],
                sumy['noclegi']['kwota'],
                RapKas ? Number(RapKas.noclegi||0) : 0,
        ]);
        // console.timeEnd("math2-"+okres);

        sumy['operacji'] += 1;

        // console.timeEnd("nocki-"+okres);
        return sumy;
}





export function sumyChataWydatki(operacje,okres) {

        // console.log(okres,okres.length);
        // console.time("wydatki-"+okres);
        let sumy = { operacji:0,suma:0,sumafull:0,ksiegowe:0,chata:0,uzup:0,plik:0,czyUzup:0,czyPlik:0,przeplywy:0 }
        operacje.wydatki
                .filter(o=>dayjs(o.data).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres))
                .map(o=>{
                        // console.log(o);
                        sumy['operacji'] += 1;

                        let s = [];
                        s = operacje.uzupelnienia.filter(u=>u.tabela==="Wydatki"&&u.wiersz===o.id);
                        sumy['uzup'] += s.length;
                        if (s.length>0) { sumy['czyUzup'] += 1; }
                        if (s.filter(i=>i.wybor==="koszt").length>0) { sumy['ksiegowe'] += Number(o.kwota); }
                        if (s.filter(i=>i.wybor==="przepływy").length>0) { sumy['przeplywy'] += 1; } // liczba niesumowanych przepływów
                        if (o.zobowiazanie<5 && s.filter(i=>i.wybor==="przepływy").length===0) { sumy['suma'] += Number(o.kwota); } // w podsumowaniach nie liczone przepływy
                        if (o.zobowiazanie===0) { sumy['chata'] += Number(o.kwota); } // w chacie sumowane realne wydatki Gospodarza
                        
                        let p = operacje.pliki_polaczenia.filter(i=>i.tabela==="Wydatki"&&i.wiersz===o.id).length;
                        sumy['plik'] += p;
                        sumy['czyPlik'] += p>0 ? 1 : 0;
                        return true;
                });
        // console.timeEnd("wydatki-"+okres);
        return sumy;
}





export function sumyBanki(operacje,okres,typ) {

        // console.log(okres,okres.length);
        // console.time("banki-"+okres+"-"+typ);
        let sumy = { operacji:0,suma:0,ksiegowe:0,uzup:0,plik:0,czyUzup:0,czyPlik:0,przeplywy:0 }
        let lista = [];

        // filtrujemy operacje bankowe ze względu na typ:
        if (typ === "bankW") {
                lista = operacje.banki
                        .filter(o=>dayjs(o.data).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres) 
                        && (o.bank==="mBankGl"||o.bank==="VWBank") && o.typ===2 )
        }
        if (typ === "bankP") {
                lista = operacje.banki
                        .filter(o=>dayjs(o.data).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres) 
                        && (o.bank==="mBankGl"||o.bank==="VWBank") && o.typ===1 )
        }
        if (typ === "kasaW") {
                lista = operacje.banki
                        .filter(o=>dayjs(o.data).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres) 
                        && (o.bank==="mBank"||o.bank==="kasa") && o.typ===2 )
        }
        if (typ === "kasaP") {
                lista = operacje.banki
                        .filter(o=>dayjs(o.data).format( String(okres).length===7 ? "YYYY-MM" : "YYYY")===String(okres) 
                        && (o.bank==="mBank"||o.bank==="kasa") && o.typ===1 )
        }
        
        lista.map(o=>{
                // console.log(o);
                sumy['operacji'] += 1;

                let s = operacje.uzupelnienia.filter(u=>u.tabela==="Banki"&&u.wiersz===o.id);
                sumy['uzup'] += s.length;
                if (s.length>0) { sumy['czyUzup'] += 1; }
                if ((typ==="bankW"||typ==="bankP")&&s.filter(i=>i.wybor==="przepływy").length===0) { sumy['ksiegowe'] += Number(o.kwota); } // w banku zawsze każda operacja JEST księgowa
                if (s.filter(i=>i.wybor==="przepływy").length>0) { sumy['przeplywy'] += 1; } // liczba niesumowanych przepływów
                if (s.filter(i=>i.wybor==="przepływy").length===0) { sumy['suma'] += Number(o.kwota); } // w podsumowaniach nie liczone przepływy
                
                let p = operacje.pliki_polaczenia.filter(i=>i.tabela==="Banki"&&i.wiersz===o.id).length;
                sumy['plik'] += p;
                sumy['czyPlik'] += p>0 ? 1 : 0;
                return true;
        });
        // console.timeEnd("banki-"+okres+"-"+typ);
        return sumy;
}





// utworzenie struktury podsumowań - mapa funkcji:
// export function sumy(dane) {
//         let sumy = [];
//         for (let year = 2012; year <= dayjs().format("YYYY"); year++) {
//                 let sumyMiesiace = [];
//                 for (let month = 1; month <= 12; month++) {
//                         let miesiac = month<10 ? year+'-0'+month : year+"-"+month;
//                         sumyMiesiace.push({
//                                 miesiac: miesiac,
//                                 wydatki: sumyChataWydatki(dane,miesiac),
//                                 przychody: sumyChataPrzychody(dane,miesiac),
//                                 bankW: sumyBanki(dane,miesiac,"bankW"),
//                                 bankP: sumyBanki(dane,miesiac,"bankP"),
//                                 kasaW: sumyBanki(dane,miesiac,"kasaW"),
//                                 kasaP: sumyBanki(dane,miesiac,"kasaP"),
//                         })
//                 }
//                 sumy.push({
//                         rok: year,
//                         wydatki: sumyChataWydatki(dane,year),
//                         przychody: sumyChataPrzychody(dane,year),
//                         bankW: sumyBanki(dane,year,"bankW"),
//                         bankP: sumyBanki(dane,year,"bankP"),
//                         kasaW: sumyBanki(dane,year,"kasaW"),
//                         kasaP: sumyBanki(dane,year,"kasaP"),
//                         miesiace: sumyMiesiace,
//                 });
//         }
//         return sumy;
// }

export function sumyLatamiMiesiacami(dane) {
        // console.log(dane);
        let sumyMiesieczne = [];
        let sumyRoczne = [];
        for (let year = 2020; year <= dayjs().format("YYYY"); year++) {
                
                let rok = {rok:year,
                        wydatki:{operacji:0,suma:0,ksiegowe:0},
                        przychody:{operacji:0,suma:0,ksiegowe:0},
                        bankW:{operacji:0,suma:0,ksiegowe:0},
                        bankP:{operacji:0,suma:0,ksiegowe:0},
                        kasaW:{operacji:0,suma:0,ksiegowe:0},
                        kasaP:{operacji:0,suma:0,ksiegowe:0}
                }
                for (let month = 1; month <= 12; month++) {
                        let miesiac = month<10 ? year+'-0'+month : year+"-"+month;
                        let sumaMies = {
                                miesiac: miesiac,
                                wydatki:        sumyChataWydatki(dane,miesiac),
                                przychody:      sumyChataPrzychody(dane,miesiac),
                                bankW:          dane.banki ? sumyBanki(dane,miesiac,"bankW") : 0,
                                bankP:          dane.banki ? sumyBanki(dane,miesiac,"bankP") : 0,
                                kasaW:          dane.banki ? sumyBanki(dane,miesiac,"kasaW") : 0,
                                kasaP:          dane.banki ? sumyBanki(dane,miesiac,"kasaP") : 0,
                        }
                        sumyMiesieczne.push(sumaMies);
                        rok.wydatki.operacji += sumaMies.wydatki.operacji
                        rok.wydatki.suma += sumaMies.wydatki.suma
                        rok.wydatki.ksiegowe += sumaMies.wydatki.ksiegowe
                        rok.przychody.operacji += sumaMies.przychody.operacji
                        rok.przychody.suma += sumaMies.przychody.suma
                        rok.przychody.ksiegowe += sumaMies.przychody.ksiegowe
                        rok.bankW.operacji += sumaMies.bankW.operacji
                        rok.bankW.suma += sumaMies.bankW.suma
                        rok.bankW.ksiegowe += sumaMies.bankW.ksiegowe
                        rok.bankP.operacji += sumaMies.bankP.operacji
                        rok.bankP.suma += sumaMies.bankP.suma
                        rok.bankP.ksiegowe += sumaMies.bankP.ksiegowe
                        rok.kasaW.operacji += sumaMies.kasaW.operacji
                        rok.kasaW.suma += sumaMies.kasaW.suma
                        rok.kasaW.ksiegowe += sumaMies.kasaW.ksiegowe
                        rok.kasaP.operacji += sumaMies.kasaP.operacji
                        rok.kasaP.suma += sumaMies.kasaP.suma
                        rok.kasaP.ksiegowe += sumaMies.kasaP.ksiegowe
                }
                sumyRoczne.push(rok);
        }
        return {sumyMiesieczne,sumyRoczne};
}

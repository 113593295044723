import { createTheme } from "@mui/material/styles";
import { green, grey } from "@mui/material/colors";
import { plPL as corePlPL } from '@mui/material/locale';
// import { plPL as dataGridPlPL } from '@mui/x-data-grid';
import { plPL as pickerPlPL } from '@mui/x-date-pickers/locales';

let theme = createTheme(
  {
    palette: {
      primary: {
        main: green[800],
      },
      neutral: {
        main: grey[800],
        contrastText: '#fff',
      },
      jasnyszary: {
        main: grey[200],
        contrastText: grey[700],
      },
      jasnyzielony: {
        main: green[200],
        contrastText: green[800],
      },
    },
  },
  corePlPL,
  // dataGridPlPL,
  pickerPlPL,
);

theme = createTheme(theme,{
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          "&& .Mui-selected": {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
          },
          "&& .Mui-selected:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },

    // 
    // MUIDataTableHeadCell-toolButton MUIDataTableHeadCell-fixedHeader
    // MuiButtonBase-root-MuiButton-root MuiTableCell
    // ustawienia formularza filtrów DataGrid
    // nie wiedzieć czemu część definicji musi być tu a część przy DataGrid :(
    MuiDataGrid: {
      styleOverrides: {
        filterForm: {
          display: "block"
        },
        virtualScrollerRenderZone: {
          width: '100%'
        }
      }
    },
    MuiFormControl: { // szerokość pola wyboru w formularzu filtrów DataGrid
      styleOverrides: {
        root: {
          display: "block",
          marginTop: "0px",
        },
      }
    },

    MuiOutlinedInput: { // szerokość pól 'outlined'
      styleOverrides: {
        root: {
          width: "100%",
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "5px"
        }
      }
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },

    MUIDataTableHeadCell: { // formatowanie buttona w nagłówku mui-datatables
      styleOverrides: {
        root: {
          // '& .MuiButtonBase-root': {
          //   padding: 0,
          // },
          paddingLeft: "1em",
        }
      }
    },
    MUIDataTableHead: { // formatowanie buttona w nagłówku mui-datatables
      styleOverrides: {
        responsiveStacked: {
          display: 'contents',
        }
      }
    },
    MUIDataTableToolbar: { // formatowanie buttona w nagłówku mui-datatables
      styleOverrides: {
        actions: {
          textAlign: 'right',
        }
      }
    },

  },
});

export default theme;

import * as React from 'react';
import { Link, Typography } from '@mui/material';
import OdwiedzinyFormularz from './OdwiedzinyFormularz';
import { getSumyGrupowe, transGrupa } from '../komponenty/labelki';
import { useQuery } from '@tanstack/react-query';
import { fetchDane, 
  // fetchJUser, fetchOperacje 
} from '../api';

function GrupaSzczegoly(props) {

        // const cred = useQuery(['JUser'], fetchJUser);
        const cred = useQuery(['dane','cred'], fetchDane);
        let isEditor = false
        let isViewer = false
        let isOwner = false
        if (cred.isSuccess) { 
          if (cred.data.grupa==="GOSP") isEditor = true
          if (cred.data.grupa==="RADA") isViewer = true
          if (cred.data.userID===props.grupa.created_by) isOwner = true
        }

        // const dane = useQuery(['dane','odwiedziny',{id: props.grupa.id}], fetchDane, {enabled: false});
        // let grupa = dane.isSuccess && transGrupa(dane.data.odwiedziny[0]);
      
        const grupa = transGrupa(props.grupa)
        // const noclegi = props.noclegi;
        // const operacje = useQuery(['operacje'], fetchOperacje);
        const nocki = useQuery(['dane','noclegi'], fetchDane);
        const noclegi = nocki.isSuccess && nocki.data.noclegi;
        let sumyGrupowe = {kwota: 0, klimat: 0, noclegow: 0}
        // // console.log(grupa);
  
        if (noclegi) {
        sumyGrupowe = getSumyGrupowe(noclegi.filter(n=>n&&n.gid===grupa.gid));
        }

  return (
    <>
            <Typography variant="body1">{grupa.info}</Typography>
            <hr/>
            { (isViewer||isEditor) && <><Typography variant="body1">
                status: <b>{grupa.state_label}</b><br/>
                decyzja: <b>{grupa.noclegchata_label}</b><br/>
                główna kategoria noclegów: <b>{grupa.kategoria ? grupa.kategoria_label : "nie wybrana..."}</b><br/>
                zarejestrowanych osób: <b>{sumyGrupowe.noclegow}</b><br/>
                suma opłat w grupie: <b>{sumyGrupowe.kwota}</b>,<br/>
                opłaty klimatyczne: <b>{sumyGrupowe.klimat}</b> 
            </Typography> 
            <hr/></>}
            <Typography variant="body2" sx={{ textAlign: "right", fontStyle: "italic" }}>
                zgłoszenie nr <b>{grupa.gid}</b> dodane: {grupa.created}<br/>
                { (grupa.owner) && <>przez: {grupa.owner.name} - login: {grupa.owner.username}<br/>
                e-mail: <b><Link underline="hover" color="primary" href={grupa.mailto}>{grupa.owner.email}</Link></b>, 
                  tel.: <b>{grupa.owner.tel}</b><br/>
                  { (isViewer||isEditor) && <small>
                    IP: {grupa.ip}<br/>
                    { grupa.ua && grupa.ua.device.vendor && <>urządzenie: {grupa.ua.device.vendor} {grupa.ua.device.model}<br/></> }
                    { grupa.ua && grupa.ua.browser.name && <>przeglądarka: {grupa.ua.browser.name} {grupa.ua.browser.major}, {grupa.ua.os.name} {grupa.ua.os.version}</> }
                    <br/>
                  </small> } 
                </> }
                ostatnio modyfikowane: {grupa.modified}<br/>
                { (isViewer||isEditor) && <>przez: {grupa.modifier.name}<br/></> }
            </Typography>
            { (isViewer||isEditor||isOwner) && <OdwiedzinyFormularz row={grupa} resetujGrupe={props.resetujGrupe} /> }
    </>
  );
}

export default GrupaSzczegoly;
// ||isOwner
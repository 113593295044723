import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Badge, Box, DialogContent, Fab, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { fetchDane } from '../api';
import { useQuery } from '@tanstack/react-query';
import FinanseTab3 from '../finanse/FinanseTab3';

export default function ButtonPliki(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const operacje = useQuery(['dane','pliki_polaczenia'], fetchDane);
  const pliki = operacje.isSuccess && operacje.data.pliki_polaczenia.filter(u=>u&&(u.tabela==="Wydatki" && u.wiersz===props.wydatek.id));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
        setOpen(false);
  };

  // console.log(props);
  // button edycji plików - widoczny w szczegółach listy wydatków (WydatkiLista)
  return (
    <React.Fragment>
      <Fab
            color="primary"
            aria-label="edit"
            sx={props.pozycja}
            onClick={handleClickOpen}
            size="small"
            disabled={props.disabled}
      >
        <Badge anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} 
            sx={{ p: '5px' }}
            badgeContent={pliki?pliki.length:0}
            color="secondary"
        >
              <AttachFileIcon />
        </Badge>
      </Fab>



      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Fab
            size="small"
            aria-label="zamknij"
            sx={{ position: "absolute", top: "1em", right: "1em" }}
            onClick={handleClose}
          >
            <CloseOutlinedIcon />
          </Fab>
          Załączniki:
        </DialogTitle>

        <DialogContent>
          <Box sx={{ width: "100%" }}>
                {props.wydatek.id}. {props.wydatek.tytul}<br/>
                {props.wydatek.uwagi&&<>{props.wydatek.uwagi}<br/></>}
                kwota: {props.wydatek.kwota} zł<br/>
                <hr/>
                <FinanseTab3 
                        dane={{ tabela: "Wydatki", id: props.wydatek.id }} 
                        // setOpen={setOpen} 
                />
          </Box>
        </DialogContent>

      </Dialog>
    </React.Fragment>
    )
}

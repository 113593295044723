import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { 
  fetchDane,
  deleteObiekt, 
} from '../api';

// import { Alert, CircularProgress } from '@mui/material';
// import { serialize } from 'object-to-formdata';
/**
 * 
 * @param {*} props 
 * @returns 
 * 
 * TODO: 
 * + zrobić form dla plików
 * - zrobić usuwanie pliku
 * 
 * - dorobić podgląd pliku
 * 
 */

export default function ButtonDeleteUzupelnienie(props) {

  const [openDial, setOpenDial] = React.useState(false);
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const operacje = useQuery(['dane'], fetchDane);
  const queryClient = useQueryClient();
  
  const delUzupelnienie = useMutation(uz => deleteObiekt('dane',uz), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      // console.log(data,operacje.data.uzupelnienia);
      let newLista = operacje.data.uzupelnienia.filter((o) => o.id !== Number(data.data));
      let newOper = { 
        ...operacje.data, 
        uzupelnienia: newLista,
      };
      queryClient.setQueryData(['dane'], newOper);
      setOpenDial(false);
    },
  });

  const handleConfirm = () => {
    delUzupelnienie.mutate(props.obiekt);
  }


  return (
    <React.Fragment>
      <Button variant="link" onClick={()=>setOpenDial(true)}>
        <DeleteForeverIcon color="error" />
      </Button>

      <Dialog
        open={openDial}
        onClose={()=>setOpenDial(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Czy aby na pewno???</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Żądasz TRWAŁEGO usunięcia zasobu.
            Nie będzie można cofnąć tej decyzji.
            Czy na pewno chcesz to zrobić?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            sx={{ position: "absolute", left: "1em" }}
          >
            Tak!!!
          </Button>
          <Button onClick={()=>setOpenDial(false)} autoFocus>
            Przemyślę...
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

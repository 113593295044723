import * as React from 'react';
import Switch from '@mui/material/Switch';
import { Box, FormControlLabel, FormGroup, FormHelperText, Stack } from '@mui/material';

const CFButtonSwitch = React.forwardRef((props, ref) => {

  // console.log("props BS:", props);
  const [checked, setChecked] = React.useState((props.value==="1"||props.value===1||props.value==="true"||props.value===true)?true:false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onChange(event.target.checked);
  };

  React.useEffect(() => {
    setChecked((props.value==="1"||props.value===1||props.value==="true"||props.value===true)?true:false);
  }, [props.value]);

  return (
    <Box sx={{ margin: '1em' }}>
    <Stack maxWidth={props.width ? props.width : null}>
      <FormGroup>
      <FormControlLabel control={<Switch
          ref={ref}
          checked={checked}
          value={checked}
          color={props.color}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />} 
      label={props.label} 
      />
    {props.legenda && <FormHelperText sx={{ml: '14px'}} className='MuiFormHelperText-contained'>{props.legenda}</FormHelperText>}
    {props.error && <FormHelperText sx={{ml: '14px'}} className='Mui-error MuiFormHelperText-contained'>{props.error.message}</FormHelperText>}
    </FormGroup>
    </Stack>
    </Box>
  );
});

export default CFButtonSwitch;
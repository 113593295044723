import dayjs from 'dayjs';
import { parseUserAgent } from 'react-device-detect';
import { useQuery } from '@tanstack/react-query';
import { validatePolish } from 'validate-polish';
import { fetchDane } from '../api';

export const Chata = () => {
  // const cred = useQuery(['JUser'], fetchJUser);
  const cred = useQuery(['dane','cred'], fetchDane);
  // console.log(cred);
  let chata = false;
  if ( cred.isSuccess && (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA" )) {
    chata = true;
  }
  return chata;
};

export const parseHTML = (html) => {
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
};

export const opcjeLista = (tablica=[],kolumna="",warunek=null) => {
  // console.log(tablica)
  let lista = [];
  if(warunek) { // warunek na razie ma zastosowanie jedynie w Uzupełnieniach - AutocompleteRemote
    tablica.map(e=>{
      if (e[warunek.key]===warunek.val) {
        lista[e[kolumna]] =+ 1;
      }    
      return true;
    });
  } else { // głównie do utworzenia listy imion i nazwisk w NoclegFormularz
    tablica.map(e=>{
          lista[parseHTML(e[kolumna])] =+ 1;
          return true;
    });
  }
  let klucze = Object.keys(lista).sort();
  // console.log(klucze)
  return klucze;
}

// funkcja parsowania numeru PESEL ściągnięta z netu.
export function PeselDecode(pesel) 
{
    if (pesel===null) { pesel = "" }
    //Sprawdź, czy wszystkie znaki to cyfry
    var cyfry = [];
    for (let i=0;i<11; i++)
    {
      cyfry[i] = parseInt(pesel.substring(i,i+1));
    }

    //Policz rok z uwzględnieniem XIX, XXI, XXII i XXIII wieku
    var rok = 1900+cyfry[0]*10+cyfry[1];
    if (cyfry[2]>=2 && cyfry[2]<8)
      rok+=Math.floor(cyfry[2]/2)*100;
    if (cyfry[2]>=8)
      rok-=100;

    var miesiac = (cyfry[2]%2)*10+cyfry[3];
    var dzien = cyfry[4]*10+cyfry[5];

    // Zdefiniowanie zmiennych wynikowych:
    var urodzony = dayjs(rok+"-"+miesiac+"-"+dzien).format("YYYY-MM-DD");
    var plec = (cyfry[9]%2===1)?"M":"K";
    var valid = validatePolish.pesel(pesel);

    return {valid:valid,sex:plec,date:urodzony};
}


// przeliczenie informacji o Osobie
export function transOsoba(o) {

  // console.log(o)
  // o.oid = o.id; delete o.id;
  if (!o.PESEL) { o.PESEL="" }
  if (!o.hasOwnProperty('oinfo')) { o.oinfo = o.info?o.info:{}; } // jeśli nie istnieje oinfo to stworz obiekt, a jeśli istnieje to sparsuj string na obiekt:
  if (o.oinfo && typeof o.oinfo === 'string') { o.oinfo = JSON.parse(o.oinfo); }

  if ( o.PESEL && o.PESEL.length > 5) {
    o.validPESEL = PeselDecode(o.PESEL).valid;
    o.dataur = PeselDecode(o.PESEL).valid 
        ? PeselDecode(o.PESEL).date
        : (o.oinfo.dataur ? o.oinfo.dataur : PeselDecode(o.PESEL).date);
  } else if (o.oinfo && o.oinfo.dataur) {
        o.dataur = dayjs(o.oinfo.dataur).format("YYYY-MM-DD");
  } else if (dayjs(o.dataur).isValid()) {
        o.oinfo.dataur = o.dataur;
  } else {
    o.dataur = "";
  }

  if (dayjs(o.dataur).isValid()) {
    o.wiekdzis = dayjs().diff(o.dataur, "y");
  } else {
    o.wiekdzis = "nieokreślony";
  }
  o.imie = parseHTML(o.imie);
  o.nazwisko = parseHTML(o.nazwisko);

  let funkcje = [];
  if (o.funkcje) {
    o.funkcje.forEach( (e,i) => {
      funkcje[i] = transFunkcja(e);
      if (Number(e.funkcja)===5) { o.kategoria = "7" }
      if (Number(e.funkcja)===1 && e.datastop==="2100-01-01") { o.kategoria = "5" }
    });
  }
  o.funkcje = funkcje;

  // console.log(o)
  return o;
}

export function policzNocleg(nocleg) {

  let n = nocleg;
  if (nocleg.hasOwnProperty('dataod')) { // transformacja surowych danych prosto z tabeli pobranej z API
    n.nid = Number(n.id);
    n.gid = Number(n.gid);
    n.od = n.od ? n.od : n.dataod; delete n.dataod;
    n.do = n.do ? n.do : n.datado; delete n.datado;
    n.ninfo = n.info; delete n.info;
    if (n.ninfo && typeof n.ninfo === 'string') { n.ninfo = JSON.parse(n.ninfo); }
    n.klimat_zw = n.klimat_zw ? n.klimat_zw : n.klimat_zwolnieni; delete n.klimat_zwolnieni;
    n.nuwagi = n.ninfo&&n.ninfo.uwagi;
  }

  n.nocy = dayjs(n.do).diff(dayjs(n.od), 'days');
  n.oklimat = (( n.nocy - 1 ) * 1.6).toFixed(2);
  
  n.standard = (Number(n.rodzaj)>0)?false:true;
  // n.oplata = n.oplata?n.oplata:undefined;
  n.kwota = 0;
  n.wyliczona = oplata(n.kategoria,n.od,n.nocy);

  if ( Number(n.rodzaj)===1 ) { n.kwota = Number(n.oplata?n.oplata:0)+Number(n.karta?n.karta:0) }
  else if ( Number(n.rodzaj)>1 ) { n.kwota = Number(n.oplata?n.oplata:0) }
  else { n.kwota = n.wyliczona }

  let pobrano = 0;
  if (n.standard) { 
    n.rodzaj = 0;
    pobrano = n.wyliczona; 
    if ( Number(n.kategoria)===7 ) { 
      n.kwota = Number(n.oplata?n.oplata:0);
      pobrano = Number(n.oplata?n.oplata:0); 
    }
  } else { 
    if (n.rodzaj===1) { pobrano = (Number(n.karta??0)+Number(n.oplata??0)) }
    else if (n.rodzaj===5) { pobrano = 0 }
    else { pobrano = n.oplata??0 }
  }
  // if (!n.klimat_zw) { pobrano += Number(n.oklimat)??0 } // dopisanie klimatycznej do opłaty, ale w przypadku płatności kartą to bez sensu...
  n.pobrano = pobrano;

  if (n.dataur && dayjs(n.dataur).isValid()) { n.wieknoc = dayjs(n.od).diff(n.dataur, 'y'); } else { n.wieknoc = "niewiadomy" }
  
  if (n.dataur && dayjs(n.dataur).isValid()) { n.wiekdzis = dayjs().diff(n.dataur, 'y'); } else { n.wiekdzis = "niewiadomy" }
  // console.log(n);
  return n;
}





// przeliczenie informacji o Noclegu
export function transNocleg(nocleg) {

  let domyslne = {};
  domyslne.id = "nowy";
  domyslne.standard = true;
  domyslne.rodzaj = 0;
  // domyslne.bank = 0;
  // console.log(nocleg);
  let n = {...domyslne, ...policzNocleg(nocleg)};
  
  // if (nocleg.hasOwnProperty('dataod')) { // transformacja surowych danych prosto z tabeli pobranej z API
  //   n.nid = Number(n.id);
  //   n.gid = Number(n.gid);
  //   n.od = n.dataod; delete n.dataod;
  //   n.do = n.datado; delete n.datado;
  //   n.ninfo = n.info; delete n.info;
  //   if (n.ninfo && typeof n.ninfo === 'string') { n.ninfo = JSON.parse(n.ninfo); }
  //   n.klimat_zw = n.klimat_zwolnieni; delete n.klimat_zwolnieni;
  //   n.nuwagi = n.ninfo&&n.ninfo.uwagi;
  // }

    // if (!nocleg.osoba.hasOwnProperty('id')) { // jeśli dodajemy nową osobę, niewybraną z listy
    // }
    n.osoba = {};
    n.osoba.id = nocleg.idosoby ? nocleg.idosoby : "nowa";
    n.osoba.imie = nocleg.imie;
    n.osoba.nazwisko = nocleg.nazwisko;
    n.osoba.PESEL = nocleg.PESEL;
    n.osoba.dataur = nocleg.dataur;
    n.osoba.panstwo = nocleg.panstwo;

    n.osoba = n.osoba && transOsoba(n.osoba);

  // n.nocy = dayjs(n.do).diff(dayjs(n.od), 'days');
  // n.oklimat = (( n.nocy - 1 ) * 1.6).toFixed(2);
  // n.standard = (n.standard===true||n.rodzaj===""||n.rodzaj===0)?true:false;
  // n.standard = (n.standard||n.rodzaj===""||n.rodzaj===0)?true:false;
  // n.standard = (n.rodzaj>0)?false:true;
  // n.oplata = n.oplata?n.oplata:undefined;
  // n.kwota = 0;
  // n.wyliczona = oplata(n.kategoria,n.od,n.nocy);

  // if ( Number(n.rodzaj)===1 ) { n.kwota = Number(n.oplata?n.oplata:0)+Number(n.karta?n.karta:0) }
  // else if ( Number(n.rodzaj)>1 ) { n.kwota = Number(n.oplata?n.oplata:0) }
  // else { n.kwota = n.wyliczona }
  // if ( Number(n.kategoria)===7 ) { n.kwota = Number(n.oplata?n.oplata:0) }

  // kwota = pobrana do kasy Chaty, klimatyczna jest kasą Gospodarza...
  // if (!n.klimat_zw) { n.kwota = Number(n.kwota) + Number(n.oklimat) } 

  // if (n.osoba && dayjs(n.osoba.dataur).isValid() && n.od) { n.wieknoc = dayjs(n.od).diff(n.osoba.dataur, 'y'); } 
  // else { n.wieknoc = "niewiadomy" }

  // if (n.osoba && dayjs(n.osoba.dataur).isValid()) { n.wiekdzis = dayjs().diff(n.osoba.dataur, 'y'); } 
  // else { n.wiekdzis = "niewiadomy" }

  // n.wieknoc = n.wieknoc === "niewiadomy" ? n.wiekdzis : n.wieknoc;

  // if (n.osoba) { 
  //   let osoba = transOsoba(n.osoba);
  //   if (osoba.kategoria) { n.kategoria = osoba.kategoria }
  // }
  n.kategoria_label = kategoriaLabel(n.kategoria);
  n.rodzaj_label = rodzajLabel(n.rodzaj);
  if ( n.ninfo && n.ninfo.uwagi ) { n.nuwagi = n.ninfo.uwagi }
  if ( n.ninfo && n.ninfo.karta ) { n.karta = n.ninfo.karta }

  // wyliczenie pobranych opłat - w szczegółach noclegu:
  // let pobrano = 0;
  // if (n.standard) { 
  //   n.rodzaj = 0;
  //   pobrano = n.wyliczona; 
  // }
  // else { 
  //   if (n.rodzaj===1) { pobrano = (Number(n.karta??0)+Number(n.oplata??0)) }
  //   else if (n.rodzaj===5) { pobrano = 0 }
  //   else { pobrano = n.oplata??0 }
  // }
  // // if (!n.klimat_zw) { pobrano += Number(n.oklimat)??0 } // dopisanie klimatycznej do opłaty, ale w przypadku płatności kartą to bez sensu...
  // n.pobrano = pobrano;

  return n;
}

// transformacja informacji kasowej
export function transKasa(k) {
  // k.info = JSON.parse(k.info);
  // console.log(k);
  return k;
}

// transformacja Wydatku
export function transWydatek(wydatek) {

  let domyslne = {
    id: "nowy",
    data: "", 
    tytul: "",
    uwagi: "",
    nrfaktury: "",
    info: [],
    kwota: "",
    splaconeprzez: "",
    wierzyciel: "",
    wykonawca: "",
    zobowiazanie: 0,
  };
  let w = {...domyslne, ...wydatek};

  w.tabela = "Wydatki";
  if (w.info && typeof w.info === 'string') { w.info = JSON.parse(w.info) }
  if (w.info && w.info.uwagi) { w.uwagi = w.info.uwagi }
  if (w.info && w.info.nrfaktury) { w.nrfaktury = w.info.nrfaktury }


  // console.log(w);
  return w;
}

// przeliczenie informacji o Noclegu
export function getSumyGrupowe(listaNoclegowGrupie) {
  
  // console.log(listaNoclegowGrupie)
  let sumy = {kwota: 0, klimat: 0, noclegow: 0};
  listaNoclegowGrupie.map(n=>{
    n = transNocleg(n);
    sumy.kwota += Number(n.pobrano);
    if (!n.klimat_zw) { sumy.klimat += Number(n.oklimat) }
    return true;
  })
  if (sumy.klimat===0) { sumy.klimat = "brak" }
  sumy.noclegow = listaNoclegowGrupie.length;

  return sumy;
}

// przeliczenie informacji o Grupie
export function transGrupa(grupa) {

  let start = {};
  // start.state = Chata() ? "-3" : "0";
  start.administracyjne = "";
  start.created = "";
  start.created_by = {id: '', name: '', username: '', email: '', tel: ''};
  start.do = null;
  start.id = "nowa";
  start.info = Chata ? "." : "";
  start.ip = "";
  start.kategoria = Chata ? "1" : "";
  start.klient = "";
  start.liczba = Chata ? 1 : undefined;
  start.modified = "";
  start.modified_by = {id: '', name: '', username: ''};
  start.nazwa = "";
  start.noclegchata = Chata ? "1" : "";
  start.od = Chata ? dayjs().format("YYYY-MM-DD") : null;
  start.olali = false;
  start.zapoznalem = Chata ? true : false;
  start.klimat_zw = true;
  // console.log(start);

  let g = {...start, ...grupa};

  g.kategoria_label = kategoriaLabel(g.kategoria);
  g.state_label = stateLabel(g.state);
  g.noclegchata_label = ncLabel(g.noclegchata)
  g.mailto = "mailto:" + g.created_by.email;
  g.gid = grupa ? Number(grupa.id) : start.id;
  if (grupa && grupa.klient) { g.ua = parseUserAgent(grupa.klient); }
  // console.log(g.ua);

  // wartości logiczne:
  if (g.zapoznalem === "1" || g.zapoznalem === 1 || g.zapoznalem === true) { g.zapoznalem = true; } else { g.zapoznalem  = false; }
  if (g.klimat_zw === "1" || g.klimat_zw === 1 || g.klimat_zw === true) { g.klimat_zw  = true; } else { g.klimat_zw   = false; }
  if (g.olali === "1" || g.olali === 1 || g.olali === true) { g.olali      = true; } else { g.olali       = false; }

  // sumy grupowe:
  // console.log(g);

  return g;
}

export const kategoriaOpcje = [
  { value: 1, label: kategoriaLabel(1) },
  { value: 2, label: kategoriaLabel(2) },
  { value: 3, label: kategoriaLabel(3) },
  { value: 9, label: kategoriaLabel(9) },
  { value: 4, label: kategoriaLabel(4) },
  { value: 5, label: kategoriaLabel(5) },
];

export function kategoriaLabel(id) {
  // let labelka = kategoriaOpcje.filter(o=>o.value===Number(id))[0];
  let kat = "";
  if (Number(id)===1) { kat = "Chata"; }
  if (Number(id)===2) { kat = "Student / Emeryt"; }
  if (Number(id)===3) { kat = "Namiot"; }
  if (Number(id)===9) { kat = "Lektorium"; }
  if (Number(id)===4) { kat = "Dziecko do 6 r.ż."; }
  if (Number(id)===5) { kat = "Klub Otrycki"; }

  if (Number(id)===6) { kat = "Gość Gospodarza"; }
  if (Number(id)===8) { kat = "karta / przelew"; }
  if (Number(id)===7) { kat = "Inne"; }

  return kat;
}

export function miesiacLabel(id,small=null) {
  let kat = "";
  if (Number(id)===1)  { kat = "Styczeń"; }
  if (Number(id)===2)  { kat = "Luty"; }
  if (Number(id)===3)  { kat = "Marzec"; }
  if (Number(id)===4)  { kat = "Kwiecień"; }
  if (Number(id)===5)  { kat = "Maj"; }
  if (Number(id)===6)  { kat = "Czerwiec"; }
  if (Number(id)===7)  { kat = "Lipiec"; }
  if (Number(id)===8)  { kat = "Sierpień"; }
  if (Number(id)===9)  { kat = "Wrzesień"; }
  if (Number(id)===10) { kat = "Październik"; }
  if (Number(id)===11) { kat = "Listopad"; }
  if (Number(id)===12) { kat = "Grudzień"; }
  
  return small?kat.toLowerCase():kat;
}

export const rodzajOpcje = [
  { value: 1, label: rodzajLabel(1) },
  { value: 2, label: rodzajLabel(2) },
  { value: 3, label: rodzajLabel(3) },
  { value: 4, label: rodzajLabel(4) },
  { value: 5, label: rodzajLabel(5) },
];

export function rodzajLabel(id) {
  let r = "";
  if (Number(id)===0) { r = "opłata standardowa"; }
  if (Number(id)===1) { r = "karta / przelew"; }
  if (Number(id)===2) { r = "obóz"; }
  if (Number(id)===3) { r = "zwolnienie"; }
  if (Number(id)===4) { r = "nadpłata"; }
  if (Number(id)===5) { r = "Gość Gospodarza"; }
  return r;
}



export function stateLabel(id) {
  let s = "";
  if (Number(id)===0)  {  s = "oczekujące" }
  if (Number(id)===1)  {  s = "zaakceptowane" }
  if (Number(id)===2)  {  s = "anulowane" }
  if (Number(id)===3)  {  s = "do kontaktu" }
  if (Number(id)===-2) { s = "usunięte" }
  if (Number(id)===-3) { s = "dodana przez Gospodarza" }

  return s;
}

export function ncLabel(id) {
  let nc = "";
  if (Number(id)===1)  { nc = "chcę spać w Chacie" }
  if (Number(id)===2)  { nc = "chcę spać we własnym namiocie" }
  
  return nc;
}

export function transFunkcja(f) {
  if (Number(f['funkcja'])===1)   { f['funkcja_label'] = "członek Stowarzyszenia"; f['funkcja_label_short'] = "czł. Stow.";  }
  if (Number(f['funkcja'])===2)   { f['funkcja_label'] = "Rada Otrycka"; f['funkcja_label_short'] = "RO"; }
  if (Number(f['funkcja'])===3)   { f['funkcja_label'] = "Komisja Rewizyjna"; f['funkcja_label_short'] = "KR"; }
  if (Number(f['funkcja'])===4)   { f['funkcja_label'] = "Prezes Stowarzyszenia"; f['funkcja_label_short'] = "Prezes"; }
  if (Number(f['funkcja'])===5)   { f['funkcja_label'] = "Gospodarz Chaty"; f['funkcja_label_short'] = "Gospodarz"; }
  if (Number(f['funkcja'])===99)  { f['funkcja_label'] = "inne"; f['funkcja_label_short'] = "inne"; }
  
  return f;
}


// ################################################################################
// CENNIKi -> wyliczenie należnej opłaty
// ################################################################################

const oplata = (kat,od,nocy) => {

  // stawki początkowe - modyfikowane niżej:
  let cena = {
          kat1: 15, // Chata
          kat2: 10, // Student
          kat3: 10, // Namiot
          kat9: 10, // Lektorium
          kat4: 0,  // Dziecko
          kat5: 0,  // KO
          kat6: 0,  // GG
          kat7: 0,  // Inne
          kat8: 0,  // Bank
  };

  // opłata wg cennika do 2018-06-30
  if ( dayjs(od) < dayjs('2018-07-01') ) {
          return cena['kat'+kat] * nocy;
  }

  // opłata wg cennika od 2018-07-01 do 2019-12-31
  if ( dayjs(od) < dayjs('2020-01-01') ) {

          cena.kat1 = 20;
          cena.kat2 = 15;
          
          if ( nocy>5 && ( kat===1 || kat===2 ) ) {
                  return ( nocy - 5 ) * ( cena['kat'+kat] - 5 ) + ( 5 * cena['kat'+kat] );
          } else {
                  return cena['kat'+kat] * nocy;
          }
  }

  // opłata wg cennika od 2020-01-01 do 2020-04-30
  if ( dayjs(od) < dayjs('2020-05-01') ) {

          cena.kat1 = 25;
          cena.kat2 = 20;
          
          return cena['kat'+kat] * nocy;
  }

  // opłata wg cennika od 2020-05-01 do 2022-10-31
  if ( dayjs(od) < dayjs('2022-11-01') ) {

          cena.kat1 = 25;
          cena.kat2 = 20;
          cena.kat3 = 15;
          cena.kat9 = 15;

          return cena['kat'+kat] * nocy;
  }

  // opłata wg cennika od 2022-11-01 do dzisiaj
  if ( dayjs(od) >= dayjs('2022-11-01') ) {

          cena.kat1 = 30;
          cena.kat2 = 25;

          return cena['kat'+kat] * nocy;
  }
}

// ### ZESTAWIENIA: #############################################################################

export function zestawienie_noclegi(dane,rok,miesiac) {

  let res = {};
  let osoby = [];

  res['id'] = rok+"-"+miesiac+"-N";
  res['tabela'] = "Noclegi";
  res['data'] = rok+"-"+miesiac+"-01";
  res['tytul'] = "zarejestrowane noclegi w Chacie";
  res['sumy'] = {
    odwiedziny: 0,
    noclegi: 0,
    przychody: 0,
  };
  res['kat'] = {
    1: {o:0,n:0,p:0},
    2: {o:0,n:0,p:0},
    3: {o:0,n:0,p:0},
    9: {o:0,n:0,p:0},
    4: {o:0,n:0,p:0},
    5: {o:0,n:0,p:0},
    6: {o:0,n:0,p:0},
    7: {o:0,n:0,p:0},
  };

  dane.map((k,i)=>{
    if ( k.dataod.substr(0,7) === rok+"-"+miesiac ) {
      let n = transNocleg(k)
      // console.log(n);
      let kat = n.kategoria;

      res['kat'][kat]['o'] += 1;
      res['kat'][kat]['n'] += n.nocy;
      res['kat'][kat]['p'] += (n.oplata ? n.oplata : n.kwota);
    
      osoby.push(n.idosoby);
      res['sumy']['odwiedziny'] += 1;
      res['sumy']['noclegi'] += n.nocy;
      res['sumy']['przychody'] += (n.oplata ? n.oplata : n.kwota);
    }
    return true;
  });

  const osob = osoby.filter((item, index, osoby) => osoby.indexOf(item) === index);
  res['sumy']['osoby'] = osob.length;
  res['kwota'] = res['sumy']['przychody'];
  // console.log(res);

  return res;
}

export function zestawienie_ipos(dane,rok,miesiac) {

  let res = {};
  // console.log(dane);

  res['id'] = rok+"-"+miesiac+"-K";
  res['tabela'] = "ipos";
  res['data'] = rok+"-"+miesiac+"-01";
  res['tytul'] = "przychody zarejestrowane w kasoterminalu";

  res['ipos'] = {};
  res['sumy'] = {
    glicz: 0,
    gsuma: 0,
    klicz: 0,
    ksuma: 0,
    op: 0,
  };

  dane.map((k,i)=>{
    if ( k.czas.substr(0,7) === rok+"-"+miesiac ) {
      // console.log(k);
      if (!res['ipos'][k.nazwa]) {
        res['ipos'][k.nazwa] = {
          glicz: 0,
          gsuma: 0,
          klicz: 0,
          ksuma: 0,
          op: 0,
        };
      }
      if (k.platnosc==="gotówka") {
        res['ipos'][k.nazwa]['glicz'] += k.liczba;
        res['ipos'][k.nazwa]['gsuma'] += k.oplata;
        res['sumy']['glicz'] += k.liczba;
        res['sumy']['gsuma'] += k.oplata;
        } else if (k.platnosc==="karta") {
        res['ipos'][k.nazwa]['klicz'] += k.liczba;
        res['ipos'][k.nazwa]['ksuma'] += k.oplata;
        res['sumy']['klicz'] += k.liczba;
        res['sumy']['ksuma'] += k.oplata;
        }
      res['ipos'][k.nazwa]['op'] += 1;
      res['sumy']['op'] += 1;
    }
    return true;
  });

  res['kwota'] = res['sumy']['ksuma']+res['sumy']['gsuma'];

  return res;
}

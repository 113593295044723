import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import { Link } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Link, useLocation } from 'react-router-dom';
import { fetchDane } from '../api';
import { useQuery } from '@tanstack/react-query';
import useOnline from '@haydenbleasel/use-online';
import { Avatar } from '@mui/material';

import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

export const pages = [
        { label: 'Start',     url: '../',                        cred: ["SKAR", "RADA", "GOSP", "REGI", "PUBL"] },
        { label: 'Odwiedziny',url: '/mobi',          apka: true, cred: ["SKAR", "RADA", "GOSP", "REGI", "PUBL"] },
        { label: 'Noclegi',   url: '/mobi/noclegi',  apka: true, cred: ["SKAR", "RADA", "GOSP"] },
        { label: 'Wydatki',   url: '/mobi/wydatki',  apka: true, cred: ["SKAR", "RADA", "GOSP"] },
        { label: 'R-K',       url: '/mobi/rk',       apka: true, cred: ["SKAR", "RADA", "GOSP"] },
        { label: 'SIWKO',     url: '/mobi/siwko',    apka: true, cred: ["SKAR", "RADA"] },
        { label: 'Migracje',  url: '/mobi/migracje', apka: true, cred: ["SKAR", "RADA"], hide: true },
        { label: 'Ceny',      url: '../#ceny',                   cred: ["SKAR", "RADA", "GOSP", "REGI", "PUBL"] },
        { label: 'Kontakt',   url: '../#kontakt',                cred: ["SKAR", "RADA", "GOSP", "REGI", "PUBL"] },
];
const settings = [
        { label: 'Profil',  url: '../konto',   cred: ["SKAR", "RADA", "GOSP", "REGI"] },
        { label: 'Wyloguj', url: '../wyloguj', cred: ["SKAR", "RADA", "GOSP", "REGI"] },
        { label: 'Zaloguj', url: '../loguj',   cred: ["PUBL"] },
        { label: 'Resetuj', url: '/reset.html', cred: ["SKAR", "RADA", "GOSP"] },
];

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Naglowek = (props) => {

  const cred = useQuery(['dane','cred'], fetchDane);
  const isOnline = useOnline();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  let location = useLocation();
  // console.log(location,pages);
  let tytul = pages.filter(a=>a.url===location.pathname)[0];
  if (tytul) { tytul = tytul.label } else { tytul = "Odwiedziny" }

  // console.log("location: ",location," props: ",props, cred );

  return (
    <>
    <CssBaseline />
      <HideOnScroll {...props}>
    <AppBar>
      <Container maxWidth="xl">
        { cred.isSuccess && <Toolbar disableGutters>
          
          <Typography
            key="typo1"
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            {tytul}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} key="box1" >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              key="hamburger"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              id="menu-appbar1"
              key="menu-appbar1"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              // menu mobilne:
            >
              { pages.map((page) => ( <div key={"mbutdiv-"+page.label} hidden={page.hide} >
                { page.cred.includes(cred.data.grupa) &&
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                  { page.apka ? <Link to={page.url} style={{ textDecoration: "none" }}><Button variant="text">{page.label}</Button></Link>
                    : <Button variant="text" href={page.url}>{page.label}</Button> }
                  </Typography>
                </MenuItem>
                } </div>
              ))}
            </Menu>
          </Box>

          <Typography
            key="typo2"
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            {tytul}
          </Typography>
          
          <Box sx={{ alignItems:"center", justifyContent:"flex-end", flexGrow: 1, mr: "3em", display: { xs: 'none', md: 'flex' } }} key="box2">
            { pages.map((page) => ( <div key={"dbut-"+page.label} hidden={page.hide}>
              { page.cred.includes(cred.data.grupa) &&
                <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  { page.apka ? <Link to={page.url}>{page.label}</Link>
                    : <Button variant="text" href={page.url}>{page.label}</Button> }
              </Button>} 
              </div> 
            ))}
          </Box>

          { !isOnline && <Tooltip title="Brak połączenia z Internetem - można przeglądać informacje ale nie można zapisać zmian.">
                <Avatar sx={{ bgcolor: 'white', width: 28, height: 28, mr: 1, border: "2px solid red" }}>
                  <WifiOffIcon color='error' />
                </Avatar>
          </Tooltip> }
          <Box sx={{ flexGrow: 0 }} key="box3">
            {/* <Tooltip title="Open settings"> */}
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                <AccountCircle fontSize="large" sx={{ color: "white" }} />
              </IconButton>
            {/* </Tooltip> */}

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar2"
              key="menu-appbar2"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                { cred.name !== "null" && <MenuItem key="username">
                  <Typography textAlign="center">{cred.data.name}</Typography>
                </MenuItem> }
              { settings.map((setting) => (
                <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                { setting.cred.includes(cred.data.grupa) && <Typography textAlign="center">
                    <Button variant="text" href={setting.url}>{setting.label}</Button>
                  </Typography> }
                </MenuItem>
              ))}
              <MenuItem><Typography variant="caption">v. {process.env.REACT_APP_WERSJA}</Typography></MenuItem>
            </Menu>
            
          </Box>
        </Toolbar> }
      </Container>
    </AppBar>
    </HideOnScroll>
    <Toolbar sx={{ marginBottom: { md: 2 } }} />
    </>
  );
};
export default Naglowek;

import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { fetchDane } from "../api";

export const InstrukcjeTeksty = (props) => {
  // const cred = useQuery(['JUser'], fetchJUser);
  const cred = useQuery(['dane','cred'], fetchDane);

// ############################################################################################
        if (props.tekst==="noclegFormularz") return (<>
          <Typography variant="body2" gutterBottom>
                <b>Wpisywanie noclegu zacznij od wpisania numeru PESEL. </b>
                Jeśli wpisany PESEL będzie poprawny - formularz stanie się krótszy.
                Jeśli nie znasz numeru PESEL - wpisz resztę informacji.
                Datę urodzenia możesz wpisać w polu PESEL podając 6 cyfr w formacie RRMMDD.
          </Typography>
          { cred.isSuccess && cred.data.grupa === "GOSP" && <>
          <Typography variant="body2" gutterBottom>
                <b>Jeśli</b> aplikacja <b>znajdzie</b> osoby pasujące do fragmentu PESEL - 
                <b> możesz je przejrzeć w drugim kroku</b> - podczas edycji osoby. 
                <b> Zawsze lepiej wybrać osobę z listy. </b>
                Zawsze możesz poprawić dane wybranej osoby.
          </Typography>
          <hr/>
          <Typography variant="body2" gutterBottom>
            Wybierając Grupę przypisujesz nocleg do wybranego zgłoszenia Odwiedzin. 
            <b> Po wybraniu Grupy przepisane zostają ze zgłoszenia informacje</b> o datach przyjścia, wyjścia 
            oraz ustawiana jest kategoria noclegu. Każdą informację możesz dostosować indywidualnie dla danego noclegu.
          </Typography>
          </> }
          <hr/>
          <Typography variant="body2" gutterBottom>
            Po podaniu wszystkich informacji wyliczana jest opłata za nocleg.
            Jeśli Gość płaci inną kwotę - Gospodarz wskazuje rodzaj przypadku i uzupełnia informacje. 
          </Typography>
          { cred.isSuccess && cred.data.grupa === "GOSP" && <>
          <hr/>
          <Typography variant="body2" gutterBottom>
            Opłata klimatyczna jest odprowadzana przez Gospodarza bezpośrednio do Urzędu Gminy. 
            Możesz ewidencjonować te wpłaty w aplikacji - wtedy będą one odrębnie sumowane w aplikacji.
          </Typography>
          </> }
        </>)

// ############################################################################################
        if (props.tekst==="noclegiGrupa") return (<>
          <Typography variant="body2" gutterBottom>
                Zamiast wypełniac papiery, możesz wpisać tutaj uczestników tej grupy. 
                Jeśli ktoś chce zostac dłużej, albo przyjechac wczesniej - 
                możesz dla każdej osoby podac indywidualne daty i kategorię noclegu.
          </Typography>
          <Typography variant="body2" gutterBottom>
                <b>Blokada</b> edycji noclegów jest zakładana i zdejmowana <b>przez Gospodarza</b>.
          </Typography>
        </>)

// ############################################################################################
        if (props.tekst==="noclegiLista") return (<>
        <Typography variant="body2">noclegiLista fasfa sdsafasfsa fsaf sdafasfasf dsaf sa fas fsa fsaf</Typography>
        </>)
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Badge, TableCell, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import FinanseOperacje from './FinanseOperacje';
import { miesiacLabel } from '../komponenty/labelki';
import { useQueryClient } from '@tanstack/react-query';
// import { fetchDane } from '../api';
// import { miesiacChataWydatki } from './sumy';
import dayjs from 'dayjs';


export default function FinanseKomorka(props) {

  const { miesiac, typ, sumy } = props;
  let rok = Number(dayjs(miesiac).format("YYYY"));
  let tytul = "";
  
  // console.time('a3');
  // console.time('k');
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const invisible = queryClient.getQueryData(['badzeSumy']);
  const ksiegowe = queryClient.getQueryData(['ksiegowe']);
  const calosc = queryClient.getQueryData(['calosc']);
  // console.time('a3');
  // const podsumowania = queryClient.getQueryData(['sumy']);
  // console.timeEnd('a3')
  // console.timeEnd('a')
  // console.timeEnd('a2')

//   const operacje = useQuery(['dane'], fetchDane);
  // let sumy = {};
  // let parametr = "suma";
//   console.log(podsumowania);



  // sterowanie zakresem wyświetlania:
  if (typ==="pch") {
        // sumy = podsumowania.filter(o=>String(o.rok)===dayjs(miesiac).format("YYYY"))[0].miesiace.filter(m=>m.miesiac===miesiac)[0].przychody;
        tytul = "przychody - Chata";
  }
  if (typ==="pb") {
        // sumy = podsumowania.filter(o=>String(o.rok)===dayjs(miesiac).format("YYYY"))[0].miesiace.filter(m=>m.miesiac===miesiac)[0].bankP;
        tytul = "przychody - bank";
  } 
  if (typ==="pk") {
        // sumy = podsumowania.filter(o=>String(o.rok)===dayjs(miesiac).format("YYYY"))[0].miesiace.filter(m=>m.miesiac===miesiac)[0].kasaP;
        tytul = "przychody - kasa";
  } 
  if (typ==="wch") {
        // sumy = podsumowania.filter(o=>String(o.rok)===dayjs(miesiac).format("YYYY"))[0].miesiace.filter(m=>m.miesiac===miesiac)[0].wydatki;
        // parametr = "chata";
        tytul = "wydatki - Chata";
  }
  if (typ==="wb") {
        // sumy = podsumowania.filter(o=>String(o.rok)===dayjs(miesiac).format("YYYY"))[0].miesiace.filter(m=>m.miesiac===miesiac)[0].bankW;
        tytul = "wydatki - bank";
  } 
  if (typ==="wk") {
        // sumy = podsumowania.filter(o=>String(o.rok)===dayjs(miesiac).format("YYYY"))[0].miesiace.filter(m=>m.miesiac===miesiac)[0].kasaW;
        tytul = "wydatki - kasa";
  } 
//   console.log(sumy);
// console.timeEnd('k')


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  // console.log(v,val,kval);
  // console.log('aaa');

  return (
    <React.Fragment>
      <TableCell sx={{ 
        borderRight: props.last ? 0 : 1, 
        cursor: 'pointer',
        '& .MuiBadge-root': { display: "block", top: "10px", left: "10px" },
      }} align="right" onClick={handleClickOpen}>

        <Tooltip title="Liczba operacji w tej komórce" placement="top-start">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={sumy.operacji}
          variant="outlined"
          color="jasnyszary"
          invisible={invisible}
        />
        </Tooltip>

        <Tooltip title="Liczba operacji uzupełnionych informacjami" placement="top">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={sumy.uzup}
          variant="outlined"
          color="jasnyzielony"
          invisible={invisible}
          sx={{ ml: 3 }}
        />
        </Tooltip>

        <Tooltip title="Liczba operacji z dodanym plikiem" placement="top-start">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={sumy.pliki}
          variant="outlined"
          color="info"
          invisible={invisible}
          sx={{ ml: sumy.uzup>0?6:3 }}
        />
        </Tooltip>

        <Tooltip title="Obecność operacji w kategorii 'przepływy'" placement="top">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          badgeContent={sumy.przeplywy}
          variant="outlined"
          color="warning"
          invisible={invisible}
          sx={{ ml: sumy.pliki>0?9:6 }}
        />
        </Tooltip>

        {!(props.typ==="pb" || props.typ==="wb") && calosc && <>{sumy.suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</>}
        {rok>2021 && !(props.typ==="pb" || props.typ==="wb") && calosc && ksiegowe && <br/>}
        {(props.typ==="pb" || props.typ==="wb" || (rok>2021 && ksiegowe)) && <i>{sumy.ksiegowe.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</i>}

        {/* {props.val.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł */}
        
      </TableCell>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="xl"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Lista operacji - {tytul} - {miesiacLabel(dayjs(miesiac).format("MM"),true)} {dayjs(miesiac).format("YYYY")}<br/>
          <small><i>( szczegóły po rozwinięciu )</i></small>
        </DialogTitle>
        <DialogContent>
          {/* lista operacji w wybranej kategorii */}
          <FinanseOperacje 
            // rok={props.rok} 
            // m={props.m} 
            typ={typ} 
            miesiac={miesiac} 
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Wyjdź</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableHead, Typography } from '@mui/material';
import { kategoriaLabel } from '../komponenty/labelki';


export default function TabelaNoclegi(props) {

  // console.log(props);

    // let rows = [
    //   { name: kategoriaLabel(1),  value: props.dane['kat']['1'].wizyt, val2: props.dane['kat']['1'].nocy, val3: props.dane['kat']['1'].suma },
    //   { name: kategoriaLabel(2),  value: props.dane['kat']['2'].wizyt, val2: props.dane['kat']['2'].nocy, val3: props.dane['kat']['2'].suma },
    //   { name: kategoriaLabel(3),  value: props.dane['kat']['3'].wizyt, val2: props.dane['kat']['3'].nocy, val3: props.dane['kat']['3'].suma },
    //   { name: kategoriaLabel(9),  value: props.dane['kat']['9'].wizyt, val2: props.dane['kat']['9'].nocy, val3: props.dane['kat']['9'].suma },
    //   { name: kategoriaLabel(4),  value: props.dane['kat']['4'].wizyt, val2: props.dane['kat']['4'].nocy, val3: props.dane['kat']['4'].suma },
    //   { name: kategoriaLabel(5),  value: props.dane['kat']['5'].wizyt, val2: props.dane['kat']['5'].nocy, val3: props.dane['kat']['5'].suma },
    //   { name: kategoriaLabel(6),  value: props.dane['kat']['6'].wizyt, val2: props.dane['kat']['6'].nocy, val3: props.dane['kat']['6'].suma },
    //   { name: kategoriaLabel(7),  value: props.dane['kat']['7'].wizyt, val2: props.dane['kat']['7'].nocy, val3: props.dane['kat']['7'].suma },
    // ];

    // console.log(props.dane);

  // kat1: 15, // Chata
  // kat2: 10, // Student
  // kat3: 10, // Namiot
  // kat9: 10, // Lektorium
  // kat4: 0,  // Dziecko
  // kat5: 0,  // KO
  // kat6: 0,  // GG
  // kat7: 0,  // Inne
  // kat8: 0,  // Bank

  return (
    <React.Fragment>
    <Typography variant="h6">Szczegóły operacji:</Typography>
    <hr/>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow sx={{ borderBottom: 2 }}>
            <TableCell align="right"><b>kategoria</b></TableCell>
            <TableCell align="right"><b>odwiedzin</b></TableCell>
            <TableCell align="right"><b>noclegów</b></TableCell>
            <TableCell align="right"><b>wpływy</b></TableCell>
          </TableRow>
        </TableHead> 
        
        <TableBody>
          {props.dane.kat.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" component="th" scope="row">
                <b>{kategoriaLabel(row.id)}</b>
              </TableCell>
              <TableCell align={row.kwota!=null ? "right" : "left"}>{row.wizyt}</TableCell>
              <TableCell align="right">{row.nocy}</TableCell>
              <TableCell align="right">{row.kwota && row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
            </TableRow>
          ))}
            <TableRow
                             sx={{ borderTop: 2 }}
                            //  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" component="th" scope="row">
                <b>Sumy</b>
              </TableCell>
              <TableCell align="right">{props.dane.wizyt}</TableCell>
              <TableCell align="right">{props.dane.nocy}</TableCell>
              <TableCell align="right">{props.dane.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="right" component="th" scope="row">
                <i>liczba osób:</i>
              </TableCell>
              <TableCell align="left" ><i>{Object.keys(props.dane.osoby).length}</i></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
}


import * as React from "react";
import { 
  MenuItem, 
  TextField, 
  useMediaQuery 
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import { kategoriaOpcje } from "../../komponenty/labelki";
import { useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AutoVirGrupa from "../../komponenty/AutoVirGrupa";
import { Nocleg4bOsobaSzcz } from "./Nocleg4bSzczegoly";
import { useQueryClient } from '@tanstack/react-query';


// const NoclegFormularz = React.forwardRef((props, ref) => {
const Nocleg3Nocleg = (props) => {
  
  const [drugiCal, setOpenDrugiCal] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // const funkcje = useQuery(['dane','ofunkcje'], fetchDane);
  // const odwiedziny = useQuery(['dane','odwiedziny'], fetchDane);

  const queryClient = useQueryClient();
  let cred = queryClient.getQueryData(['dane','cred']);
  let funkcje = (cred.grupa==="GOSP"||cred.grupa==="RADA") && queryClient.getQueryData(['dane','ofunkcje']);
  let odwiedziny = queryClient.getQueryData(['dane','odwiedziny']);

  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = props.formularz;


  React.useEffect(() => {
    let grupa = watch("gid") && odwiedziny.odwiedziny.filter(o=>o.id===watch("gid"))[0];

    if(grupa && !watch("od")) { setValue("od",grupa.od) }
    if(grupa && !watch("do")) { setValue("do",grupa.do) }
    if(grupa && !watch("kategoria")) { setValue("kategoria",grupa.kategoria) }

    funkcje && funkcje.ofunkcje.filter(o=>o.idosoby===watch("idosoby")).forEach( (e,i) => {
      if (Number(e.funkcja)===5) { setValue("kategoria",7, { shouldValidate: true }); } // byli Gospopdarze 
      if (Number(e.funkcja)===1 && e.datastop==="2100-01-01") { setValue("kategoria",5, { shouldValidate: true }); } // członek Klubu
    });
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ["",watch("gid")]);
  // console.log(watch());

  return (
    <React.Fragment>
        
        <Nocleg4bOsobaSzcz n={watch()} />
                
        {/* ### koniec o osobie, poczatek noclegu ############################################################# */}
        <Grid xs={12} sx={{ '& .MuiFormControl-root': { mt: fullScreen?2:0, mb: 0 } }}>
          <AutoVirGrupa 
              {...register("gid")}
              opcja="grupa" 
              value={watch("gid")}
              onChange={(v) => {
                // console.log(v);
                setValue("od", "");
                setValue("do", "");
                // timeout wymusza kolejność operacji i jest konieczny do rewalidacji:
                // jeśli pole jest null to rewalidacja nie zadziała
                // a jeśli data jest "" to wyświetla 'YYYY-MM-DD'
                // więc trzeba zamienić null na "" i wtedy ustawić docelową wartość
                setTimeout(function () {
                  setValue("gid", v?v.id:null);
                  if (v) {
                    setValue("od", v.od);
                    setValue("do", v.do);
                    setValue("kategoria", v.kategoria);
                  }
                }, 10);
                // clearErrors(["od","do"]);
                // trigger(["od","do","kategoria"]);
                // clearErrors(["od","do"]);
              }}
          />

        </Grid>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            <Grid xs={6}>
                  <DatePicker 
                    {...register("od", {
                        required: 'Podaj datę przyjścia',
                        validate: v => dayjs(v).isValid() || 'Wpisz prawidłową datę',
                    })}
                    value={watch("od")&&dayjs(watch("od"))}
                    label="Data przyjścia"
                    id="od"
                    format="YYYY-MM-DD"
                    onClose={()=>watch("do")===null&&setOpenDrugiCal(true)}
                    onChange={(event) => {
                      setValue("od", event.format("YYYY-MM-DD"));
                      trigger("od");
                    }}
                    clearable
                    slotProps={{
                      textField: {
                        // value: watch("od")?watch("od"):"",
                        error: errors.od ? true : false,
                        helperText: errors.od ? errors.od.message : false,
                        size: 'small',
                      },
                    }}
                    localeText={{ datePickerToolbarTitle: 'Data przyjścia' }}
                    sx={{ 
                      '& .MuiOutlinedInput-input': { backgroundColor: "none",pt:1,pb:1 },
                      '& .MuiInputLabel-root': { zIndex: 0 }
                    }}
                  /> 
            </Grid>
            <Grid xs={6}>
                    <DatePicker 
                    {...register("do", {
                      required: 'Podaj datę wyjścia',
                      validate: v => dayjs(v).isValid() || 'Wpisz prawidłową datę',
                    })}
                    value={watch("do")&&dayjs(watch("do"))}
                    label="Data wyjścia"
                    id="do"
                    format="YYYY-MM-DD"
                    minDate={dayjs(watch("od")).add(1, "day")}
                    open={drugiCal}
                    onOpen={()=>setOpenDrugiCal(true)}
                    onClose={()=>setOpenDrugiCal(false)}
                    onChange={(event) => {
                      setValue("do", event.format("YYYY-MM-DD"));
                      trigger("do");
                    }}
                    clearable
                    slotProps={{
                      textField: {
                        error: errors.do ? true : false,
                        helperText: errors.do ? errors.do.message : false,
                        size: 'small',
                      },
                    }}
                    localeText={{ datePickerToolbarTitle: 'Data wyjścia' }}
                    sx={{ 
                      '& .MuiOutlinedInput-input': { backgroundColor: "none",pt:1,pb:1 },
                      '& .MuiInputLabel-root': { zIndex: 0 }
                    }}
                  />
            </Grid>
        </LocalizationProvider>


        <Grid xs={12} md={4} 
          sx={{ '& .MuiFormControl-root': { m: 0 } }}
        >
        <TextField 
          select
          {...register("kategoria", {
            required: 'Wybierz z listy kategorię',
            min: {
              value: 1,
              message: 'Wybierz z listy kategorię' // JS only: <p>error message</p> TS only support string
            }
          })}
          value={watch('kategoria')}
          onChange={(event) => {
            setValue("kategoria", event.target.value);
            trigger("kategoria");
          }}
          label="kategoria noclegu"
          fullWidth
          size="small"
          margin="dense"
          variant="outlined"
          error={errors.kategoria ? true : false}
          helperText={errors.kategoria ? errors.kategoria.message : false}
        >
          {kategoriaOpcje.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </Grid>
        
    </React.Fragment>
  );
}


export default Nocleg3Nocleg;

import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { 
  // QueryCache, 
  QueryClient, 
  QueryClientProvider 
} from '@tanstack/react-query'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import theme from './statyczne/theme';
// tu wskazujesz z którego pliku ma być załadowany formularz
// import App from './formularze/Odwiedziny';
// import reportWebVitals from './reportWebVitals';
import App from './App';
// import { persistWithIndexedDB } from './api/persistWithIndexedDB';
// import App from './OSP';
// import AppWitryna from './AppWitryna';

// const queryCache = new QueryCache();
const queryClient = new QueryClient({
  // queryCache,
  // networkMode: 'offlineFirst',
  defaultOptions: {
    // networkMode: 'offlineFirst',
    queries: {
      // networkMode: 'always',
      networkMode: 'offlineFirst',
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
      // refetchOnWindowFocus: false,
      // initialData: () => queryClient.getQueryData(['dane']),
      // initalData mozna ew. pobierac z indexedDB - chodzi głównie o osoby...
    },
  },
});
// persistWithIndexedDB(queryClient);



const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient} >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  // </React.StrictMode>
);

// poniżej wersja Odwiedzin jako widżet na witrynie:
// ReactDOM.render(
//   <React.StrictMode>
//       <QueryClientProvider client={queryClient} >
//         <ThemeProvider theme={theme}>
//           <BrowserRouter>
//             <AppWitryna />
//           </BrowserRouter>
//         </ThemeProvider>
//       </QueryClientProvider>
//   </React.StrictMode>,
//   document.getElementById("witryna")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

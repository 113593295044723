import * as React from 'react';

export default function Wprowadzenie() {
      
        return (
        <>
                <p>Chata Socjologa próbuje funkcjonować w tych trudnych czasach. 
                Nie jest to jednak łatwe, dlatego będziemy wdzięczni, jeśli pomożesz nam w tym, 
                informując o swoim zamiarze odwiedzenia Chaty. 
                <br/><br/>
                Aby to zrobić trzeba najpierw utworzyć sobie konto <a href="/rejestracja">na naszej witrynie</a>. 
                Tam trzeba przekazać m.in. namiar na siebie ( imię, nazwisko, e-mail, telefon komórkowy ).
                Na podany mail przyjdzie link aktywacyjny.
                <br/><br/>
                Jeśli masz już konto to po zalogowaniu się 
                będzie możliwe dodanie informacji na temat pobytu ( termin pobytu, liczba osób )
                <br/><br/>
                Brzmi skomplikowanie, ale jest to naprawdę bardzo proste i BARDZO pomoże nam w organizacji życia Chaty. 
                </p>
                <p align="right">Istnieje też możliwość<br/>wsparcia nas zakupem pamiątki na <a href="https://allegro.pl/uzytkownik/ChataSocjologa" target="_blank" rel="noreferrer"><b>Allegro Charytatywni</b></a><br/>tylko z odbiorem osobistym w Chacie Socjologa. ;-)<br/><a href="https://allegro.pl/uzytkownik/ChataSocjologa" target="_blank" rel="noreferrer"><img src="/images/qr-allegro.png" alt="QRCode do Allegro" width="140"/></a></p>
        </>
        );
      
}

export function LegendaGospodarzaGrupa() {
      
        return (
        <>
                <p>Widzisz ekran Gospodarza dla rejestracji noclegów.</p>
                <p>Jeśli Gość rejestrował się na stronie www - wyszukaj jego zgłoszenie.</p>
                <p>Jeśli Gość przyszedł bez rejestracji - dodaj go do swojej <i>grupy Gospodarza</i>, np. "Noclegi Zdzicha".</p>
                <p>Możesz dodawać dowolną liczbę swoich grup, by rejestrować Gości.</p>
                <p>W Grupie możesz rejestrować dowolną liczbę Gości.</p>
                <hr/>
                <p>Jeśli coś będzie niejasne - pytaj <a href="mailto:igor@otryt.bieszczady.pl">Igora</a>, żeby mógł uzupełnić opisy. ;-)</p>
        </>
        );
      
}


/* ARCHIWUM:
2022-05-31:
                <p>Chata Socjologa próbuje funkcjonować w tych trudnych czasach. Nie jest to jednak łatwe, dlatego należy respektować poniższe zasady rezerwacji. Inaczej się po prostu nie da. Czas ich
                funkcjonowania jest zależny od zmian w wytycznych związanych ze stanem pandemii COVID19.
                <br/><br/>
                1. Jednym z obostrzeń jest ograniczenie ilości osób nocujących w Chacie. Dlatego <b>konieczna</b> jest zapowiedź odwiedzin Chaty. Każda zapowiedź będzie przez nas potwierdzana. Dlatego trzeba przekazać  m.in. namiar na siebie ( imię, nazwisko, e-mail, telefon komórkowy ) oraz informacje dotyczące pobytu ( termin pobytu, liczba osób )
                <br/><br/>
                2. Twoja zapowiedź będzie ważna tylko po jej potwierdzeniu przez nas. Wstęp do Chaty mają tylko jej zapowiedziani Goście, więc jeśli przyjdziesz do Chaty bez potwierdzonej zapowiedzi, nie będziesz mógł wejść do środka.
                <br/><br/>
                3. Każdy Gość musi zapoznać się z <a href="/regulamin-covid19">Regulaminem na czas pandemii</a>.
                <br/><br/>
                4. <b>Powyższe zasady obowiązują wszystkich Gości Chaty, również członków Stowarzyszenia i innych stałych bywalców</b>
                </p>
                <p align="right">Istnieje też możliwość<br/>wsparcia nas zakupem pamiątki na <a href="https://allegro.pl/uzytkownik/ChataSocjologa" target="_blank"><b>Allegro Charytatywni</b></a><br/>tylko z odbiorem osobistym w Chacie Socjologa. ;-)<br/><a href="https://allegro.pl/uzytkownik/ChataSocjologa" target="_blank"><img src="/images/qr-allegro.png" width="140"/></a></p>


*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import { Badge, Box } from '@mui/material';
import { transOsoba } from '../komponenty/labelki';

export default function OsobaDuplikaty(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // console.log(props);


  return (
    <div>
      <Box sx={{ m: 2, cursor: "pointer" }} onClick={handleClickOpen}>
        {/* <Badge badgeContent={props.osoba.duplikaty.length} color="warning">
              <Button variant="outlined" color="warning" startIcon={<TheaterComedyIcon />} onClick={handleClickOpen}>
                Duplikaty
              </Button>
        </Badge> */}
        <Badge anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }} badgeContent={props.osoba.duplikaty.length} color="warning">
              <TheaterComedyIcon color="warning" />
        </Badge>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Duplikaty
        </DialogTitle>
        <DialogContent>
            Duplikaty osoby:
            <Box key={"duplikat-"+props.osoba.oid} fontSize={"small"}><b>{props.osoba.oid}.</b> {props.osoba.imie} {props.osoba.nazwisko},<br/>PESEL: {props.osoba.PESEL}, ur. {props.osoba.dataur}<hr/></Box>
            To okno można zobaczyć bo istnieją w bazie inne osoby z takim samym numerem PESEL, albo imieniem i nazwiskiem.<br/><br/>
            { props.osoba.duplikaty.map(d=>{
                        d.oinfo = d.info;
                        d.oid = d.id;
                        d = transOsoba(d);
                        return <Box key={"duplikat-"+d.oid} fontSize={"small"}><b>{d.oid}.</b> {d.imie} {d.nazwisko},<br/>PESEL: {d.PESEL}, ur. {d.dataur}<hr/></Box>
                }) }
                <br/>
            Tu w przyszłości będzie możliwość zarządzania duplikatami osób. Na razie trzeba poczekać.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Rozumiem
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { PeselDecode, transFunkcja, transNocleg } from '../../komponenty/labelki';
import { useQueryClient } from '@tanstack/react-query';
// import { fetchDane } from '../../api';


export function Nocleg4bOsobaSzcz(props) {

  const queryClient = useQueryClient();
  let cred = queryClient.getQueryData(['dane','cred']);
  let funkcjeLista = (cred.grupa==="GOSP"||cred.grupa==="RADA") && queryClient.getQueryData(['dane','ofunkcje']);
  // const funkcjeLista = useQuery(['dane','ofunkcje'], fetchDane);
  let funkcje = funkcjeLista && funkcjeLista.ofunkcje.filter(o=>o.idosoby===props.n.idosoby);

  let n = transNocleg(props.n);
  // React.useEffect(() => {
  //   n = transNocleg(props.n);
  // }, [props.n]);
  
  // console.log(funkcje)

  return (
    <Box sx={{ borderTop: "1px solid grey",ml:2,mt:1,pt:2,width:"100%" }} >
        <b>{n.imie} {n.nazwisko}</b>, ur. {n.dataur} - wiek: <b>{n.wieknoc==="niewiadomy"?n.osoba.wiekdzis:n.wieknoc}</b>
        <br />
        wpisany PESEL: {n.PESEL} - {PeselDecode(n.PESEL).valid?"poprawny":"niepoprawny"}

        { funkcje && funkcje.length>0 && <>
          <hr /><u>historia związków z Klubem Otryckim:<br/></u>
          { funkcje.map( f => {
            f = transFunkcja(f);
            return (<Box key={"formfun-"+f.id}><small>
              <b>{f.funkcja_label_short} </b>
              <i> 
                - od {f.datastart ? f.datastart : "..."} do {f.datastop ? (f.datastop==="2100-01-01" ? "dzisiaj" : f.datastop) : "..."}
              </i>
              </small></Box>)
          }) }
        </> }
        <Typography sx={{textAlign:"right",fontSize:".7em",fontStyle:"italic",mt:1}} >osobaID: {n.idosoby ? n.idosoby : "nowa..."}</Typography>

    </Box>
  );
}

export function Nocleg4bSzczegoly(props) {

  const queryClient = useQueryClient();
  let cred = queryClient.getQueryData(['dane','cred']);
  let n = transNocleg(props.n);
  // React.useEffect(() => {
  //   n = transNocleg(props.n);
  // }, [props.n]);
  
  // console.log(n)

  return (<>
    <Nocleg4bOsobaSzcz n={n} />
    <Box sx={{ borderTop: "1px solid grey",ml:2,mt:1,pt:2,width:"100%" }}>
        noclegi w dniach: <b>{n.od} - {n.do}</b>
        <br />
        kategoria: {n.kategoria_label}
        <br />
      nocy: <b>{n.nocy}</b> - opłata wyliczona: {n.wyliczona} zł
      <br />
      
      {(cred.grupa!=="REGI") && <>
        
        opłata klimatyczna:{" "}
        {n.klimat_zw ? <>nie pobrano</> : <>{n.oklimat} zł</>}
        <br />
        pobrano: <b>
        {n.pobrano} zł</b>{ !n.standard && n.rodzaj===1 && <>, w tym gotówka: {n.oplata??0} zł</> }
        <br />

      </>}

    </Box>
    </>
  );
}

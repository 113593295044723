import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from '../api';
import { matchSorter } from 'match-sorter';

const LISTBOX_PADDING = 1; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };




  
  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {/* {`#${dataSet[2] + 1} - ${dataSet[1]}`} */}
      {`${dataSet[1].id}`}. {`${dataSet[1].od}`} {`${dataSet[1].nazwa}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.array,
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const AutoVirGrupa = React.forwardRef((props, ref) => {
        // const cred = useQuery(['JUser'], fetchJUser);
        // const operacje = useQuery(['operacje'], fetchOperacje);
        const operacje = useQuery(['dane'], fetchDane);
        const cred = useQuery(['dane','cred'], fetchDane);
        let grupy = operacje.isSuccess && matchSorter(operacje.data.odwiedziny, '', {baseSort: (a, b) => (a.item.od > b.item.od ? -1 : 1)});
        // let grupa = operacje.isSuccess && grupy.filter

        const [value, setValue] = React.useState(props.value);
        React.useEffect(() => {
          setValue(props.value);
        }, [props.value]);
      
  return (
    <>
    { operacje.isSuccess && <Autocomplete
      id={props.opcja}
      ref={ref}
      value={value}
      onChange={(e,v) => {
        props.onChange(v);
      }}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={grupy}
      getOptionLabel={(g) => {
       if (typeof g === "string"||typeof g === "number") {
          let grupa = grupy.filter(gr=>Number(gr.id)===Number(g))[0];
          return grupa ? grupa.id+". "+grupa.od+" - "+grupa.nazwa : "";
        } else if (g) {
          return g.id+". "+g.od+" "+g.nazwa;
        } 
      }}
      isOptionEqualToValue={(o,w)=>o.id===w}
      groupBy={(option) => option && option.od.substring(0,7)}
      renderInput={(params) => <TextField {...params} value={value} label={props.opcja} />}
      renderOption={(props, option, state) => [props, option, state.index]}
      // TODO: Post React 18 update - validate this conversion, look like a hidden bug
      renderGroup={(params) => params}
      size="small"
      disabled={(cred.data.grupa==="REGI")?true:false}
        // TODO: połączyć z blokadą edycji noclefgów w grupie
    /> }
    </>
  );
});
export default AutoVirGrupa;

import * as React from "react";
import dayjs from 'dayjs';
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useMutation, 
  // useQuery, 
  useQueryClient } from '@tanstack/react-query';
import { 
  // fetchDane, 
  postRapKas } from "../../api";
import KomunikatyWysylka from "../../komponenty/KomunikatyWysylka";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ButtonPliki from "./ButtonPliki";

function RapKasFormularz(props) {
  
  // const operacje = useQuery(['dane'], fetchDane);
  const queryClient = useQueryClient();
  const operacje = queryClient.getQueryData(['dane']);
  const sumyM = queryClient.getQueryData(['sumyMiesieczne']);
  
  let data = dayjs(props.miesiac).endOf('month').format("YYYY-MM-DD");
  let dataPrev = dayjs(props.miesiac).subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
  
  let rapKas = operacje.przychody.filter(o=>o.gadzet==="RapKas"&&o.data===data);
  let rapKasPrev = operacje.przychody.filter(o=>o.gadzet==="RapKas"&&o.data===dataPrev);
  let stanPrev = rapKasPrev && rapKasPrev.length>0 ? Number(JSON.parse(rapKasPrev[0].info).stanKasy) : null;

  let sumy = sumyM.filter(m=>m.miesiac===props.miesiac)[0];

  const startoweDane = {
    id:         rapKas.length>0?rapKas[0].id:"nowy",
    gadzet:     rapKas.length>0?rapKas[0].gadzet:"RapKas",
    data:       rapKas.length>0?rapKas[0].data:data,
    kwota:      rapKas.length>0?rapKas[0].kwota:"",
    stanKasy:   rapKas.length>0?JSON.parse(rapKas[0].info).stanKasy:"",
    noclegi:    rapKas.length>0?JSON.parse(rapKas[0].info).noclegi:"",
    uwagi:      rapKas.length>0?JSON.parse(rapKas[0].info).uwagi:"",
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: startoweDane,
  });

  const mutateRK = useMutation(rk => postRapKas(['x'],rk), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      let przychody = operacje.przychody.filter(o=>o.id!==data.data.id);
      przychody.push(data.data);
      operacje.przychody = przychody;
      queryClient.setQueryData(['dane'], operacje);
    },
  });

  const onSubmit = (data) => {
    !mutateRK.isLoading && mutateRK.mutate(data);
  };

  React.useEffect(() => {
        reset(startoweDane);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.miesiac]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  let wyliczonyIpos = stanPrev+sumy.przychody.iposN.kwota+sumy.przychody.iposS.kwota-sumy.wydatki.chata+Number(watch('kwota'));
  // let wyliczonyNoclegi = stanPrev+Number(watch('noclegi'))+sumy.przychody.iposS.kwota-sumy.wydatki.chata+Number(watch('kwota'));

  return (
    <React.Fragment>
      <KomunikatyWysylka stany={mutateRK} />
      <Box component="form">
        { !stanPrev ? <Grid container spacing={1}>
          <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" height={300}>
            <Typography variant="h5" align="center">UWAGA!!! Najpierw uzupełnij raport w poprzednim miesiącu...</Typography>
          </Grid> 
        </Grid> :
        <Grid container spacing={1}>

{/* ############### RODZAJ ######################################################################### */}
          <Grid item xs={8} md={6} display="flex" textAlign="right" justifyContent="right" alignItems="center">
              <i>stan kasy w Chacie na koniec poprzedniego miesiąca:</i>
          </Grid>
          <Grid item xs={4} md={3} display="flex" justifyContent="right" alignItems="center">
              {stanPrev.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł
          </Grid>
          { !fullScreen && <Grid item md={3}></Grid> }

          <Grid item xs={12} md={6} display="flex" justifyContent="right" alignItems="center">
          <input type="hidden" {...register("id")} value={watch("id")} />
          <input type="hidden" {...register("gadzet")} value={watch("gadzet")} />
          <input type="hidden" {...register("data")} value={watch("data")} />
          <input type="hidden" {...register("kwota")} value={watch("kwota")} />
          <input type="hidden" {...register("liczba")} value={watch("liczba")} />
          <input type="hidden" {...register("noclegi")} value={watch("noclegi")} />
                suma pieniędzy ze skarbonek:
          </Grid>
          { !fullScreen && <Grid item md={2}></Grid> }
          <Grid item xs={12} md={4} display="flex" alignItems="center" >
              <TextField 
                {...register("kwota")}
                type="tel"
                id="kwota"
                label="suma ze skarbonek"
                value={watch('kwota')}
                onChange={(event) => {
                  setValue("kwota", event.target.value.replace(',', '.'));
                }}
                error={errors.kwota ? true : false}
                helperText={errors.kwota && errors.kwota.message}
                variant="outlined" 
                fullWidth
                size="small"
                margin="dense"
                sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px" },
                    '& .MuiFormControl-root': { m: 0 },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                {/* <i>suma z imiennych noclegów zarejestrowanych w aplikacji:</i> */}
            </Grid>
            {/* <Grid item xs={12} md={3} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <small><i>na razie nie liczone</i></small>
            </Grid> */}
            { !fullScreen && <Grid item md={2}></Grid> }
            <Grid item xs={12} md={4} display="flex" alignItems="center" >
                {/* <TextField 
                {...register("noclegi")}
                type="tel"
                label="wpisz sumę z noclegów"
                id="noclegi"
                value={watch('noclegi')}
                onChange={(event) => {
                  setValue("noclegi", event.target.value.replace(',', '.'));
                }}
                error={errors.noclegi ? true : false}
                helperText={errors.noclegi && errors.noclegi.message}
                variant="outlined" 
                fullWidth
                size="small"
                margin="dense"
                sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px", textAlign: "right" },
                    '& .MuiFormControl-root': { m: 0 },
                }}
                /> */}
            </Grid>
            
            <Grid item xs={8} md={6} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <i>suma z noclegów zarejestrowanych w kasoterminalu:</i>
            </Grid>
            <Grid item xs={4} md={3} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <b>{sumy.przychody.iposN.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</b>
            </Grid>
            { !fullScreen && <Grid item md={3}></Grid> }


            <Grid item xs={8} md={6} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <i>suma ze sprzedży zarejestrowanej w kasoterminalu:</i>
            </Grid>
            <Grid item xs={4} md={3} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                {sumy.przychody.iposS.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł
            </Grid>
            { !fullScreen && <Grid item md={3}></Grid> }


            <Grid item xs={8} md={6} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <i>suma wydatków w Chacie:</i>
            </Grid>
            <Grid item xs={4} md={3} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <b>{sumy.wydatki.chata.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł</b>
            </Grid>
            { !fullScreen && <Grid item md={3}></Grid> }


            <Grid item xs={8} md={6} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <i>miesięczne saldo w Chacie (bez skarbonki):</i>
            </Grid>
            <Grid item xs={4} md={3} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                {(sumy.przychody.iposN.kwota+sumy.przychody.iposS.kwota-sumy.wydatki.chata).toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł
            </Grid>
            <Grid item xs={0} md={3} display="flex"></Grid>


            <Grid item xs={12} md={6} display="flex" textAlign="right" justifyContent={!fullScreen&&"right"} alignItems="center">
                <i>wyliczony stan kasy w Chacie na koniec miesiąca (ze skarbonką):</i>
            </Grid>
            <Grid item xs={6} md={3} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                <ReplyAllIcon color="success" sx={{ transform: "rotate(270deg)", mr: 2, cursor: "pointer" }} onClick={()=>setValue("stanKasy",wyliczonyIpos.toFixed(2))} />
                {wyliczonyIpos.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł
            </Grid>
            <Grid item xs={6} md={3} display="flex" textAlign="right" justifyContent="right" alignItems="center">
                {/* <ReplyAllIcon color="success" sx={{ transform: "rotate(270deg)", mr: 2, cursor: "pointer" }} onClick={()=>setValue("stanKasy",wyliczonyNoclegi.toFixed(2))} />
                {wyliczonyNoclegi.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})} zł */}
            </Grid>

            <Grid item xs={12} md={6} display="flex" textAlign="right" justifyContent={!fullScreen&&"right"} alignItems="center">
                potwierdzam stan kasy na koniec miesiąca:
            </Grid>
            <Grid item xs={12} md={6} display="flex" textAlign="right" alignItems="center">
              <TextField 
                {...register("stanKasy")}
                type="tel"
                id="stanKasy"
                value={watch('stanKasy')}
                placeholder="możesz użyć strzałki do skopiowania właściwej kwoty"
                onChange={(event) => {
                  setValue("stanKasy", event.target.value.replace(',', '.'));
                }}
                error={errors.stanKasy ? true : false}
                helperText={errors.stanKasy && errors.stanKasy.message}
                variant="outlined" 
                fullWidth
                size="small"
                margin="dense"
                sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px", textAlign: "right" },
                    '& .MuiFormControl-root': { m: 0 },
                }}
              />
            </Grid>
            <Grid item xs={12} display="flex" textAlign="right" alignItems="center">
                <TextField
                  {...register("uwagi")}
                  id="uwagi"
                  multiline
                  rows={6}
                  placeholder="tu wpisz ew. uwagi do tego konkretnego raportu"
                  value={watch("uwagi")}
                  onChange={(event) => setValue("uwagi", event.target.value)}
                  label="Uwagi"
                  error={errors.uwagi ? true : false}
                  helperText={errors.uwagi && errors.uwagi.message}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: 0 },
                    '& .MuiFormControl-root': { m: 0 },
                  }}
                />
            </Grid>
{/* ############# koniec formularza ########################################################################### */}
          <Grid item xs={3} align="left">
            <ButtonPliki button miesiac={props.miesiac} pozycja={{ mb: 3, mr: 3 }} />
          </Grid>
          <Grid item xs={4} align="center">
            <i><small>(ID: {watch("id")})</small></i>
          </Grid>
          <Grid item xs={5} align="right">
            <Button 
              variant="outlined" 
              // disabled 
              endIcon={<SendIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={mutateRK.isLoading}
            >
              zapisz
            </Button>
          </Grid>
        </Grid> }
      </Box>
    </React.Fragment>
  );
}

export default RapKasFormularz;

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import FinanseDialog from './FinanseDialog';
import { useQueryClient } from '@tanstack/react-query';
import { miesiacLabel } from '../komponenty/labelki';
// import { bilanseMiesiac } from './sumy';
// import { fetchDane } from '../api';
import FinanseKomorka from './FinanseKomorka';
// import { podsumowania } from './sumy';
import dayjs from 'dayjs';



export default function FinanseRok(props) {
  // console.time('a')
  // console.time('b')
  // console.time('c')
  // console.time('d')

  // w props jest tylko rok
  const queryClient = useQueryClient();
  // const operacje = useQuery(['operacje'], fetchOperacje);
  // const operacje = useQuery(['dane'], fetchDane);
  const ksiegowe = queryClient.getQueryData(['ksiegowe']);
  const calosc = queryClient.getQueryData(['calosc']);
  const sumyMiesieczne = queryClient.getQueryData(['sumyMiesieczne']);
  const sumyRoczne = queryClient.getQueryData(['sumyRoczne']);
  // const podsumowania = useQuery(['sumy']);
  // console.timeEnd('a')
  // console.log(podsumowania);

  const sumy = sumyMiesieczne.filter(o=>String(dayjs(o.miesiac).format("YYYY"))===String(props.rok));
  const sumyRok = sumyRoczne.filter(o=>String(o.rok)===String(props.rok))[0];
  
  // console.timeEnd('b')
  // const miesiace = operacje.isSuccess && bilanseMiesiac(operacje.data,props.rok);
  // let rok = {
  //   c: {przychody: {chata:0,bank:0,kasa:0}, wydatki: {chata:0,bank:0,kasa:0}},
  //   k: {przychody: {chata:0,bank:0,kasa:0}, wydatki: {chata:0,bank:0,kasa:0}},
  // }
  // if (operacje.isSuccess) {
  //   Object.keys(miesiace).map(m=>{
  //     rok['c']['przychody']['chata'] += miesiace[m]['c']['przychody']['chata'];
  //     rok['c']['przychody']['bank'] += miesiace[m]['k']['przychody']['bank'];
  //     rok['c']['przychody']['kasa'] += miesiace[m]['c']['przychody']['kasa'];
  //     rok['c']['wydatki']['chata'] += miesiace[m]['c']['wydatki']['chata'];
  //     rok['c']['wydatki']['bank'] += miesiace[m]['k']['wydatki']['bank'];
  //     rok['c']['wydatki']['kasa'] += miesiace[m]['c']['wydatki']['kasa'];
  //     rok['k']['przychody']['chata'] += miesiace[m]['k']['przychody']['chata'];
  //     rok['k']['przychody']['bank'] += miesiace[m]['k']['przychody']['bank'];
  //     rok['k']['przychody']['kasa'] += miesiace[m]['k']['przychody']['kasa'];
  //     rok['k']['wydatki']['chata'] += miesiace[m]['k']['wydatki']['chata'];
  //     rok['k']['wydatki']['bank'] += miesiace[m]['k']['wydatki']['bank'];
  //     rok['k']['wydatki']['kasa'] += miesiace[m]['k']['wydatki']['kasa'];
  //     return true;
  //   })
  // } 
  // console.log(rok)

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650, width: "99%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderRight: 1, borderBottom: 1 }} align="center" rowSpan={2}>
              Rok: {props.rok}
            </TableCell>
            <TableCell sx={{ borderRight: 1 }} align="center" colSpan={3}><b>Przychody</b></TableCell>
            <TableCell align="center" colSpan={3}><b>Wydatki</b></TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderRight: 1, borderBottom: 1 }} align="center"><b>Chata</b></TableCell>
            <TableCell sx={{ borderRight: 1, borderBottom: 1 }} align="center"><b>Bank</b></TableCell>
            <TableCell sx={{ borderRight: 1, borderBottom: 1 }} align="center"><b>Kasa</b></TableCell>
            <TableCell sx={{ borderRight: 1, borderBottom: 1 }} align="center"><b>Chata</b></TableCell>
            <TableCell sx={{ borderRight: 1, borderBottom: 1 }} align="center"><b>Bank</b></TableCell>
            <TableCell sx={{ borderBottom: 1 }} align="center"><b>Kasa</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sumy.sort((a,b)=>b.miesiac>a.miesiac?1:-1).map(m=>{
            // console.log(m);
            let k = dayjs(m.miesiac).format("M");
            return (
              <TableRow
                key={"mies-"+k}
                hover
              >
                <TableCell sx={{ borderRight: 1 }} component="th" scope="row" align="right">
                  {miesiacLabel(k)}
                </TableCell>
                <FinanseKomorka miesiac={m.miesiac} sumy={m.przychody} typ="pch" />
                <FinanseKomorka miesiac={m.miesiac} sumy={m.bankP} typ="pb" />
                <FinanseKomorka miesiac={m.miesiac} sumy={m.kasaP} typ="pk" />
                <FinanseKomorka miesiac={m.miesiac} sumy={m.wydatki} typ="wch" />
                <FinanseKomorka miesiac={m.miesiac} sumy={m.bankW} typ="wb" />
                <FinanseKomorka miesiac={m.miesiac} sumy={m.kasaW} typ="wk" />
              </TableRow>
            )
          })}
          {/* {Object.keys(miesiace).sort().reverse().map((k) => (
            k!=="rok" && <TableRow
              key={"mies-"+k}
              hover
        //       sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ borderRight: 1 }} component="th" scope="row" align="right">
                {miesiacLabel(k)}
              </TableCell> */}
              {/* kliknięcie w komórkę wiersza otwiera okno Dialog z listą operacji */}
              {/* <FinanseDialog val={miesiace[k]['c']['przychody']['chata']} ksieg={miesiace[k]['k']['przychody']['chata']} uz={miesiace[k]['u']['przychody']['chata']} rok={props.rok} m={k} typ="pch" />
              <FinanseDialog val={miesiace[k]['c']['przychody']['bank']}  ksieg={miesiace[k]['k']['przychody']['bank']}  uz={miesiace[k]['u']['przychody']['bank']}  rok={props.rok} m={k} typ="pb"  />
              <FinanseDialog val={miesiace[k]['c']['przychody']['kasa']}  ksieg={miesiace[k]['k']['przychody']['kasa']}  uz={miesiace[k]['u']['przychody']['kasa']}  rok={props.rok} m={k} typ="pk"  />
              <FinanseDialog val={miesiace[k]['c']['wydatki']['chata']}   ksieg={miesiace[k]['k']['wydatki']['chata']}   uz={miesiace[k]['u']['wydatki']['chata']}   rok={props.rok} m={k} typ="wch" />
              <FinanseDialog val={miesiace[k]['c']['wydatki']['bank']}    ksieg={miesiace[k]['k']['wydatki']['bank']}    uz={miesiace[k]['u']['wydatki']['bank']}    rok={props.rok} m={k} typ="wb"  />
              <FinanseDialog val={miesiace[k]['c']['wydatki']['kasa']}    ksieg={miesiace[k]['k']['wydatki']['kasa']}    uz={miesiace[k]['u']['wydatki']['kasa']}    rok={props.rok} m={k} typ="wk"  last /> */}
              {/* <FinanseKomorka miesiac={props.rok+"-"+k} typ="pch" />
              <FinanseKomorka miesiac={props.rok+"-"+k} typ="pb" />
              <FinanseKomorka miesiac={props.rok+"-"+k} typ="pk" />
              <FinanseKomorka miesiac={props.rok+"-"+k} typ="wch" />
              <FinanseKomorka miesiac={props.rok+"-"+k} typ="wb" />
              <FinanseKomorka miesiac={props.rok+"-"+k} typ="wk" />

            </TableRow>
          ))} */}
          {/* {  console.timeEnd('c')} */}
            <TableRow
              key={"rokN-"+props.rok}
              hover
              // sx={{ borderTop: 1, fontWeight: 700 }}
            >
              <TableCell sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }} component="th" scope="row" align="right">
                sumy w roku:
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                {calosc && sumyRok.przychody.suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && sumyRok.przychody.ksiegowe.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                <i>{sumyRok.bankP.suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                {calosc && sumyRok.kasaP.suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && sumyRok.kasaP.ksiegowe.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                {calosc && sumyRok.wydatki.suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && sumyRok.wydatki.ksiegowe.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                <i>{sumyRok.bankW.suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderTop: 1.5, fontWeight: 700 }}>
                {calosc && sumyRok.kasaW.suma.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && sumyRok.kasaW.ksiegowe.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
            </TableRow>


            {/* {  console.timeEnd('d')} */}

            {/* <TableRow
              key={"rok-"+props.rok}
              hover
              // sx={{ borderTop: 1, fontWeight: 700 }}
            >
              <TableCell sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }} component="th" scope="row" align="right">
                sumy w roku:
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                {calosc && rok['c']['przychody']['chata'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && rok['k']['przychody']['chata'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                <i>{rok['k']['przychody']['bank'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                {calosc && rok['c']['przychody']['kasa'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && rok['k']['przychody']['kasa'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                {calosc && rok['c']['wydatki']['chata'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && rok['k']['wydatki']['chata'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderRight: 1, borderTop: 1.5, fontWeight: 700 }}>
                <i>{rok['k']['wydatki']['bank'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
              <TableCell align="right" sx={{ borderTop: 1.5, fontWeight: 700 }}>
                {calosc && rok['c']['wydatki']['kasa'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}
                {calosc && ksiegowe && <br/>}
                <i>{ksiegowe && rok['k']['wydatki']['kasa'].toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</i>
              </TableCell>
            </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Badge, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchHistoria } from '../api';

export default function HistoriaZmian(props) {
  
  const [open, setOpen] = React.useState(false);
  const listaZmian = useQuery(['historia', props.tabela, props.id], fetchHistoria);
  // console.log(listaZmian);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // console.log(props);


  return (
    <div>
      { listaZmian.isSuccess && listaZmian.data.length > 1 && <Box sx={{ m: 2, cursor: "pointer" }} onClick={handleClickOpen}>
        <Badge anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }} badgeContent={listaZmian.isSuccess && listaZmian.data.length-1} color="info">
              <HistoryEduIcon color="info" />
        </Badge>
      </Box> }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Historia zmian
        </DialogTitle>
        <DialogContent>
            Tabela: {props.tabela},<br/>wiersz: {props.id} - aktualny stan:
            <Box key={"duplikat-"+props.id} fontSize={"small"}><b>{props.id}.</b> coś tu bedzie...<hr/></Box>
            { listaZmian.isSuccess && listaZmian.data && listaZmian.data.map(d=>{
                        // d.oinfo = d.info;
                        // d.oid = d.id;
                        // d = transOsoba(d);
                        return <Box key={"duplikat-"+d.id} fontSize={"small"}><b>{d.akcja}</b><br/>{JSON.stringify(d.dane, null, 2)},<br/>JUser: {d.kto} - {d.kiedy}<hr/></Box>
                }) }
                <br/>
            Tu w przyszłości będzie coś można... ;-)
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Rozumiem
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

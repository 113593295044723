import * as React from "react";
import { 
  MenuItem, 
  TextField, 
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { rodzajOpcje, transNocleg } from "../../komponenty/labelki";

// const NoclegFormularz = React.forwardRef((props, ref) => {
const Nocleg4cNiestandard = (props) => {

  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = props.formularz;

  let nocleg = transNocleg(watch());
  // console.log(nocleg);

  return (
    <React.Fragment>

            <Grid xs={12} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
            <TextField 
              select
              {...register("rodzaj", {
                min: {
                  value: 1,
                  message: 'Wybierz z listy przypadek' // JS only: <p>error message</p> TS only support string
                }
              })}
              value={watch('rodzaj')}
              onChange={(event) => {
                setValue("rodzaj", event.target.value);
                trigger(["rodzaj","oplata","karta"]);
              }}
              label="wskaż przypadek"
              fullWidth
              size="small"
              margin="dense"
              variant="outlined"
              error={errors.rodzaj ? true : false}
              helperText={errors.rodzaj && errors.rodzaj.message}
            >
              {rodzajOpcje.map((option) => (
                <MenuItem key={"rodzaj-"+option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>

            { (  Number(watch("rodzaj")) === 1
              || Number(watch("rodzaj")) === 2 
              || Number(watch("rodzaj")) === 3 
              || Number(watch("rodzaj")) === 4 
              ) && (

              <Grid xs={12} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
                <TextField
                  {...register("oplata", {
                    // shouldUnregister: true,
                    validate: v => {
                      if (watch("rodzaj")===3) {
                        return Number(v) < nocleg.wyliczona || 'Zwolnienie nie może być większe od kwoty wyliczonej - wybierz np. nadpłatę';
                      } else if (watch("rodzaj")===4) {
                        return Number(v) > nocleg.wyliczona || 'Nadpłata musi być większa od wyliczonej - wybierz inny przypadek';
                      } else {
                        return (v !== "" && Number(v) >= 0) || 'Wpisz pobraną kwotę';
                      }
                    }
                  })}
                  label={Number(watch("rodzaj")) === 1 ? "kwota gotówką" : "kwota"}
                  value={watch("oplata")}
                  type="tel"
                  onWheel={(e) => e.target.blur()}
                  onChange={(event) => {
                    setValue("oplata",event.target.value.replace(',', '.'));
                    trigger("oplata");
                  }}
                  error={errors.oplata ? true : false}
                  helperText={errors.oplata && errors.oplata.message}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px" },
                    '& .MuiFormControl-root': { m: 0 },
                  }}
                /> 
              </Grid>
              )
            }

            { Number(watch("rodzaj")) === 1 && (
              <Grid xs={12} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
                <TextField
                  {...register("karta", {
                    // shouldUnregister: true,
                    validate: v => Number(v) > 0 || 'Kwota musi być większa od 0 - wybierz inny przypadek',
                  })}
                  label="kwota płatności niegotówkowej"
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  value={watch("karta")}
                  type="tel"
                  onWheel={(e) => e.target.blur()}
                  onChange={(event) => {
                    setValue("karta",event.target.value.replace(',', '.'));
                    trigger(["karta","oplata"]);
                  }}
                  error={errors.karta ? true : false}
                  helperText={errors.karta && errors.karta.message}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px 14px" },
                    '& .MuiFormControl-root': { m: 0 },
                  }}
                /> 
              </Grid>
            )}
          
            {(   Number(watch("rodzaj")) === 2 
              || Number(watch("rodzaj")) === 3 
              || Number(watch("rodzaj")) === 4 
              ) && (
                <Grid xs={12} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
                <TextField
                  {...register("nuwagi")}
                  label="uwagi"
                  multiline
                  rows={2}
                  placeholder="tu wpisz uwagi do tego konkretnego noclegu"
                  value={watch("nuwagi")}
                  onChange={(event) => {
                    setValue("nuwagi",event.target.value);
                  }}
                  error={errors.uwagi ? true : false}
                  helperText={errors.nuwagi && errors.nuwagi.message}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: 0 },
                    '& .MuiFormControl-root': { m: 0 },
                  }}
                /> 
              </Grid>
            )}

    </React.Fragment>
  );
}

export default Nocleg4cNiestandard;

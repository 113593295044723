import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from '../api';
import ButtonNoclegDodaj from './ButtonNoclegDodaj';
import KomunikatyLadowanie from '../komponenty/KomunikatyLadowanie';
import MUIDataTable from "mui-datatables";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import NoclegiWiersz from './NoclegiWiersz';



export default function NoclegiLista() {

    const operacje = useQuery(['dane','noclegi'], fetchDane, {refetchOnWindowFocus: false});
    const osoby = useQuery(['dane','osoby'], fetchDane, {refetchOnWindowFocus: false});
    const grupy = useQuery(['dane','odwiedziny'], fetchDane, {refetchOnWindowFocus: false});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // console.log(osoby);
    
    // ###########################################################################################33
    const options = {
      customRowRender: (n)=><NoclegiWiersz nid={n[1]} key={n[1]} noclegi />,
      rowsPerPage: 30,
      rowsPerPageOptions: [10,30,100,300],
      selectableRowsHideCheckboxes: true,
      viewColumns: false,
      print: false, // ukrycie ikony drukowania
      // searchOpen: true,
      download: false, // ukrycie ikony eksportu do csv
      sortOrder: {
        name: 'id',
        direction: 'desc'
      },
      setFilterChipProps: () => {return{ sx: { display: 'none' } }}, // ukrycie reguł filtrowania
      searchPlaceholder: "przeszukuj po: nazwisku, nazwie grupy, dacie przyjścia, numerze...",
      textLabels: { // polonizacja... 
        body: {
          noMatch: "Nie udało się załadować żadnych noclegów...",
          toolTip: "Sortuj",
          columnHeaderTooltip: column => `Sortowanie ${column.label}`,
        },
        pagination: {
          next: "następna strona",
          previous: "poprzednia strona",
          rowsPerPage: "wierszy na stronę:",
          displayRows: "z",
        },
        toolbar: {
          search: "Szukaj",
          downloadCsv: "Pobierz CSV",
          print: "Drukuj",
          viewColumns: "Kolumny widoczne",
          filterTable: "Filtrowanie tabeli",
        },
        filter: {
          all: "wszystkie",
          title: "filtrowanie wg kryteriów ",
          reset: " resetuj",
        },
        viewColumns: {
          title: "Pokaż kolumny",
          titleAria: "Pokaż/Ukryj kolumny Tabeli",
        },
        selectedRows: {
          text: "zaznaczono",
          delete: "Usuń",
          deleteAria: "Usuń zaznaczone wiersze",
        },
      },
    };
  
  
    // ###########################################################################################33
    const columns = [
      {
        name: 'kategoria',
        label: 'kategoria noclegu',
        options: {
          customHeadLabelRender: ()=>"",
          // headerAlign: 'right',
          sort: false,
          filterType: 'multiselect',
          filterOptions: {
            fullWidth: true,
            renderValue: v => (
              (v===1 && "Chata") ||
              (v===2 && "Student") ||
              (v===3 && "Namiot") ||
              (v===9 && "Lektorium") ||
              (v===4 && "Dziecko") ||
              (v===5 && "Klub Otrycki") ||
              (v===6 && "Gość Gospodarza") ||
              (v===7 && "Inne")
            )},
          customFilterListOptions: { 
            render: v=>null,
          },
        }
      },
      { 
        name: 'id', 
        label: 'ID', 
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: 'dataod',
        label: 'przyjście',
        options: {
          filter: false,
        }
      },
      {
        name: 'rodzaj',
        label: 'rodzaj opłaty',
        options: {
          customHeadLabelRender: ()=>"",
          // headerAlign: 'right',
          sort: false,
          filterType: 'multiselect',
          filterOptions: {
            fullWidth: true,
            renderValue: v => (
              (v===0 && "opłata standardowa") ||
              (v===1 && "karta / przelew") ||
              (v===2 && "obóz") ||
              (v===3 && "zwolnienie") ||
              (v===4 && "nadpłata") ||
              (v===5 && "Gość Gospodarza")
            )},
          customFilterListOptions: { 
            render: v=>null,
          },
        }
      },
      {
        name: 'idosoby',
        label: 'idosoby',
        options: {
          customHeadLabelRender: ()=>"",
          filter: false,
        }
      },
      {
        name: 'idosoby',
        label: 'osoba',
        options: {
          customHeadLabelRender: ()=>"",
          customBodyRender: (value) => { 
            let osoba = value && osoby.isSuccess && osoby.data.osoby.filter(o=>o.id===value)[0];
            return osoba && osoba.imie+" "+osoba.nazwisko+" - "+osoba.PESEL; 
          },
          filter: false,
        }
      },
      {
        name: 'gid',
        label: 'grupa',
        options: {
          customHeadLabelRender: ()=>"",
          customBodyRender: (value) => { 
            let grupa = value && grupy.isSuccess && grupy.data.odwiedziny.filter(o=>o.id===value)[0];
            return grupa ? grupa.id+". "+grupa.nazwa : "0. bez grupy"; 
          },
          // display: true,
          // filter: false,
        }
      },
    ];
  




  return (
    <div style={{ width: '100%' }}>
    <KomunikatyLadowanie stany={operacje} />
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>

    <MUIDataTable
        data={operacje.isSuccess ? operacje.data.noclegi : undefined}
        columns={columns}
        options={options}
    />

    <ButtonNoclegDodaj dodawanie 
      pozycja={{ 
        position: fullScreen?'fixed':'relative', 
        bottom: fullScreen?'2em':'3.2em', 
        left: fullScreen?"2em":'25%', 
        zIndex: 'modal',
      }} 
    />
    <br/><br/><br/>
    </div>
    </div>
    </div>
    
  );
}
// actions: https://mui.com/components/data-grid/columns/#special-properties
import * as React from "react";
// import { serialize } from "object-to-formdata";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchDane, postPlik } from "../api";
import KomunikatyWysylka from "../komponenty/KomunikatyWysylka";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pl';
import {DropzoneArea} from 'mui-file-dropzone'


// TODO: usupełnić schemat walidacji - data, typ pliku - obowiązkowe
///// schemat walidacji pól formularza ///////////////////////////////////////
const schema = yup.object().shape({
        data: yup.string().nullable().required("Podaj datę wystawienia faktury, podpisania dokumentu lub zrobienia zdjęcia"),
        katpliku: yup.string().required("Wybierz rodzaj pliku"),
        nazwa: yup.string().required("Wpisz jakąś nazwę dla pliku"),
  });
// ############################################################################

function PlikFormularz(props) {
  
  const startoweDane = {
    data: "",
    katpliku: "",
    nazwa: "",
    opis: "",
    tabela: props.row.tabela,
    wiersz: props.row.id
  };
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const operacje = useQuery(['dane'], fetchDane);

  const [files, setFiles] = React.useState([]);
  // console.log("files: ", files);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    // resetField,
    trigger,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: startoweDane,
    resolver: yupResolver(schema),
    // mode: "onSubmit",
    // reValidateMode: "onSubmit" | "onBlur" | "onChange",
    // reValidateMode: "onSubmit" | "onChange",
  });

  const queryClient = useQueryClient();
  const mutatePlik = useMutation(plik => postPlik(['x'],plik), {
    onError: (rollback) => {
      rollback();
    },
    onSuccess: data => {
      // console.log(data);
      // console.log(pliki,pliki_polaczenia); // OK
      let newPliki = operacje.isSuccess && operacje.data.pliki.filter(p=>p.id!==data.data.plik.id);
      let newPolaczenia = operacje.isSuccess && operacje.data.pliki_polaczenia.filter(p=>p.id!==data.data.polaczenie.id);
      newPliki.push(data.data.plik);
      newPolaczenia.push(data.data.polaczenie);
      // console.log(newPliki,newPolaczenia); // zle - zdefiniowany count
      let newOper = { 
        ...operacje.data, 
        pliki: newPliki,
        pliki_polaczenia: newPolaczenia,
      };
      // console.log(newOper);
      queryClient.setQueryData(['dane'], newOper);
      
      setTimeout(function () {
        mutatePlik.reset(); // resetowanie stanów mutacji
        reset(startoweDane); // resetowanie formularza
        props.setOpen(false); // żeby wymusić odświeżenie listy...
      }, 1500);
    },
  });

  const onSubmit = (data) => {
    let dane = {
      formularz: watch(), 
      pliki: files
    };
    !mutatePlik.isLoading && mutatePlik.mutate(dane);
  };

  let opcje=[
    { value: "faktura", label: "faktura" },
    { value: "zdjecie", label: "zdjęcie" },
    { value: "dokument", label: "dokument" },
  ];

  // console.log(props);

  return (
    <React.Fragment>
      <KomunikatyWysylka stany={mutatePlik} />
      <Box component="form" sx={{ mt: 2 }}>
        <Grid container spacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
          <Grid item xs={12} md={3} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
            <DatePicker 
              {...register("data")}
              label="data powstania"
              value={watch("data")?dayjs(watch("data")):null}
              id="dataPliku"
              format="YYYY-MM-DD"
              onChange={(event) => {
                setValue("data", event.format("YYYY-MM-DD"));
              }}
              clearable
              slotProps={{
                textField: {
                  error: errors.data ? true : false,
                  helperText: errors.data ? errors.data.message : false,
                  size: 'small',
                },
              }}
              localeText={{ datePickerToolbarTitle: 'data powstania' }}
              sx={{ 
                '& .MuiOutlinedInput-input': { backgroundColor: "none",pt:1,pb:1 },
                '& .MuiInputLabel-root': { zIndex: 0 }
              }}
            /> 
          </Grid>
        </LocalizationProvider>

          <Grid item xs={12} md={3} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
          <TextField
            select
            {...register("katpliku")}
            value={watch("katpliku")}
            onChange={(event) => {
              setValue("katpliku", event.target.value);
              trigger();
            }}
            label="kategoria"
            error={errors.katpliku ? true : false}
            helperText={errors.katpliku && errors.katpliku.message}
            variant="outlined"
            size="small"
            margin="dense"
            sx={{ 
              '& .MuiOutlinedInput-input': { p: "8.5px" } 
            }}
          >
            {opcje.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          </Grid>
            
          <Grid item xs={12} md={6} sx={{ '& .MuiFormControl-root': { m: 0 } }}>
          <TextField
            {...register("nazwa")}
            id="nazwa"
            value={watch("nazwa")}
            onChange={(event) => {
              setValue("nazwa", event.target.value);
              trigger();
            }}
            label="nazwa"
            error={errors.nazwa ? true : false}
            helperText={errors.nazwa && errors.nazwa.message}
            variant="outlined"
            size="small"
            margin="dense"
            sx={{ 
              '& .MuiOutlinedInput-input': { p: "8.5px" } 
            }}
        />
          </Grid>
          
          <Grid item xs={12} sx={{'& .MuiFormControl-root': { mt: 0 }}} >
          <TextField
            {...register("opis")}
            id="opis"
            value={watch("opis")}
            onChange={(event) => {
              setValue("opis", event.target.value);
            }}
            label="opis"
            multiline
            rows={3}
            error={errors.opis ? true : false}
            helperText={errors.opis && errors.opis.message}
            variant="outlined"
            size="small"
            margin="dense"
            sx={{ 
              '& .MuiOutlinedInput-input': { p: 0 },
              '& .MuiFormControl-root': { m: 0 },
            }}
          />
          </Grid>
          
          <Grid item xs={12}>
            <DropzoneArea
              onChange={setFiles}
              maxFileSize={99999999}
              showFileNames
              showFileNamesInPreview
              showPreviews
              showPreviewsInDropzone={false}
              previewText="podgląd: "
              filesLimit={1}
              acceptedFiles={['image/jpeg','application/pdf']}
              dropzoneText="dodaj plik"
              dropzoneClass="otryckaDropZone"
              sx={{ innerHeight: 400, border: "1 solid red" }}
            />

          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Button 
              variant="outlined" 
              disabled={files.length>0?false:true||mutatePlik.isLoading} 
              endIcon={<SendIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              zapisz
            </Button>
            <br/>
          </Grid>
        </Grid>
      </Box>
      <br/>
    </React.Fragment>
  );
}

export default PlikFormularz;

import * as React from 'react';
import PropTypes from 'prop-types';
import Autocomplete, { autocompleteClasses, createFilterOptions } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
// import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from '../api';
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';
import { Button } from '@mui/material';
import { PeselDecode, transOsoba } from './labelki';
import { matchSorter } from "match-sorter";

const LISTBOX_PADDING = 1; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
};




  
  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {/* {`#${dataSet[2] + 1} - ${dataSet[1]}`} */}
      {`${dataSet[1].id}`}. {`${dataSet[1].imie}`} {`${dataSet[1].nazwisko}`} - PESEL: {`${dataSet[1].PESEL}`}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.array,
};

// const StyledPopper = styled(Popper)({
//   [`& .${autocompleteClasses.listbox}`]: {
//     boxSizing: 'border-box',
//     '& ul': {
//       padding: 0,
//       margin: 0,
//     },
//   },
// });

const StyledPopper = styled('div')(({ theme }) => ({
        [`& .${autocompleteClasses.paper}`]: {
          zIndex: 2000,
          fontSize: 13,
          position: "absolute",
          left: 0,
          margin: "1em",
          width: "95vw",
        },
        [`&.${autocompleteClasses.popperDisablePortal}`]: {
          position: 'relative',
        },
}));

const AutoVirOsoba = React.forwardRef((props, ref) => {

        // const operacje = useQuery(['operacje'], fetchOperacje);
        // console.log(props.stan);
        const operacje = useQuery(['dane'], fetchDane);
        // console.log(operacje);

        let osoby = operacje.isSuccess && operacje.data.osoby;
        let funkcje = operacje.isSuccess && operacje.data.ofunkcje;
        // console.log(osoby);

        const [value, setValue] = React.useState(props.stan.idosoby);
        React.useEffect(() => {
          setValue(props.stan.idosoby);
        }, [props.stan.idosoby]);
        
        // wyszukanie osób na podstawie danych z formularza:
        if (osoby) {
              if (props.stan.PESEL) { osoby = matchSorter(osoby, props.stan.PESEL, {keys: ['PESEL']}); }
              if (props.stan.imie) { osoby = matchSorter(osoby, props.stan.imie, {keys: ['imie'], keepDiacritics: false}); }
              if (props.stan.nazwisko) { osoby = matchSorter(osoby, props.stan.nazwisko, {keys: ['nazwisko']}); }
        }
        // console.log(osoby);
    
  return ( 
    <Autocomplete
        id="osoba"
        ref={ref}
        value={value}
        onChange={(e,v) => {
          v.funkcje = funkcje.filter(o=>o.idosoby===v.id);
          props.onChange(transOsoba(v));
          setValue(v.id);
        }}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={osoby}
        getOptionLabel={o=>o.id+". "+o.imie+" "+o.nazwisko+" - PESEL: "+o.PESEL}
        isOptionEqualToValue={(option, value) => option.id === value}
        filterOptions={createFilterOptions({
                matchFrom: 'start',
                trim: true,
                limit: 50,
        })}
        //       groupBy={(option) => option && option[0].toUpperCase()}
        disabled={(osoby.length>99||osoby.length===0||(osoby.length===1&&PeselDecode(props.stan.PESEL).valid))?true:false}
        renderInput={(params) => (
          <div 
                  ref={params.InputProps.ref}
          >
            <Button 
                  variant="outlined"
                  fullWidth
                  {...params.inputProps}
                  sx={{ mt: "2px" }}
            >znaleziono osób: {osoby.length>99?">99":osoby.length} <SelfImprovementOutlinedIcon sx={{ ml:2,mr:2 }} /> 
            { osoby.length===0 && "dodaj nową osobę..." }
            { PeselDecode(props.stan.PESEL).valid && osoby.length===1 && "ustawiona jest znaleziona osoba" }
            { !(PeselDecode(props.stan.PESEL).valid && osoby.length===1) && osoby.length>0 && osoby.length<99 && "wybierz jedną lub dodaj nową." }
            { osoby.length>99 && "ogranicz kryterium" }
            </Button>
            <input type="hidden" value={value} {...params.inputProps} />
          </div>
        )}
        renderOption={(props, option, state) => [props, option, state.index]}
        // TODO: Post React 18 update - validate this conversion, look like a hidden bug
        renderGroup={(params) => params}
        size="small"
        freeSolo
    /> 
  );
});
export default AutoVirOsoba;

import * as React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Naglowek, { pages } from './statyczne/Naglowek';
import OdwiedzinyLista from './odwiedziny/OdwiedzinyLista';
import WydatkiLista from './wydatki/WydatkiLista';
import NoclegiLista from './noclegi/NoclegiLista';
import FinanseStart from "./finanse/FinanseStart";
import { fetchDane } from './api';
import { Container, Link, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import Wprowadzenie from './statyczne/Wprowadzenie';
import MigracjeFormularz from './formularze/MigracjeFormularz';
import { opcjeLista } from './komponenty/labelki';
import KomunikatyLadowanie from './komponenty/KomunikatyLadowanie';
import RaportyKasowe from './finanse/rk/RaportyKasowe';
import { sumyLatamiMiesiacami } from './finanse/sumy';

function App() {

  const cred = useQuery(['dane','cred'], fetchDane);
  const queryClient = useQueryClient();

  function wypelnijDanymi(data) {

    let imiona = opcjeLista(data.osoby,'imie');
    let nazwiska = opcjeLista(data.osoby,'nazwisko');
    queryClient.prefetchQuery(['imiona'], ()=>imiona);
    queryClient.prefetchQuery(['nazwiska'], ()=>nazwiska);

    Object.keys(data).map(klucz=>{
      queryClient.prefetchQuery(['dane',klucz], ()=>{
        return {[klucz]: data[klucz]};
      });
      klucz!=="cred" && data[klucz] && data[klucz].forEach((obiekt) => {
        queryClient.prefetchQuery(['dane',klucz,{id:obiekt.id}], ()=>{
          return {[klucz]: [obiekt]};
        });
      });
      return true;
    });

    if(cred.data.cred.grupa==="GOSP" || cred.data.cred.grupa==="RADA" || cred.data.cred.grupa==="SKAR"){
      let sumyML = sumyLatamiMiesiacami(data);
      queryClient.prefetchQuery(['sumyMiesieczne'], ()=>sumyML.sumyMiesieczne);
      queryClient.prefetchQuery(['sumyRoczne'], ()=>sumyML.sumyRoczne);
    }
  }

  queryClient.setQueryData(['badzeSumy'], true);
  queryClient.setQueryData(['ksiegowe'], false);
  queryClient.setQueryData(['calosc'], true);

  console.time("pobieranie");
  const dane = useQuery(['dane'], fetchDane, { // przepisanie całego obiektu danych na klucze zbiorów i elementów
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      wypelnijDanymi(data);
    },
  });

  let canEdit = false;
  
  if (cred.isSuccess && (cred.data.grupa==="GOSP" || cred.data.grupa==="RADA" || cred.data.grupa==="SKAR")) {
    canEdit = true;
  }
  const theme = useTheme();
  const desk = useMediaQuery(theme.breakpoints.up('sm'));
  let location = useLocation();
  const sciezka = pages.filter(e=>e.url===location.pathname).length;


  return (
      <div className="App">
        { sciezka===0 && <>
        <Wprowadzenie />
        { cred.isSuccess && cred.data.grupa==="PUBL" && <Typography component={"h3"} sx={{ m: "1em", right: "2em", zIndex: 'modal' }}><Link color="primary" href="/zaloguj">Zaloguj się</Link>, by móc dodać zgłoszenie</Typography> }
        { dane.isSuccess && <OdwiedzinyLista /> }
        { cred.isSuccess && cred.data.grupa==="PUBL" && <Typography component={"h3"} sx={{ m: "1em", mt: "2em", left: "2em", zIndex: 'modal' }}><Link color="primary" href="/zaloguj">Zaloguj się</Link>, by móc dodać zgłoszenie</Typography> }
        </> }
        { sciezka!==0 && <>
        <Naglowek />
        { cred.isSuccess && cred.data.grupa!=="PUBL" && <KomunikatyLadowanie stany={dane} /> }
        { dane.isSuccess && desk && <Container maxWidth="xl">
        <Routes>
          <Route path={"/mobi"+process.env.REACT_APP_URL_PRZYROSTEK} element={<OdwiedzinyLista />} />
          { canEdit && <Route path={"/mobi/noclegi"+process.env.REACT_APP_URL_PRZYROSTEK} element={<NoclegiLista />} /> }
          { canEdit && <Route path={"/mobi/wydatki"+process.env.REACT_APP_URL_PRZYROSTEK} element={<WydatkiLista />} /> }
          { canEdit && <Route path={"/mobi/rk"+process.env.REACT_APP_URL_PRZYROSTEK} element={<RaportyKasowe />} /> }
          { canEdit && <Route path={"/mobi/siwko"+process.env.REACT_APP_URL_PRZYROSTEK} element={<FinanseStart />} /> }
          { canEdit && <Route path={"/mobi/migracje"+process.env.REACT_APP_URL_PRZYROSTEK} element={<MigracjeFormularz />} /> }
        </Routes>
        </Container> }
        
        { dane.isSuccess && !desk && <Routes>
          <Route path={"/mobi"+process.env.REACT_APP_URL_PRZYROSTEK} element={<OdwiedzinyLista />} />
          { canEdit && <Route path={"/mobi/noclegi"+process.env.REACT_APP_URL_PRZYROSTEK} element={<NoclegiLista />} /> }
          { canEdit && <Route path={"/mobi/wydatki"+process.env.REACT_APP_URL_PRZYROSTEK} element={<WydatkiLista />} /> }
          { canEdit && <Route path={"/mobi/rk"+process.env.REACT_APP_URL_PRZYROSTEK} element={<RaportyKasowe />} /> }
          { canEdit && <Route path={"/mobi/siwko"+process.env.REACT_APP_URL_PRZYROSTEK} element={<FinanseStart />} /> }
          { canEdit && <Route path={"/mobi/migracje"+process.env.REACT_APP_URL_PRZYROSTEK} element={<MigracjeFormularz />} /> }
        </Routes> }
        </> }
    </div>
  );
}

export default App;

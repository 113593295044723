import { serialize } from "object-to-formdata";
// import PouchDb from "pouchdb-browser";
// import upsert from 'pouchdb-upsert';
// import { parseHTML } from "../komponenty/labelki";
import { token } from "./index"


// zapisanie zgłoszenia Odwiedzin:
export const postOdwiedziny = async (key, grupa) => {

  let formData = serialize(
    { 
      grupa: grupa,
      [token]: 1,
    }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { booleansAsIntegers: true, indices: true }
  );

  let dane = fetch(
    process.env.REACT_APP_API_DOMAIN +
      "/index.php?option=com_content&task=api.osave&format=json",
    {
      method: "POST",
      body: formData,
    }
  ).then(res => res.json()).then(json=>json);
  // console.log(dane);

  return dane;
};  




// zapisanie Noclegu:
export const postNocleg = async (key, nocleg) => {
  // console.log(nocleg);
  let formData = serialize(
    { 
      nocleg: nocleg, 
      [token]: 1
    }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { booleansAsIntegers: true, indices: true }
  );

  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "/index.php?option=com_content&task=api.nsave&format=json",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
};  






// ######################################################################################################
// zapisanie Wydatku
export const postWydatek = async (key, wydatek) => {

  let formData = serialize(
    { wydatek: wydatek.formularz, [token]: 1 }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { 
      booleansAsIntegers: true, 
      indices: true,
      // noFilesWithArrayNotation: true,
    }
  );
  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "/index.php?option=com_content&task=api.wsave&format=json",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
};  







// #####################################################################################################################
// zapisanie Uzupełnień
export const postUzupelnienia = async (key, uz) => {

  let formData = serialize(
    { uz: uz, [token]: 1 }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { 
      booleansAsIntegers: true, 
      indices: true,
      // noFilesWithArrayNotation: true,
    }
  );
  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "/index.php?option=com_content&task=api.usave&format=json",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
};  






// #####################################################################################################################
// zapisanie Raportu Kasowego
export const postRapKas = async (key, rk) => {

  let formData = serialize(
    { rk: rk, [token]: 1 }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { 
      booleansAsIntegers: true, 
      indices: true,
      // noFilesWithArrayNotation: true,
    }
  );
  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "/index.php?option=com_content&task=api.rksave&format=json",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
};  






// ###############################################################################################################
// usunięcie wiersza we wskazanej tabeli
export const deleteObiekt = async (key, o) => {
  let formData = serialize(
    { tabela: o.tabela, id: o.wiersz, [token]: 1 }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { booleansAsIntegers: true, indices: true }
  );

  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "/index.php?option=com_content&task=api.delete&format=json",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
};  







// wysłanie danych do importu
export const postMigracje = async (key, mig) => {

  let formData = serialize(
    { mig: mig.formularz, [token]: 1 }, // zamiana listy pól formularza na tablicę formularza komponentu Joomla.
    { 
      booleansAsIntegers: true, 
      indices: true,
      // noFilesWithArrayNotation: true,
    }
  );
  mig.pliki && mig.pliki.map((file,i)=>{ // dołączenie wszystkich plików do obiektu formData:
    formData.append('zalaczniki['+i+']', mig.pliki[i]);
    return true;
  });

  const response = await fetch(
    process.env.REACT_APP_API_DOMAIN +
      "/index.php?option=com_content&task=api.migracje&format=json",
    {
      method: "POST",
      body: formData,
    }
  );
  return response.json();
};  

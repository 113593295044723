import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Fab, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditIcon from '@mui/icons-material/Edit';
import WydatekFormularz from './WydatekFormularz';
// import { transWydatek } from '../komponenty/labelki';

export default function ButtonWydatekDodaj(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
        setOpen(false);
  };

  // console.log(props);

  return (
    (<React.Fragment>
      {props.button && ( // button edycji wydatku - widoczny w szczegółach listy wydatków (WydatkiLista)
          (<Fab
            color="primary"
            aria-label="edit"
            sx={props.pozycja}
            size="small"
            disabled={props.disabled}
            onClick={handleClickOpen}
          >
            <EditIcon />
          </Fab>)
      )}

      {/* {props.dodajwgrupie && <Button
        color="primary"
        aria-label="edit"
        variant="outlined"
        onClick={handleClickOpen}
        size="small"
        disabled={props.disabled}
      >dodaj wydatek</Button>} */}

      { fullScreen && props.dodawanie && <Fab
        color="primary"
        aria-label="edit"
        sx={{
          position: 'fixed', 
          bottom: '2em', 
          left: "2em",
          zIndex: 'modal' 
        }}
        onClick={handleClickOpen}
        disabled={props.disabled}
        size={props.size ? props.size : ""}
      >
        {props.dodawanie ? (
          <AddCircleOutlineIcon fontSize="large" />
        ) : (
          <GroupAddIcon />
        )}
      </Fab> }

      { !fullScreen && props.dodawanie && <Button
        color="primary"
        aria-label="edit"
        variant="outlined"
        sx={{
          position: 'relative', 
          bottom: '46px', 
          left: '20px', 
          zIndex: 'modal' 
        }}
        onClick={handleClickOpen}
        size={props.size ? props.size : ""}
        disabled={props.disabled}
      >{props.dodawanie?<><AddCircleOutlineIcon sx={{ mr: 1 }} /> Dodaj wydatek</>:<><GroupAddIcon sx={{ mr: 1 }} /> Edytuj wydatek</>}</Button> }

      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Fab
            size="small"
            aria-label="zamknij"
            sx={{ position: "absolute", top: "1em", right: "1em" }}
            onClick={handleClose}
          >
            <CloseOutlinedIcon />
          </Fab>
          {props.w?"Edytuj":"Dodaj"} wydatek {props.w&&" - ID: "+props.w.id}
        </DialogTitle>

        <WydatekFormularz wydatek={props.wydatek} setOpen={setOpen} />

      </Dialog>
    </React.Fragment>)
  );
}

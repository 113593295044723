import * as React from 'react';
import dayjs from 'dayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableHead, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fetchDane } from '../api';
import { sumyMiesiacIpos } from './sumy';
// import { useQueryClient } from '@tanstack/react-query';

export default function TabelaIPOS(props) {

  // console.log(props);
  const miesiac = dayjs(props.dane?props.dane.data:props.miesiac).format("YYYY-MM");
  
  const operacje = useQuery(['dane','ipos'], fetchDane, {refetchOnWindowFocus: false});
  let dane = operacje.isSuccess && sumyMiesiacIpos(operacje.data.ipos.filter(o=>
        dayjs(o.czas).format("YYYY-MM")===miesiac
        && ((props.noclegi && o.kat==="Noclegi") || (!props.noclegi && o.kat!=="Noclegi"))
        ));
  let rows = props.noclegi ? dane.noclegi : dane.sprzedaz;

  return (
    <React.Fragment>
    <Typography variant="h6">{props.noclegi ? "Noclegi zarejestrowane w kasoterminalu":"Sprzedaż zarejestrowana w kasoterminalu"}:</Typography>
    <hr/>
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ borderRight: 1 }} align="center" rowSpan={2}><b>kategoria</b></TableCell>
            <TableCell sx={{ borderRight: 1 }} align="center" colSpan={2}><b>gotówka</b></TableCell>
            <TableCell sx={{ borderRight: 1 }} align="center" colSpan={2}><b>karta / przelew</b></TableCell>
            <TableCell align="center" rowSpan={2}>                        <b>operacji</b></TableCell>
          </TableRow>
          <TableRow sx={{ borderBottom: 2 }}>
            <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right"> <b>noclegi</b></TableCell>
            <TableCell sx={{ borderRight: 1 }} align="right">                           <b>opłaty</b></TableCell>
            <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right"> <b>noclegi</b></TableCell>
            <TableCell sx={{ borderRight: 1 }} align="right">                           <b>opłaty</b></TableCell>
          </TableRow>
        </TableHead> 
        
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ borderRight: 1 }} align="right" component="th" scope="row">
                <b>{row.name}</b>
              </TableCell>
              <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{row.liczba}</TableCell>
              <TableCell sx={{ borderRight: 1 }} align="right">{row.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
              <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{row.karta_liczba}</TableCell>
              <TableCell sx={{ borderRight: 1 }} align="right">{row.karta_kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
              <TableCell align="right">{row.operacji}</TableCell>
            </TableRow>
          ))}
            <TableRow
              sx={{ borderTop: 2 }}
              // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ borderRight: 1 }} align="right" component="th" scope="row">
                <b>Sumy</b>
              </TableCell>
              <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{dane.liczba}</TableCell>
              <TableCell sx={{ borderRight: 1 }} align="right">{dane.kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
              <TableCell sx={{ borderRight: 1, borderRightColor: "#ccc" }} align="right">{dane.karta_liczba}</TableCell>
              <TableCell sx={{ borderRight: 1 }} align="right">{dane.karta_kwota.toLocaleString('pl', {minimumFractionDigits: 2, maximumFractionDigits: 2})+" zł"}</TableCell>
              <TableCell align="right">{dane.operacji}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
}

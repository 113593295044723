import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pl';

import {
  // Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControlLabel,
  // Grid,
  Link,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import CFButtonSwitch from "../komponenty/ButtonSwitch";
import CFButtonToggle from "../komponenty/ButtonToggle";
import dayjs from "dayjs";
import SaveIcon from '@mui/icons-material/Save';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchDane, postOdwiedziny } from "../api";
import KomunikatyWysylka from "../komponenty/KomunikatyWysylka";
// import { fetchOperacje } from "../api/Finanse";
import { kategoriaOpcje } from "../komponenty/labelki";

///// schemat walidacji pól formularza ///////////////////////////////////////
const schema = yup.object().shape({
    nazwa: yup.string().required("Wpisz jakąś nazwę dla swojego zgłoszenia ;-)"),
    od: yup.string().nullable().required("Musisz podać datę przyjścia"),
    do: yup.string().nullable().required("Musisz podać datę wyjścia"),
    liczba: yup
      .number().typeError("Wpisz ile osób będzie razem z Tobą")
      .min(1, "jeśli będziesz sam, to wpisz 1")
      .max(50, "w Chacie nie zmieści się więcej niż 50 osób...")
      .required("Wpisz ile osób będzie razem z Tobą"),
    noclegchata: yup
      .string()
      .nullable()
      .required("Wybierz czy chcesz spać w Chacie, czy poza Chatą?"),
    info: yup.string().required("Napisz choć dwa słowa o sobie..."),
    zapoznalem: yup.boolean().oneOf([true], "Musisz zapoznać się z Regulaminem :-)"),
  });
  // console.log(schema);
// ############################################################################

const OdwiedzinyFormularz = React.forwardRef((props, ref) => {

  // let token = "asdadadadadsadad";
  // if (process.env.REACT_APP_TYP !== "dev") token = document.querySelector("div#token input").name;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
 
  const [zwin, setZwin] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [drugiCal, setOpenDrugiCal] = React.useState(false);
  const [grupa, setGrupa] = React.useState(props.row);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const cred = useQuery(['dane','cred'], fetchDane);
  const operacje = useQuery(['dane','odwiedziny'], fetchDane);
  var odwiedziny = operacje.isSuccess && operacje.data.odwiedziny;

  // console.log(cred);
  let isViewer = false;
  let isEditor = false;
  // let isOwner = false;

  if (cred.isSuccess) {
    if (cred.data.grupa === "GOSP") {
      isEditor = true;
    }
    if (cred.data.grupa === "RADA") {
      isViewer = true;
    }
    // if (cred.data.userID === grupa.created_by) {
    //   isOwner = true;
    // }
  }

  const {
    register,
    handleSubmit,
    // resetField,
    setValue,
    trigger,
    watch,
    // reset,
    formState: { errors },
  } = useForm({
    defaultValues: grupa,
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();      
  const mutateOdwiedziny = useMutation(grupa => postOdwiedziny(['x'],grupa)
    , {
      onError: (rollback) => {
        rollback()
      },
      onSettled: data => {
        // console.log({odwiedziny: data.data})
        queryClient.setQueryData(['dane','odwiedziny',{id: data.data[0].id}], {odwiedziny: data.data});
        if (data.data[0].created===data.data[0].modified) {
          // odwiedziny.push(data.data[0]);
          odwiedziny = [...odwiedziny, data.data[0]];
          queryClient.setQueryData(['dane','odwiedziny'], {odwiedziny: odwiedziny});
        }
  
        // let newLista = odwiedzinyLista.filter((o) => o.id !== data.data[0].id);
        // newLista.push(data.data[0]);
        // let newOper = { 
        //   ...operacje.data, 
        //   odwiedziny: newLista,
        // };
        // // console.log(operacje,newOper);
        // queryClient.setQueryData(['operacje'], newOper);

        setGrupa(data.data[0]);
        props.resetujGrupe && props.resetujGrupe(data.data[0]);
        setTimeout(function () {
          mutateOdwiedziny.reset();
          setOpen(false);
        }, 2000);
      }
    }
  );
  // console.log(mutateOdwiedziny);

  React.useEffect(() => {
    if ( props.row.id === "nowa" && props.row.nazwa ) {
      setOpen(true);
      setZwin(false);
    }
  }, [props.row]);


// ###################################################################################
  const handleClose = () => {
    if ( grupa.id === "nowa" ) { setOpenConfirm(true); }
    else { 
      setOpen(false); 
    }
  };

  const handleConfirm = () => {
    if ( props.row.id === "nowa" && props.row.nazwa ) {
      props.resetujGrupe && props.resetujGrupe();
    }
    setOpen(false);
    setOpenConfirm(false);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleZwin = () => {
    setZwin((zwin)=>!zwin);
  };
  const opcje = [
    { value: 1, label: "zaakceptowane" },
    { value: 0, label: "oczekujące" },
    { value: 2, label: "anulowane" },
    { value: 3, label: "do kontaktu..." },
    { value: -2, label: "usunięte" },
    { value: -3, label: "dodana przez Gospodarza" },
  ];




  const onSubmit = (data) => {
    !mutateOdwiedziny.isLoading && mutateOdwiedziny.mutate(grupa);
  }

  // console.log(grupa);
  // console.log(watch());
// #######################################################################################
  return (
    <>
      {fullScreen && <Fab
        color="primary"
        aria-label="edit"
        sx={props.pozycja ? props.pozycja : { bottom: "0", left: "0" }}
        onClick={handleClickOpen}
      >
        { (props.row.id === "nowa") ? <AddCircleOutlineIcon fontSize="large" /> : <EditIcon /> }
      </Fab> }
      {!fullScreen && <Button
        color="primary"
        variant="outlined"
        aria-label="edit"
        sx={props.pozycja ? props.pozycja : { bottom: "0", left: "0" }}
        onClick={handleClickOpen}
      >{props.row.id==="nowa"?<><AddCircleOutlineIcon sx={{ mr: 1 }} /> Dodaj zgłoszenie</>:<><EditIcon sx={{ mr: 1 }} /> Edytuj zgłoszenie</>}</Button> }

      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Fab
            size="small"
            aria-label="zamknij"
            sx={{ position: "absolute", top: "1em", right: "1em" }}
            onClick={handleClose}
          >
            <CloseOutlinedIcon />
          </Fab>
          { props.row.id === "nowa" ? 
          <>{ (isViewer||isEditor) ? 
              <>Nowa grupa Gospodarza</> :
              <>Dodawanie nowego zgłoszenia</> 
              }
          </> : 
          <>Edycja zgłoszenia nr {props.row.id}</>
          }
          
          <br />
          { (isViewer||isEditor) && <FormControlLabel
            control={<Switch checked={zwin} onChange={handleZwin} />}
            label="pokaż wszystkie pola"
          /> }
        </DialogTitle>

        <DialogContent>
        <Box component="form" sx={{ mt: 1 }}>
        <Grid container spacing={2}>
  
            <KomunikatyWysylka stany={mutateOdwiedziny} />

              <Collapse in={zwin}>
                <Grid xs={12}>
                  <TextField
                    select
                    label="Status"
                    id="state"
                    {...register("state")}
                    value={grupa.state}
                    onChange={(event) => {
                      setValue("state", event.target.value);
                      setGrupa({
                        ...grupa,
                        state: event.target.value,
                      });
                      trigger();
                    }}
                    variant="outlined"
                    error={errors.state ? true : false}
                    helperText={errors.state && errors.state.message}
                    disabled={!isEditor&&!isViewer}
                    sx={{ maxWidth: "16em" }}
                    >
                    {opcje.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Collapse>

              <Grid xs={12}>
              <TextField
                label="Nazwa"
                id="nazwa"
                {...register("nazwa")}
                value={grupa.nazwa}
                onChange={(event) => {
                  setValue("nazwa", event.target.value);
                  setGrupa({
                    ...grupa,
                    nazwa: event.target.value,
                  });
                  trigger();
                }}
                variant="outlined"
                error={errors.nazwa}
                helperText={errors.nazwa ? errors.nazwa.message : <>{ (isViewer||isEditor) && props.row.id === "nowa" ? <>UWAGA: 
                Daty poniżej będą domyślnymi datami każdego nowego noclegu w Grupie, 
                ale możesz je później ustawić indywidualnie dla każdego noclegu.
                Podobnie jest z kategorią - można ustawić osobno dla każdego noclegu.
                </> : "" }</>}
            />
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            <Grid xs={6}>
                  <DatePicker 
                    {...register("od")}
                    value={watch("od")&&dayjs(watch("od"))}
                    label="Data przyjścia"
                    id="od"
                    disablePast={isEditor?false:true}
                    format="YYYY-MM-DD"
                    onClose={()=>watch("do")===null&&setOpenDrugiCal(true)}
                    onChange={(event) => {
                      setValue("od", event.format("YYYY-MM-DD"));
                      setGrupa({
                        ...grupa,
                        od: event.format("YYYY-MM-DD"),
                      });
                      trigger();
                    }}
                    clearable
                    slotProps={{
                      textField: {
                        error: errors.od ? true : false,
                        helperText: errors.od ? errors.od.message : false,
                      },
                    }}
                    localeText={{ datePickerToolbarTitle: 'Data przyjścia' }}
                    sx={{ 
                      '& .MuiOutlinedInput-input': { backgroundColor: "none" },
                      '& .MuiInputLabel-root': { zIndex: 0 }
                    }}
                  /> 
            </Grid>
            <Grid xs={6}>
                    <DatePicker 
                    {...register("do")}
                    value={watch("do")&&dayjs(watch("do"))}
                    label="Data wyjścia"
                    id="do"
                    format="YYYY-MM-DD"
                    minDate={dayjs(watch("od")).add(1, "day")}
                    open={drugiCal}
                    onOpen={()=>setOpenDrugiCal(true)}
                    onClose={()=>setOpenDrugiCal(false)}
                    onChange={(event) => {
                      setValue("do", event.format("YYYY-MM-DD"));
                      setGrupa({
                        ...grupa,
                        do: event.format("YYYY-MM-DD"),
                      });
                      trigger();
                    }}
                    clearable
                    slotProps={{
                      textField: {
                        error: errors.do ? true : false,
                        helperText: errors.do ? errors.do.message : false,
                      },
                    }}
                    localeText={{ datePickerToolbarTitle: 'Data wyjścia' }}
                    sx={{ 
                      '& .MuiOutlinedInput-input': { backgroundColor: "none" },
                      '& .MuiInputLabel-root': { zIndex: 0 }
                    }}
                  />
                  </Grid>
                  </LocalizationProvider>
              
              <Grid xs={12} sm={3}>
              <Collapse in={zwin}>
                <TextField
                  label="Liczba osób"
                  type="number"
                  {...register("liczba")}
                  value={grupa.liczba}
                  onChange={(event) => {
                    setValue("liczba", event.target.value);
                    setGrupa({
                      ...grupa,
                      liczba: event.target.value,
                    });
                    trigger();
                  }}
                  variant="outlined"
                  error={errors.liczba}
                  helperText={errors.liczba ? errors.liczba.message : null}
                  sx={{ maxWidth: "16em" }}
                  />
                </Collapse>
                </Grid>

                <Grid xs={12} sm={9} sx={{ textAlign: "right", pt: 1 }}>
                <Collapse in={zwin}>
                  <CFButtonToggle
                    {...register("noclegchata")}
                    value={grupa.noclegchata}
                    onChange={(event) => {
                      // console.log(event, typeof event);
                      setValue("noclegchata", event);
                      setGrupa({
                        ...grupa,
                        noclegchata: event,
                      });
                      trigger();
                    }}
                    variant="outlined"
                    error={errors.noclegchata}
                    label="Informacje dodatkowe"
                    opcje={[
                      { value: 1, label: "Chcę spać w Chacie" },
                      { value: 2, label: "Chcę spać w swoim namiocie" },
                    ]}
                  />
                </Collapse>
                </Grid>

                <Grid xs={12}>
                <Collapse in={zwin}>
                  <TextField
                    label="Informacje dodatkowe"
                    placeholder="Podaj orientacyjną godzinę dotarcia do Chaty.&#010;Miło też będzie, jeśli wspomnisz, czy przyjeżdżasz na Otryt po raz pierwszy. ;-)"
                    multiline
                    rows={5}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("info")}
                    value={grupa.info}
                    onChange={(event) => {
                      setValue("info", event.target.value);
                      setGrupa({
                        ...grupa,
                        info: event.target.value,
                      });
                      trigger();
                    }}
                    variant="outlined"
                    error={errors.info}
                    helperText={errors.info ? errors.info.message : null}
                  />
                </Collapse>
                </Grid>

                <Grid xs={12}>
                <Collapse in={zwin}>
                <CFButtonSwitch
                  {...register("zapoznalem")}
                  value={grupa.zapoznalem}
                  onChange={(event) => {
                    setGrupa({
                      ...grupa,
                      zapoznalem: event,
                    });
                    setValue("zapoznalem", event);
                    trigger();
                  }}
                  label={
                    <>
                      Potwierdzam, że zapoznałem się z{" "}
                      <Link
                        underline="hover"
                        color="primary"
                        href="https://www.otryt.bieszczady.pl/regulamin-covid19"
                      >
                        Regulaminem
                      </Link>{" "}
                      i warunkami panującymi w Chacie.
                    </>
                  }
                  error={errors.zapoznalem ? errors.zapoznalem : false}
                />
                </Collapse>
                </Grid>

                { (isViewer||isEditor) && (
                <Grid xs={12} sx={{ borderTop: "1px solid grey", pt: 3 }}>
                <Collapse in={zwin}>
                  <i>poniższe pola są widoczne tylko dla Gospodarza i Rady Otryckiej:</i>
                  <TextField
                    label="Informacje administracyjne"
                    multiline
                    rows={5}
                    // helperText="To pole jest widoczne tylko dla Gospodarza i Administratorów"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    {...register("administracyjne")}
                    value={grupa.administracyjne}
                    onChange={(event) => {
                      setValue("administracyjne", event.target.value);
                      setGrupa({
                        ...grupa,
                        administracyjne: event.target.value,
                      });
                      trigger();
                    }}
                    sx={{ mt: 3 }}
                    variant="outlined"
                  />
                </Collapse>
                </Grid>
                )}

              { (isViewer||isEditor) && (
                <Grid xs={12} sx={{ mt: zwin?0:"-5em" }}>
                  <TextField 
                    {...register("kategoria")}
                    defaultValue={1}
                    width={"20em"}
                    value={grupa.kategoria}
                    select
                    onChange={(newValue) => {
                      setValue(newValue);
                      setGrupa({
                        ...grupa,
                        kategoria: newValue,
                      });
                      trigger();
                    }}
                    variant="outlined" 
                    label="główna kategoria noclegów"
                    sx={{ maxWidth: "16em" }}
                  >
                    {kategoriaOpcje.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}

              { (isViewer||isEditor) && (
                <Grid xs={12}>
                <CFButtonSwitch
                  {...register("klimat_zw")}
                  defaultValue={true}
                  value={grupa.klimat_zw}
                  onChange={(event) => {
                    setGrupa({
                      ...grupa,
                      klimat_zw: event,
                    });
                    setValue("klimat_zw", event);
                  }}
                  // color="warning"
                  label={<>opłata klimatyczna - {grupa.klimat_zw && 'nie'} będzie pobierana</>}
                  legenda={ (isViewer||isEditor) && !grupa.klimat_zw ? <>pobranie opłaty klimatycznej wymaga wypełnienia kwitu potwierdzenia pobrania opłaty i odprowadzenie sumy opłat do Gminy.</> : false }
                  />
                  </Grid>
              )}

              { (isViewer||isEditor) && (
                <Collapse in={zwin}>
                <Grid xs={12}>
                  <CFButtonSwitch
                    {...register("olali")}
                    value={grupa.olali}
                    onChange={(event) => {
                      setGrupa({
                        ...grupa,
                        olali: event,
                      });
                      setValue("olali", event);
                    }}
                    color="warning"
                    label="Nie przyszli - bez informacji"
                  />
                  <CFButtonSwitch
                    {...register("blokada")}
                    value={grupa.blokada}
                    onChange={(event) => {
                      setGrupa({
                        ...grupa,
                        blokada: event,
                      });
                      setValue("blokada", event);
                    }}
                    color="warning"
                    label="Blokada edycji noclegów"
                  />
                </Grid>
                </Collapse>
              )}
          </Grid>
          </Box>
          {/* </form> */}
        </DialogContent>

        <DialogActions sx={{ pt: "1em", borderTop: "1 solid grey" }}>
          <Fab
            size="large"
            color="primary"
            aria-label="zapisz"
            onClick={handleSubmit(onSubmit)}
            sx={{ position: "absolute", bottom: "2em", right: "1em" }}
            disabled={mutateOdwiedziny.isLoading}
          >
            <SaveIcon fontSize="large" />
          </Fab>
        </DialogActions>
      </Dialog>

      {/* Pytanie o potwierdzenie zamknięcia formularza niezapisanej nowej grupy */}
      <Dialog
        open={openConfirm}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Czy aby na pewno???</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Chcesz zamknąć niezapisaną grupę.
            <br />
            Potwierdź decyzję... ;-)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirm}
            sx={{ position: "absolute", left: "1em" }}
          >
            Potwierdzam!
          </Button>
          <Button onClick={handleCloseConfirm} autoFocus>
            Przemyślę...
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default OdwiedzinyFormularz;

import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchDane, postUzupelnienia } from "../api";
import KomunikatyWysylka from "../komponenty/KomunikatyWysylka";
import { opcjeLista } from "../komponenty/labelki";
import AutoVirOsobeWybierz from "../komponenty/AutoVirOsobeWybierz";

///// schemat walidacji pól formularza ///////////////////////////////////////
const schema = yup.object().shape({
        kategoria: yup.string().required("No, najpierw wybierz kryterium... ;-)"),
        wybor: yup.string().nullable()
          .when('kategoria', {
          is: "9_uwagi",
          then: schema => schema.nullable().optional(),
          otherwise: schema => schema.nullable()
            .typeError("...i trzeba coś wskazać... ;-)")
            .required("...i trzeba coś wskazać... ;-)"),
        }),
        uwagi: yup.string().nullable()
          .when('kategoria', {
          is: "9_uwagi",
          then: schema => schema.nullable()
                .typeError("...no trzeba coś napisać... ;-)")
                .required("...no trzeba coś napisać... ;-)"),
          otherwise: schema => schema.nullable().optional(),
        }),
  });
  // .required();
// ############################################################################

function UzupelnieniaFormularz(props) {
  
  // const operacje = useQuery(['operacje'], fetchOperacje);
  const operacje = useQuery(['dane'], fetchDane);

  const startoweDane = {
    data: null,
    kategoria: "",
    kwota: "",
    katpliku: "",
    wybor: "",
    tabela: props.row.tabela,
    wiersz: props.row.id
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: startoweDane,
    resolver: yupResolver(schema),
    // mode: "onSubmit",
    reValidateMode: "onSubmit" | "onChange",
  });

  const queryClient = useQueryClient();
  // const kasaLista = useQuery(['kasa'], fetchKasa);
  const mutateUz = useMutation(uz => postUzupelnienia(['x'],uz), {
    onError: (rollback) => {
      rollback();
    },
    onSettled: data => {
      // console.log(data);
      let newLista = operacje.isSuccess && operacje.data.uzupelnienia;
      newLista.push(data.data);
      let newOper = { 
        ...operacje.data, 
        uzupelnienia: newLista,
      };
      queryClient.setQueryData(['dane'], newOper);
      setTimeout(function () {
        mutateUz.reset(); // resetowanie stanów mutacji
        reset(startoweDane); // resetowanie formularza
        props.setStan(data.data.id); // żeby wymusić odświeżenie listy...
      }, 1500);
    },
  });

  const onSubmit = (data) => {
    !mutateUz.isLoading && mutateUz.mutate(data);
  };

  // console.log(watch());

  let opcje_kategorie = [
    // { value: "", label: "" },
    { value: "1_osoba_wyk", label: "Załatwiał/a" },
    { value: "2_osoba_dok", label: "Dokumenty są u..." },
    { value: "3_projekt", label: "Projekt" },
    { value: "4_kategoria", label: "Kategoria" },
    { value: "5_przedmiot", label: "Przedmiot" },
    // { value: "6_operacja", label: "Inna operacja" },
    // { value: "7_plik", label: "Dołącz pliki" },
    { value: "9_uwagi", label: "Dodatkowe uwagi" },
  ];

// console.log(errors);
  // TODO:
  // - freesolo przy kategoriach, projektach i może gdzieś jeszcze? - nie działa
  // - kwota - umożliwić wprowadzanie liczb z przecinkiem
  // - nie działa dodwanie daty operacji - czemu???
  // - przy usuwaniu pliku - gdy nie ma innych powiązań - może przenosić oseirocone pliki do katalogu "kosz"?

  return (
    <React.Fragment>
      <KomunikatyWysylka stany={mutateUz} />
      <Box component="form" sx={{ mt: 2 }}>
        <Grid container spacing={1}>

{/* ############### RODZAJ ######################################################################### */}
          <Grid item md={4} xs={12}>
            <TextField
              {...register("kategoria")}
              select
              label="kategoria"
              defaultValue="4_kategoria"
              value={watch("kategoria")}
              onChange={(event) => {
                setValue("kategoria", event.target.value);
                resetField("wybor");
                resetField("kwota");
              }}
              variant="outlined"
              size="small"
              error={errors.kategoria ? true : false}
              helperText={errors.kategoria && errors.kategoria.message}
          >
              {opcje_kategorie.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item md={5} xs={12}>
            {!watch("kategoria") && (
              <Typography sx={{ p: 1, ml: 3 }}>
                Najpierw wybierz kryterium...
              </Typography>
            )}
          </Grid>

{/* ############### PRZYCISK WYŚLIJ ################################################################# */}
          <Grid item md={3} hidden={fullScreen?true:false} sx={{ textAlign: "right" }}>
            <Button 
              variant="outlined" 
              // disabled 
              endIcon={<SendIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              zapisz
            </Button>
          </Grid>
{/* ############### OSOBA ######################################################################### */}
          {( watch("kategoria") === "1_osoba_wyk" 
          || watch("kategoria") === "2_osoba_dok" ) 
          && (
            <Grid item xs={12} md={watch("kategoria") === "2_osoba_dok"?12:9}>
                <AutoVirOsobeWybierz
                  {...register("wybor")}
                  value={watch("wybor")}
                  label="wybierz osobę"
                  onChange={(v) => {
                    setValue("wybor", v);
                  }}
                />
          </Grid>
          )}
{/* ############# PROJEKT ########################################################################### */}
          {watch("kategoria") === "3_projekt" && (
            <Grid item xs={12} md={9}>
            <Autocomplete
              {...register("wybor")}
              id="projektTag"
              options={opcjeLista(operacje.isSuccess && operacje.data.uzupelnienia,"wybor",{key: "kategoria", val: "3_projekt"})}
              freeSolo
              // autoComplete
              autoHighlight
              value={watch("wybor")}
              onChange={(i,v) => {
                // console.log(i,v);
                setValue("wybor", v);
              }}
              onInputChange={(i,v) => {
                setValue("wybor", v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="wybierz projekt"
                  // placeholder="wpisz trzy litery aby zacząć wyszukiwanie"
                  // helperText="wpisuj w kolejności: nazwisko imie PESEL"
                  size="small"
                  margin="dense"
                  variant="outlined" 
                  error={errors.wybor ? true : false}
                  helperText={errors.wybor && errors.wybor.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px" },
                  }}
                />
              )}
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}
            />
            </Grid>
          )}
{/* ############# KATEGORIA ########################################################################### */}
          {watch("kategoria") === "4_kategoria" && (
            <Grid item xs={12} md={9}>
            <Autocomplete
              {...register("wybor")}
              id="kategoriaTag"
              options={opcjeLista(operacje.isSuccess && operacje.data.uzupelnienia,"wybor",{key: "kategoria", val: "4_kategoria"})}
              freeSolo
              // autoComplete
              autoHighlight
              value={watch("wybor")}
              onChange={(i,v) => {
                // console.log(i,v);
                setValue("wybor", v);
              }}
              onInputChange={(i,v) => {
                setValue("wybor", v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="wybierz kategorię"
                  // placeholder="wpisz trzy litery aby zacząć wyszukiwanie"
                  // helperText="wpisuj w kolejności: nazwisko imie PESEL"
                  size="small"
                  margin="dense"
                  variant="outlined" 
                  error={errors.wybor ? true : false}
                  helperText={errors.wybor && errors.wybor.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px" },
                  }}
                />
              )}
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}
            />
            </Grid>
          )}
{/* ############## PRZEDMIOT ########################################################################## */}
          { watch("kategoria") === "5_przedmiot" && (
            <Grid item xs={12} md={9}>
            <Autocomplete
              {...register("wybor")}
              id="przedmiotTag"
              options={operacje.isSuccess && operacje.data.inwentarz}
              getOptionLabel={(o) => {
                if (typeof o === "string"||typeof o === "number") {
                  let opt = operacje.data.inwentarz.filter(p=>Number(p.id)===Number(o))[0];
                  return opt ? opt.id+". "+opt.nazwa : "";
                } else if (o) {
                  return o.id+". "+o.nazwa;
                }
              }}
              // freeSolo
              // autoComplete
              autoHighlight
              value={watch("wybor")}
              onChange={(i,v) => {
                setValue("wybor", v.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="wybierz przedmiot"
                  // placeholder="wpisz trzy litery aby zacząć wyszukiwanie"
                  // helperText="wpisuj w kolejności: nazwisko imie PESEL"
                  size="small"
                  margin="dense"
                  variant="outlined" 
                  error={errors.wybor ? true : false}
                  helperText={errors.wybor && errors.wybor.message}
                  sx={{ 
                    '& .MuiOutlinedInput-input': { p: "8.5px" },
                  }}
                />
              )}
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}
            />
            </Grid>
          )}
{/* ########## KWOTA ############################################################################## */}
          {    watch("kategoria")
            && watch("kategoria") !== "2_osoba_dok" 
            && watch("kategoria") !== "9_uwagi" 
            && (
            <Grid item xs={12} md={3} sx={{'& .MuiFormControl-root': { mt: 0 }}} >
              <TextField 
                {...register("kwota")}
                label="kwota"
                type="number"
                step="0.01"
                min="0"
                id="kwota"
                value={watch('kwota')}
                onChange={(event) => {
                  setValue("kwota", event.target.value.replace(',', '.'));
                }}
                error={errors.kwota ? true : false}
                helperText={errors.kwota && errors.kwota.message}
                size="small"
                margin="dense"
                variant="outlined" 
                sx={{ 
                  '& .MuiOutlinedInput-input': { p: "8.5px" },
                  // '& .MuiTextField-root': { mt: "15px" } 
                }}
              />
            </Grid>
          )}
{/* ############## UWAGI ############################################################## */}
          { watch("kategoria") === "9_uwagi" && (
            <Grid item xs={12} 
              sx={{ 
                '& .MuiTextField-root': { marginTop: "0px" }, 
              }}>
            <TextField
              {...register("uwagi")}
              label="uwagi"
              multiline
              rows={15}
              id="uwagi"
              value={watch("uwagi")}
              onChange={(event) => {
                setValue("uwagi", event.target.value);
              }}
              error={errors.uwagi ? true : false}
              helperText={errors.uwagi && errors.uwagi.message}
              size="small"
              margin="dense"
            />
            </Grid>
          )}
{/* ############# koniec formularza ########################################################################### */}
          <Grid item md={12} hidden={fullScreen?false:true} sx={{ textAlign: "center" }}>
            <Button 
              variant="outlined" 
              // disabled 
              endIcon={<SendIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={mutateUz.isLoading}
            >
              zapisz
            </Button>
            <br/>
          </Grid>

        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default UzupelnieniaFormularz;

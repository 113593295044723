import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { 
        Badge,
        // Alert, 
        // CircularProgress, 
        Grid, 
        Typography } from '@mui/material';
// import { serialize } from 'object-to-formdata';
import { fetchDane } from '../api';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export default function OdwiedzinyDzien(props) {
        // const odwiedzinyLista = useQuery('odwiedziny', fetchOdwiedziny);
        // const operacje = useQuery(['operacje'], fetchOperacje);
        const operacje = useQuery(['dane','odwiedziny'], fetchDane);
        const odwiedzinyLista = operacje.isSuccess && operacje.data.odwiedziny;
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
        const [open, setOpen] = React.useState(false);
        const [dzien, setDzien] = React.useState(props.data);
        const [pokaDzien, setPokaDzien] = React.useState(props.pokaDzien);
        let dane = operacje.isSuccess && odwiedzinyLista.filter((v)=>v.od<=dzien&&v.do>dzien&&Number(v.state)!==-2&&Number(v.state)!==2);
        let osob = 0;
        let grup = 0;
        if (dane.length===1) { osob = Number(dane[0].liczba); grup = 1 }
        if (dane.length>1) {
                dane.forEach(e => {
                        osob += Number(e.liczba);
                });
                grup = dane.length;
        }
      
//   console.log(dzien,dane);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlePrev = () => {
        let prev = dayjs(dzien).subtract(1, "day").format("YYYY-MM-DD");
        setDzien(prev);
  };
  const handleNext = () => {
        let next = dayjs(dzien).add(1, "day").format("YYYY-MM-DD");
        setDzien(next);
  };

  const handleClose = () => {
        setDzien(props.data);
        setOpen(false);
  };

  React.useEffect(() => {
    setPokaDzien(props.pokaDzien);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);


  return (
    <div>

      <Button variant="link" onClick={handleClickOpen}>
        {/* <DeleteForeverIcon color="error" /> */}
        <Badge anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                badgeContent={dayjs(dzien).format("DD")}
                invisible={pokaDzien}
                // color="secondary"
                sx={{'& .MuiBadge-badge': {ml:-2,color:"#555"}}}
                >
        
        
        <div>{osob}</div>
        </Badge>
        
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Zapowiedzi na dzień: {fullScreen&&<br/>}<b>{dzien}</b>
          <br />
          grup: {grup} - osób: <b>{osob}</b>
        </DialogTitle>
        <DialogContent>
          <hr />

          {dane.length > 0 &&
            dane.map((e) => {
              // console.log(e);
              return (
                <React.Fragment key={"odw-dzien-"+e.id}>
                  <Typography key={"odw-dzien-"+e.id}>
                    <small>
                      <i>{e.id}.</i>
                    </small>{" "}
                    {e.od} - {e.do}
                    <br/>
                    <b>{e.nazwa}</b> - osób: {e.liczba}
                    <br/>
                    status: <i>  
                        {Number(e.state)===0  && "oczekujące"}
                        {Number(e.state)===1  && "zaakceptowane"}
                        {Number(e.state)===2  && "anulowane"}
                        {Number(e.state)===3  && "do kontaktu"}
                        {Number(e.state)===-2 && "usunięte"}
                        {Number(e.state)===-3 && "dodana przez Gospodarza"}
                        </i>
                  </Typography>
                    <hr />
                    </React.Fragment>
                    );
            })}
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs align="left">
              <Button onClick={handlePrev} autoFocus>
                <UndoIcon />
              </Button>
            </Grid>
            <Grid item xs align="center">
              <Button onClick={handleClose} autoFocus>
                Zamknij
              </Button>
            </Grid>
            <Grid item xs align="right">
              <Button onClick={handleNext} autoFocus>
                <RedoIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Fab, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
// import ButtonDownload from './ButtonDownload';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


export default function ButtonView(props) {
  const [open, setOpen] = React.useState(false);
  // const [numPages, setNumPages] = React.useState(null);
  // const [pageNumber, setPageNumber] = React.useState(1);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
  // plik lokalny przestaje działać, a CDN dostosowuje się do wersji (chyba) ;-)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handlePobierz() {
    const response = await fetch(process.env.REACT_APP_API_DOMAIN + "/index.php?option=com_content&task=api.pokaPlik&format=json&plik="+props.id);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = props.plik;
    link.click();
  };

//   TODO:
// - dorobić nawigację - na górze po prawej
// - dorobić informację o ładowaniu danych!!!!

  return (
    <React.Fragment>
      <Button variant="link" onClick={handleClickOpen}>
        <VisibilityIcon color="primary" />
      </Button>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Fab
            size="small"
            aria-label="pobierz"
            sx={{ position: "absolute", top: "1em", left: "1em" }}
            onClick={handlePobierz}
          >
            <SaveAltIcon color="info" />
          </Fab>
          <Fab
            size="small"
            aria-label="zamknij"
            sx={{ position: "absolute", top: "1em", right: "1em" }}
            onClick={handleClose}
          >
            <CloseOutlinedIcon />
          </Fab>
          <Typography variant="h6" align="center">
          Podgląd pliku
          </Typography>
          {/* <Button onClick={handlePobierz}><SaveAltIcon color="info" fontSize="large" /></Button> */}
        </DialogTitle>
        <DialogContent>
          { props.plik && props.plik.includes('pdf') ? <Box sx={{ width: "100%" }}>
            <Document 
              file={ process.env.REACT_APP_API_DOMAIN + "/index.php?option=com_content&task=api.pokaPlik&format=json&plik="+props.id} 
              // onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page 
                width={fullScreen ? window.innerWidth-48 : 837} 
                pageNumber={1} 
              />
            </Document>
          </Box> : 
          <Box
            component="img"
            src={ process.env.REACT_APP_API_DOMAIN+"/index.php?option=com_content&task=api.pokaPlik&format=json&plik="+props.id }
            sx={{ maxWidth: "90vw" }}
          /> }
        </DialogContent>
        {/* <DialogActions>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}

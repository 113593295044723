import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormHelperText from '@mui/material/FormHelperText';

const CFButtonToggle = React.forwardRef((props, ref) => {
  // console.log("props BT:", props);

  const [value, setValue] = React.useState(props.value);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChange(newValue);
  };

  return (
    <>
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
    >
      {props.opcje.map((option) => (
      <ToggleButton key={option.value} value={option.value}>
        {option.label}
      </ToggleButton>
      ))}
    </ToggleButtonGroup>
    {props.error && <FormHelperText sx={{ ml: '14px', textAlign: "right" }} className='Mui-error MuiFormHelperText-contained'>{props.error.message}</FormHelperText>}
    </>
  );
});

export default CFButtonToggle;